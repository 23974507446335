import React, { useEffect, useState, useRef } from "react";
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import { message } from 'antd';
import QRCode from 'qrcode.react';
import { useForm, Controller } from 'react-hook-form';

function MailServerManager(props) {
    const [state, setState] = useState({
        formFields: {
            emailServers_ID: null,
            smtpServer: '',
            smtpPort: '',
            smtpUser: '',
            smtpPassword: '',
            app: ''
        }
    });

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        Axios.get(`/api/GetMailServerInfoForApp`, {
            params: {
                App: 'MSO'
            }
        }).then(response => {
            setValue('formFields', response.data);

            setState({
                ...state
            });

        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function handleSaveEmailServerSettings() {
        let postdata = getValues("formFields");

        console.log(postdata);

        if (postdata.smtpServer === '' ||
            postdata.smtpUser === '' ||
            postdata.smtpPort === ''
        ) {
            toast.error('SMTP Server, Port and User are required fields');
            return;
        } else {
            Axios.post(`/api/UpdateMailServerInfo`, postdata
            ).then(response => {

                toast.success('Email Server Info Successfully Updated');
            }).catch(error => {
                console.log(error);
            });
        }

        //let invalidEntriesFound = false;

        //for (var r of state.emailRecipients) {
        //    if (r.recipients.length === 0) {
        //        invalidEntriesFound = true;
        //    }
        //}

        //if (invalidEntriesFound === true) {
        //    toast.error('All email types must have at least one valid recipient');
        //    return;
        //}

        //Axios.post(`/api/UpdateEmailRecipients`, state.emailRecipients
        //).then(response => {

        //    toast.success('Email Recipients Successfully Updated');
        //}).catch(error => {
        //    console.log(error);
        //});
    }

    return (
        <div className="code-box-demo">
            <p></p>

            <div className="row">
                <div className="form-group col-lg-2">
                    <label className="form-label">SMTP Server</label>
                    <input {...register("formFields.smtpServer")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-1">
                    <label className="form-label">SMTP Port</label>
                    <input {...register("formFields.smtpPort")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-3">
                    <label className="form-label">SMTP User</label>
                    <input {...register("formFields.smtpUser")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2">
                    <label className="form-label">SMTP Password</label>
                    <input {...register("formFields.smtpPassword")} type="text" className="form-control-custom" />
                </div>
            </div>
            <div className="row">
                <button className="btn btn-primary" onClick={handleSaveEmailServerSettings}>Save Email Server Settings</button>
            </div>
        </div>
    );
}

export default withRouter(MailServerManager);