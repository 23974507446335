import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import '../../../css/card.css';

import Axios from '../../../config/axios';
import Globals from '../../../config/globals';

import { Modal } from 'react-bootstrap';

import googlePlayImg from '../../../img/g_play.png';
import appStoreImg from '../../../img/a_store.png';

import { smallBox, SmartMessageBox } from "../../../common/utils/functions";

import NumberFormat from 'react-number-format';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import ProgramListing from '../../Exhibitors/ProgramListing';

export class ShowProgramCard extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showInfo.name !== prevState.showInfo.name && nextProps.showInfo.show_abbrev !== prevState.showInfo.show_abbrev) {
            return { showInfo: nextProps.showInfo, formFields: nextProps.showInfo }
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showInfo: this.props.showInfo,
            formFields: this.props.showInfo,
            showSWPane: false
        }
    }

    render() {

        return (
            <Card className="showCard">
                <Card.Header><span>Show Program Listing</span></Card.Header>
                <Card.Body>
                    <ProgramListing {...this.props} />
                </Card.Body>
            </Card>
        );
    }
}