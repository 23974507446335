import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { message } from 'antd';

function ExhibitorLeadsSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        selectedShow: '',
        selectedShowName: '',
        selectedExhibitorId: '',
        selectedExhibitorName: '',
        leadsList: [],
        dataloaded: false,
        selectedExhibitor: {},
        selectedExportType: 'Select Type'
    });

    useEffect(() => {

    }, []);

    useEffect(() => {
        //console.log(props);

        Axios.get(`/api/GetD2PLeadsForExhibitorShow`, {
            params: {
                ExhibitorID: props.selectedExhibitorId,
                showcode: props.selectedShow,
                whichday: 'all'
            }
        }).then(response => {
            let leadsList = response.data;

            //console.log(leadsList);

            setState({
                ...state,
                showPane: props.showPane,
                selectedShow: props.selectedShow,
                selectedShowName: props.selectedShowName,
                selectedExhibitorId: props.selectedExhibitorId,
                selectedExhibitorName: props.selectedExhibitorName,
                leadsList: leadsList,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function hidePane() {
        //console.log('ExhibitorLeadsSlider hidePane invoked');
        setState({
            ...state,
            showPane: false
        });

        props.hidePane();
    }

    function GetD2PLeadsForExhibitorById() {
        
    }

    function nameFormatter(cell, row) {
        return (
            <span className="color-blue"><text>{row.lName}, {row.fName}</text></span>
        );
    }

    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.originalScan)).format("LLL");
        return (
            <div>{mydate}</div>
        );
    }

    //function dateFormatter(cell, row) {
    //    //var mydate = new Date(Date.parse(row.originalScan)).toLocaleString();
    //    var mydate = new Date(Date.parse(row.originalScan)).toUTCString();

    //    return (
    //        <text>{mydate}</text>
    //    );
    //}

    const columns = [
    {
        dataField: 'leads_ID',
        text: 'Leads_ID',
        hidden: true
    }, {
        isDummyField: true,
        text: 'Attendee Name',
        formatter: nameFormatter
    }, {
        dataField: 'company',
        text: 'Company Name'
    }, {
        dataField: 'title',
        text: 'Title'
    }, {
        dataField: 'originalScan',
        text: 'Scanned',
        formatter: dateFormatter
    }];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <input
                type="checkbox"
                ref={(input) => {
                    if (input) input.indeterminate = indeterminate;
                }}
                {...rest}
            />
        ),
        selectionRenderer: ({ mode, ...rest }) => (
            <input type={mode} {...rest} />
        )
    };

    const expandRow = {
        renderer: row => (
            <div>
                <div>Address: <strong>{row.address}, {row.city}, {row.state} {row.zip}</strong></div>
                <div>&nbsp;</div>
                <div>Email: <strong><a href={`mailto:${row.email}`}>{row.email}</a></strong></div>
                <div>&nbsp;</div>
                <div>Phone: <strong>{row.phone}</strong></div>
                <div>&nbsp;</div>
                <div>Purchasing Role: <strong>{row.purchasingRole}</strong></div>
                <div>Timeframe To Purchase Parts or Services: <strong>{row.timeframe}</strong></div>
                <div>Industry: <strong>{row.industryCode}</strong></div>
                <div>&nbsp;</div>
                <div>Notes: <strong>{row.leadNotes}</strong></div>
            </div>
        ),
        showExpandColumn: true
    };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'selectedExportType') {
            setState({
                ...state,
                selectedExportType: value
            });
        }
    }

    function handleExportLeads(event) {
        var myLeadsDay;
        var myFileName;

        console.log(state.selectedExportType);

        if (state.selectedExportType == 'Select Type') {
            toast.error('Type of Export must be selected');

            return;
        }

        if (state.selectedExportType.includes("Both Days")) {
            myLeadsDay = "all";
            myFileName = "D2PLeads_" + state.selectedShowName.replace(' ', '_') + "_both_days";
        }
        else if (state.selectedExportType.includes("Day 1")) {
            myLeadsDay = "day1";
            myFileName = "D2PLeads_" + state.selectedShowName.replace(' ', '_') + "_day1";
        }
        else if (state.selectedExportType.includes("Day 2")) {
            myLeadsDay = "day2";
            myFileName = "D2PLeads_" + state.selectedShowName.replace(' ', '_') + "_day2";
        }

        let postdata = {
            exhibitorid: state.selectedExhibitorId,
            showcode: parseInt(state.selectedShow),
            showName: state.selectedShowName,
            whichday: myLeadsDay
        }

        const key = "downloadleads";
        message.loading({
            content: 'Downloading Your Leads...',
            key,
            duration: 0
        });

        if (state.selectedExportType.includes("Excel")) {
            Axios.post(`/api/DownloadCollectedLeads_XLSX`, postdata, {
                responseType: 'blob'
            }).then(response => {
                message.destroy(key);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = myFileName + ".xlsx";
                a.click();
                window.URL.revokeObjectURL(url);
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            Axios.post(`/api/DownloadCollectedLeads_CSV`, postdata, {
                responseType: 'blob'
            }).then(response => {
                message.destroy(key);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = myFileName + ".csv";
                a.click();
                window.URL.revokeObjectURL(url);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    return (
        <SlidingPane
            className='some-custom-class w-75'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Exhibitor Leads'
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div className="row">
                    <h3>{state.selectedExhibitorName}</h3>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6">
                        <h4>{state.leadsList.length} Leads Collected For {state.selectedShowName}</h4>
                    </div>
                    <div className="form-group col-lg-6">
                        <div className="d-flex flex-row">
                            <div className="p-2">Export Exhibitor Leads:</div>
                            <div className="p-2">
                                <select className="form-control-custom" value={state.selectedExportType} name="selectedExportType" onChange={handleInputChange}>
                                    <option>Select Type</option>
                                    <option>Both Days in Excel Format</option>
                                    <option>Both Days in CSV Format</option>
                                    <option>Day 1 in Excel Format</option>
                                    <option>Day 1 in CSV Format</option>
                                    <option>Day 2 in Excel Format</option>
                                    <option>Day 2 in CSV Format</option>
                                </select>
                            </div>
                            <div class="p-2">
                                <button className="btn btn-primary btn-sm" onClick={handleExportLeads}>Export Leads</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <BootstrapTable
                        wrapperClasses="tablePaginationUp"
                        keyField='leads_ID'
                        data={state.leadsList}
                        columns={columns}
                        //selectRow={selectRow}
                        expandRow={expandRow}
                    />
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-primary btn-lg margin-left-15 float-right" onClick={hidePane}>Close</button>
                    {/*<button className="btn btn-submit btn-lg margin-left-15 float-left" onClick={this.updateBadge}>{this.state.buttonLabel}</button>*/}
                </div>
            </div>
        </SlidingPane>
    );
}

export default withRouter(ExhibitorLeadsSlider);