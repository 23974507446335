import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { smallBox, SmartMessageBox } from "../../../common/utils/functions";
import statesList from '../../Helpers/StatesList';

export class IndividualBadgeSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            if (nextProps.selectedBadge && nextProps.selectedBadge.badge) {
                return nextProps.showPane === true ? { showPane: true, selectedBadge: nextProps.selectedBadge, formFields: nextProps.selectedBadge.badge, addNew: false, buttonLabel: 'Update Badge', dbAction: nextProps.selectedBadge.dbAction } : { showPane: false };
            } else {
                return nextProps.showPane === true ? { showPane: true, selectedBadge: {}, formFields: {}, addNew: true, buttonLabel: 'Add Badge', dbAction: 'insert' } : { showPane: false };
            }
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showPane: false,
            selectedBadge: {},
            formFields: {},
            states: statesList,
            addNew: false, 
            buttonLabel: 'Add Badge',
            dbAction: ''
        }
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });
        this.isDirty = false;

        this.props.hidePane();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    updateBadge = () => {
        console.log('yo');
        //console.log(this.state.formFields);

        if (!this.state.formFields?.fName ||
            !this.state.formFields?.lName ||
            !this.state.formFields?.title
        ) {
            smallBox({
                title: "New Badge Cannot Be Added",
                content: "<i class='fa fa-clock-o'></i> <i>Please fill out all required fields.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            let selectedBadge = this.state.selectedBadge;

            let formFields = this.state.formFields;
            let type = this.state.addNew ? 'new' : 'edit';

            //Changed to this approach on 2023_02_28
            //If the selectedBadge.type is already = 'new' then no matter what additional edits are made we still flag it as 'new' so it gets added in
            if (selectedBadge.type === 'new') {
                selectedBadge.isDirty = true;
                selectedBadge.type = 'new';
                selectedBadge.dbAction = 'insert';
            }
            else {
                selectedBadge.isDirty = this.isDirty ? true : false;
                selectedBadge.type = type;
                selectedBadge.dbAction = this.state.dbAction;
            }
            formFields.isDirty = this.isDirty ? true : false;
            formFields.type = type;
            selectedBadge.badge = formFields;

            //Old way changed on 2023_02_28
            //formFields.isDirty = this.isDirty ? true : false;
            //selectedBadge.isDirty = this.isDirty ? true : false;
            //selectedBadge.type = type;
            //formFields.type = type;
            //selectedBadge.badge = formFields;
            //selectedBadge.dbAction = this.state.dbAction;

            this.props.updateBadge(selectedBadge, type);

            this.hidePane();
        }
    }

    render() {

        return (
            <SlidingPane
                className='some-custom-class w-50'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title={this.state.addNew ? 'Add New Badge' : 'Update Badge for ' + this.state.formFields.fName + ' ' + this.state.formFields.lName}
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body scrollableDiv">

                    <div className="row">
                        <div className="form-group col-lg-12">
                            <label className="form-label">First Name *</label>
                            <input type="text" name="fName" value={this.state.formFields.fName || ''} onChange={this.handleInputChange} className="form-control-custom" readOnly={!this.state.addNew ? true : false} maxlength="20" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Last Name *</label>
                            <input type="text" name="lName" value={this.state.formFields.lName || ''} onChange={this.handleInputChange} className="form-control-custom" readOnly={!this.state.addNew ? true : false} maxlength="20" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Title *</label>
                            <input type="text" name="title" value={this.state.formFields.title || ''} onChange={this.handleInputChange} className="form-control-custom" maxlength="20" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Email *</label>
                            <input type="text" name="email" value={this.state.formFields.email || ''} onChange={this.handleInputChange} className="form-control-custom" maxlength="100" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Address</label>
                            <input type="text" name="address" value={this.state.formFields.address || ''} onChange={this.handleInputChange} className="form-control-custom" maxlength="50" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">City</label>
                            <input type="text" name="city" value={this.state.formFields.city || ''} onChange={this.handleInputChange} className="form-control-custom" maxlength="50" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">State</label>
                            <select className="form-control-custom" value={this.state.formFields.state} name="state" onChange={this.handleInputChange}>
                                <option></option>
                                {this.state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                            </select>
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Zip Code</label>
                            <input type="text" name="zip" value={this.state.formFields.zip || ''} onChange={this.handleInputChange} className="form-control-custom" maxlength="10" />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Phone</label>
                            <input type="text" name="phone" value={this.state.formFields.phone || ''} onChange={this.handleInputChange} className="form-control-custom" maxlength="20" />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-primary btn-lg margin-left-15 float-right" onClick={this.hidePane}>Close</button>
                        <button className="btn btn-submit btn-lg margin-left-15 float-left" onClick={this.updateBadge}>{this.state.buttonLabel}</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}