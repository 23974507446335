import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import ExhibitorSearch from './ExhibitorSearch';
import QRCode from 'qrcode.react';
import { toast, confirm } from '@rickylandino/react-messages';

//import { Tabs } from 'antd';

function ActivateDeviceTab(props) {
    const [state, setState] = useState({
        selectedShow: props.selectedShow,
        selectedShowName: props.selectedShowName,
        exhibitorsList: [],
        selectedExhibitor: "Select An Exhibitor",
        selectedExhibitorId: '',
        selectedExhibitorName: '',
        qrCodeHumanReadable: '',
        qrCodeEncrypted: '',
        resetFields: false,
        LeadRetrievalOrdered: false
    });

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        setState({
            ...state,
            selectedShow: props.selectedShow,
            selectedExhibitorId: '',
            formFields: {
                contact: '',
                phone: '',
                email: ''
            },
            lrprice: '',
            lrpriceother: ''
        });

    }, [props.selectedShow]);

    function handleSearchReturn(exhibitorId, exhibitorName) {
        //First check to see if Exhibitor is a Lead Retrieval Exhibitor
        Axios.get(`/api/GetLeadRetrievalStatus`, {
            params: {
                id: exhibitorId,
                showcode: state.selectedShow
            }
        }).then(response => {
            let myLeadRetrievalOrdered = response.data;

            //QRCode format
            //ActivateD2PShow|356|RALEIGH 2021|65|ACME WIRE PRODUCTS CO. INC.
            let qrCodeHumanReadable = 'ActivateD2PShow|' + state.selectedShow + '|' + state.selectedShowName + '|' + exhibitorId + '|' + exhibitorName;
            let qrCodeEncrypted = EncryptQRCodeText('ActivateD2PShow|' + state.selectedShow + '|' + state.selectedShowName + '|' + exhibitorId + '|' + exhibitorName);

            setState({
                ...state,
                selectedExhibitorId: exhibitorId,
                selectedExhibitorName: exhibitorName,
                qrCodeHumanReadable: qrCodeHumanReadable,
                qrCodeEncrypted: qrCodeEncrypted,
                resetFields: false,
                LeadRetrievalOrdered: myLeadRetrievalOrdered
            });

        }).catch(error => {
            console.log(error);
        });

        
    }

    const EncryptQRCodeText = (myQRCodeText) => {
        let EncryptedQRCodeText = '';

        for (let i = 0; i < myQRCodeText.length; i++) {
            EncryptedQRCodeText += String.fromCharCode(myQRCodeText.charCodeAt(i) + 1);
        }

        return EncryptedQRCodeText;
    }

    function handleResetSelections(e) {
        e.preventDefault();

        setState({
            ...state,
            resetFields: true,
            selectedExhibitorId: '',
            qrCodeHumanReadable: ''
        });
    }

    function handleRegisterExhibitor(e) {
        e.preventDefault();

        //First check to see if Exhibitor is a Lead Retrieval Exhibitor
        Axios.get(`/api/GetLeadRetrievalStatus`, {
            params: {
                id: state.selectedExhibitorId,
                showcode: state.selectedShow
            }
        }).then(response => {
            let retval = response.data;

            if (retval) {
                //Next, check to see if exhibitor is alreay registered
                Axios.get(`/api/GetD2PAppActivatedExhibitor`, {
                    params: {
                        Events_ID: state.selectedShow,
                        ExhibitorID: state.selectedExhibitorId,
                        DeviceID: ""
                    }
                }).then(response => {
                    if (!response.data) {
                        //toast.success("Good to insert");

                        //Good to go - insert new show
                        let postdata = {};

                        postdata.Events_ID = parseInt(state.selectedShow);
                        postdata.ExhibitorID = parseInt(state.selectedExhibitorId);
                        postdata.DeviceID = "";
                        postdata.ActivationDate = new Date();

                        Axios.post(`/api/InsertD2PAppActivatedExhibitor`, postdata
                        ).then(response => {
                            if (response.data) {
                                toast.success(state.selectedExhibitorName + " successfully registered for " + state.selectedShowName);
                            }
                        }).catch(error => {
                            console.log(error);
                        });

                        return;
                    }
                    else {
                        //Already in the table so just report success
                        toast.success(state.selectedExhibitorName + " successfully registered for " + state.selectedShowName);

                        return;
                    }

                }).catch(error => {
                    console.log(error);
                });
            } else {
                toast.error(state.selectedExhibitorName + " has not purchased lead retrieval for " + state.selectedShowName);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleLRPriceChange(e) {
        const target = e.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;
        //const name = target.name;

        console.log(target.type);
        if (target.type === 'radio') {
            if (e.target.value == '$225' || e.target.value == 'Free') {
                setState({
                    ...state,
                    lrprice: e.target.value,
                    lrpriceother: ''
                });
            }
            else {
                //this indicates Other pricing
                setState({
                    ...state,
                    lrprice: e.target.value
                });
            }
        }
        else if (target.type === 'text') {
            setState({
                ...state,
                lrpriceother: e.target.value,
                lrprice: 'Other'
            });
        }

        //console.log("here in useEffect for props.resetFields: ", props.resetFields);
    }

    function handleProcessOrder(e) {
        e.preventDefault();

        //Good to go - process order
        var mylrprice = '';
        if (state.lrpriceother != '') {
            mylrprice = state.lrpriceother;
        }
        else {
            mylrprice = state.lrprice;
        }

        //if (state.formFields.contact === '' || state.formFields.phone === '' || state.formFields.email === '' || mylrprice === '') {
        //    toast.error("All fields must be completed before submitting an onsite order");

        //    return;
        //}

        if (state.formFields.contact === '') {
            toast.error("Contact Name must be provided before submitting an onsite order");

            return;
        }

        let onlineOrder = {
            Id: parseInt(state.selectedExhibitorId),
            showcode: parseInt(state.selectedShow),
            contact: state.formFields.contact,
            phone: state.formFields.phone,
            email: state.formFields.email
        };

        let postdata = {
            reqExhibitor: state.selectedExhibitorName,
            currentuser: "Lead Retrieval Administrator",
            name: state.selectedShowName,
            lrprice: mylrprice,
            onlineOrdersModel: onlineOrder
        }

        Axios.post(`/api/ProcessLeadRetrievalOrder`, postdata
        ).then(response => {
            if (response.data) {
                setState({
                    ...state,
                    resetFields: false,
                    lrprice: '',
                    lrpriceother: '',
                    formFields: {},
                    LeadRetrievalOrdered: true
                });

                toast.success("Lead Retrieval order successfully submitted for " + state.selectedExhibitorName + " for " + state.selectedShowName);
            }
        }).catch(error => {
            console.log(error);

            toast.error("There was a problem processing the lead retrieval order for " + state.selectedExhibitorName + " for " + state.selectedShowName);
        });
    }

    return (
        <div className="code-box-demo">
            <div className="row">
                <label className="form-label mt-1">Search for Exhibitor:&nbsp;</label>
                <ExhibitorSearch {...props} resetFields={state.resetFields} handleSearchReturn={handleSearchReturn} />
            </div>

            {state.selectedExhibitorId == "" ?
                <div className="form-group col-lg-12 row">
                    <p>&nbsp;</p>
                    <h1>Select an exhibitor to activate a device</h1>
                </div>
                :
                <>

                        {state.LeadRetrievalOrdered ?
                            <>
                                <div className="form-group col-lg-12 mt-3">
                                    <QRCode value={state.qrCodeEncrypted} />
                                </div>
                                <div className="form-group col-lg-12">
                                    {state.qrCodeHumanReadable}
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-danger btn-lg" onClick={handleRegisterExhibitor}> Register Exhibitor</button>

                                    <span className="form-group col-lg-2 a" onClick={handleResetSelections}><u>Reset Selections</u></span>
                                </div>
                            </>
                            :
                            <>
                                <p>&nbsp;</p>
                                <h3 style={{ color: 'red' }}>This exhibitor has not purchased lead retrieval - place an onsite order below</h3>
                                <p>&nbsp;</p>
                                <h5 style={{ color: 'red' }}>Note: Contact Name is required</h5>
                                <p>&nbsp;</p>
                                <div className="form-group col-lg-6">
                                    <label className="form-label">Contact Name</label>
                                    <input type="text" className="form-control-custom" name="contact" value={state.formFields.contact} onChange={handleInputChange} />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label className="form-label">Phone</label>
                                    <input type="text" className="form-control-custom" name="phone" value={state.formFields.phone} onChange={handleInputChange} />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label className="form-label">Email</label>
                                    <input type="text" className="form-control-custom" name="email" value={state.formFields.email} onChange={handleInputChange} />
                                </div>
                                <div className="form-group form-inline col-lg-12" onChange={handleLRPriceChange}>
                                    <label className="form-label">Price:</label>&nbsp;&nbsp;
                                    <input type="radio" name="lrprice" value="$225" checked={state.lrprice === "$225"} />&nbsp;<label className="form-label">$225</label>&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="lrprice" value="Free" checked={state.lrprice === "Free"} />&nbsp;<label className="form-label">Free</label>&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="lrprice" value="Other" checked={state.lrprice === "Other"} />&nbsp;<label className="form-label">Other</label>&nbsp;&nbsp;&nbsp;
                                    <input type="text" className="form-control-custom" name="lrpriceother" value={state.lrpriceother} />
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-danger btn-lg" onClick={handleProcessOrder}> Process Order</button>

                                    <span className="form-group col-lg-2 a" onClick={handleResetSelections}><u>Reset Selections</u></span>
                                </div>
                            </>
                        }

                </>
            }
        </div>
    );
}

export default withRouter(ActivateDeviceTab);