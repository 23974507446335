import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import spinnerLarge from '../../img/spinnerLarge.gif';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Fragment } from 'react';

import { smallBox, SmartMessageBox } from "../../common/utils/functions";

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import NumberFormat from 'react-number-format';

import statesList from '../Helpers/StatesList';

class ProgramListing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            programListing: {},
            showPane: false,
            formFields: {},
            states: statesList,
            categoryList: [],
            qcList: [],
            chars_left: 375,
            processingSubmitOrder: false
        }
    }

    componentDidUpdate(prevProps) {
        //console.log('here in update');
        if (this.props.location.state?.showcode && prevProps.location.state?.showcode && (prevProps.location?.state?.showcode !== this.props.location?.state?.showcode)) {
            this.getShowProgramListing();
        }
    }

    componentDidMount() {
        //console.log('here in mount');
        this.GetAllCategoriesAndQCCerts();
        //this.getShowProgramListing();
    }

    getShowProgramListing = () => {
        Axios.get(`/api/GetShowProgramListingByCompany`, {
            params: {
                id: Globals.userInfo.companyId
            }
        }).then(response => {
            let formFields = response.data;
            let qcString = "";
            let scString = "";
            let qcOther = "";

            //console.log(formFields);
            //console.log(this.state.categoryList);

            if (formFields.jsS_Category1) {
                scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category1).categoryName + ", ";
            }
            if (formFields.jsS_Category2) {
                scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category2).categoryName + ", ";
            }
            if (formFields.jsS_Category3) {
                scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category3).categoryName + ", ";
            }
            if (formFields.jsS_Category4) {
                scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category4).categoryName + ", ";
            }
            if (formFields.jsS_Category5) {
                scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category5).categoryName + ", ";
            }
            formFields.scString = scString.substring(0, scString.length - 2);

            if (formFields.jsS_qc1) {
                qcString += formFields.jsS_qc1 + ", ";
            }
            if (formFields.jsS_qc2) {
                qcString += formFields.jsS_qc2 + ", ";
            }
            if (formFields.jsS_qc3) {
                qcString += formFields.jsS_qc3 + ", ";
            }
            if (formFields.jsS_qc4) {
                qcString += formFields.jsS_qc4 + ", ";
            }
            formFields.qcString = qcString.substring(0, qcString.length - 2);
            formFields.qcOther = qcOther;

            this.setState({
                chars_left: 375 - formFields.description.length,
                programListing: formFields,
                formFields
            });
        }).catch(error => {
            console.log(error);
        });
    }

    constructQCString = () => {
        let qcString = "";
        let formFields = this.state.formFields;

        if (formFields.jsS_qc1) {
            qcString += formFields.jsS_qc1 + ", ";
        }
        if (formFields.jsS_qc2) {
            qcString += formFields.jsS_qc2 + ", ";
        }
        if (formFields.jsS_qc3) {
            qcString += formFields.jsS_qc3 + ", ";
        }
        if (formFields.jsS_qc4) {
            qcString += formFields.jsS_qc4;
        }
    }

    GetAllCategoriesAndQCCerts = () => {
        Axios.get(`/api/GetAllCategoriesAndQCCerts`
        ).then(response => {
            this.setState({
                categoryList: response.data.categoryList,
                qcList: response.data.qcList
            }, () => this.getShowProgramListing());

            //console.log(this.state.qcList);
            //console.log(this.state.cateoryList);
        }).catch(error => {
            console.log(error);
        });
    }

    hidePane = () => {
        let formFields = this.state.programListing;

        //let qcString = "";
        //let scString = "";

        //if (formFields.jsS_Category1) {
        //    scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category1).categoryName + ", ";
        //}
        //if (formFields.jsS_Category2) {
        //    scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category2).categoryName + ", ";
        //}
        //if (formFields.jsS_Category3) {
        //    scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category3).categoryName + ", ";
        //}
        //if (formFields.jsS_Category4) {
        //    scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category4).categoryName + ", ";
        //}
        //if (formFields.jsS_Category5) {
        //    scString += this.state.categoryList.find(cat => cat.category === formFields.jsS_Category5).categoryName + ", ";
        //}
        //formFields.scString = scString.substring(0, scString.length - 2);

        //if (formFields.jsS_qc1) {
        //    qcString += formFields.jsS_qc1 + ", ";
        //}
        //if (formFields.jsS_qc2) {
        //    qcString += formFields.jsS_qc2 + ", ";
        //}
        //if (formFields.jsS_qc3) {
        //    qcString += formFields.jsS_qc3 + ", ";
        //}
        //if (formFields.jsS_qc4) {
        //    qcString += formFields.jsS_qc4 + ", ";
        //}
        //formFields.qcString = qcString.substring(0, qcString.length - 2);

        this.setState({
            showPane: false,
            formFields
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log("name: " + name + ", value: " + value);

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    maxLengthCheck = (object) => {
        this.setState({
            chars_left: 375 - object.target.value.length
        });

        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    handleSubmit = () => {
        this.setState({
            processingSubmitOrder: true
        });

        let whatChangedText = "";
        let formFields = this.state.formFields;
        let prevInfo = this.state.programListing;


        if (formFields.name !== prevInfo.name) {
            whatChangedText += "Company Name:<br /> " + formFields.name + "<br /><br /> ";
        }
        if (formFields.startyear !== prevInfo.startyear) {
            whatChangedText += "Start Year:<br /> " + formFields.startyear + "<br /><br /> ";
        }
        if (formFields.totalemployees !== prevInfo.totalemployees) {
            whatChangedText += "Total Employees:<br /> " + formFields.totalemployees + "<br /><br /> ";
        }
        if (formFields.squarefeet !== prevInfo.squarefeet) {
            whatChangedText += "Square Feet:<br /> " + formFields.squarefeet + "<br /><br /> ";
        }

        if (formFields.jsS_Category1 !== prevInfo.jsS_Category1) {
            whatChangedText += "Primary Category:<br /> " + this.state.categoryList.find(cat => cat.category === formFields.jsS_Category1).categoryName + " (" + formFields.jsS_Category1  + ")<br /><br /> ";
        }
        if (formFields.jsS_Category2 !== prevInfo.jsS_Category2) {
            whatChangedText += "Second Category:<br /> " + this.state.categoryList.find(cat => cat.category === formFields.jsS_Category2).categoryName + " (" + formFields.jsS_Category2 + ")<br /><br /> ";
        }
        if (formFields.jsS_Category3 !== prevInfo.jsS_Category3) {
            whatChangedText += "Third Category:<br /> " + this.state.categoryList.find(cat => cat.category === formFields.jsS_Category3).categoryName + " (" + formFields.jsS_Category3 + ")<br /><br /> ";
        }
        if (formFields.jsS_Category4 !== prevInfo.jsS_Category4) {
            whatChangedText += "Fourth Category:<br /> " + this.state.categoryList.find(cat => cat.category === formFields.jsS_Category4).categoryName + " (" + formFields.jsS_Category4 + ")<br /><br /> ";
        }
        if (formFields.jsS_Category5 !== prevInfo.jsS_Category5) {
            whatChangedText += "Fifth Category:<br /> " + this.state.categoryList.find(cat => cat.category === formFields.jsS_Category5).categoryName + " (" + formFields.jsS_Category5 + ")<br /><br /> ";
        }

        if (formFields.jsS_qc1 !== prevInfo.jsS_qc1) {
            whatChangedText += "Quality Certification 1:<br /> " + formFields.jsS_qc1 + "<br /><br /> ";
        }
        if (formFields.jsS_qc2 !== prevInfo.jsS_qc2) {
            whatChangedText += "Quality Certification 2:<br /> " + formFields.jsS_qc2 + "<br /><br /> ";
        }
        if (formFields.jsS_qc3 !== prevInfo.jsS_qc3) {
            whatChangedText += "Quality Certification 3:<br /> " + formFields.jsS_qc3 + "<br /><br /> ";
        }
        if (formFields.jsS_qc4 !== prevInfo.jsS_qc4) {
            whatChangedText += "Quality Certification 4:<br /> " + formFields.jsS_qc4 + "<br /><br /> ";
        }
        if (formFields.qcOther !== prevInfo.qcOther) {
            whatChangedText += "Quality Certification (Other - if not in drop down list):<br /> " + formFields.qcOther + "<br /><br /> ";
        }

        if (formFields.description !== prevInfo.description) {
            whatChangedText += "Description:<br /> " + formFields.description + "<br /><br /> ";
        }

        if (formFields.street1 !== prevInfo.street1) {
            whatChangedText += "Address 1:<br /> " + formFields.street1 + "<br /><br /> ";
        }
        if (formFields.street2 !== prevInfo.street2) {
            whatChangedText += "Address 2:<br /> " + formFields.street2 + "<br /><br /> ";
        }
        if (formFields.city !== prevInfo.city) {
            whatChangedText += "City:<br /> " + formFields.city + "<br /><br /> ";
        }
        if (formFields.state !== prevInfo.state) {
            whatChangedText += "State:<br /> " + formFields.state + "<br /><br /> ";
        }
        if (formFields.zip !== prevInfo.zip) {
            whatChangedText += "Zip:<br /> " + formFields.zip + "<br /><br /> ";
        }
        if (formFields.domain !== prevInfo.domain) {
            whatChangedText += "Website:<br /> " + formFields.domain + "<br /><br /> ";
        }
        if (formFields.telephone !== prevInfo.telephone) {
            whatChangedText += "Telephone:<br /> " + formFields.telephone + "<br /><br /> ";
        }
        if (formFields.fax !== prevInfo.fax) {
            whatChangedText += "Fax:<br /> " + formFields.fax + "<br /><br /> ";
        }

        if (whatChangedText === "") {
            this.setState({
                processingSubmitOrder: false
            });

            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>No Changes Were Made</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {

            let postdata = {
                whatChangedText,
                reqExhibitor: Globals.userInfo.companyName + " (" + Globals.userInfo.companyId + ")",
                id: Globals.userInfo.userId,
                email: Globals.userInfo.username
            }

            Axios.post(`/api/SendProgramListingChangeRequest`, postdata
            ).then(response => {
                this.setState({
                    processingSubmitOrder: false
                });

                if (response.data === true) {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Change request submitted.</i>",
                        color: "#659265",
                        iconSmall: "fa fa-check fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    this.hidePane();
                } else {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Error: Changes not submitted</i>",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    render() {
        return (
            <Fragment>
                
                <h1>{this.props.location.pathname === '/program-listing' && <Fragment>Program Listing</Fragment>}</h1>
                
                <div className={this.props.location.pathname === '/program-listing' ? "address" : ''}>
                    <Fragment>
                        <div>{this.state.programListing.name}</div>
                        <div>{this.state.programListing.startyear}, {this.state.programListing.totalemployees} Emp., {this.state.programListing.squarefeet} Sq. Ft.</div><br />
                        <div><strong>Service Categories:</strong> {this.state.programListing.scString}</div>
                        <div><strong>Quality Certs:</strong> {this.state.programListing.qcString}</div>
                        <div>{this.state.programListing.description}</div>
                        <br />
                        <div>{this.state.programListing.street1} {this.state.programListing.street2 && (' - ' + this.state.programListing.street2)}</div>
                        <div>{this.state.programListing.city}, {this.state.programListing.state} {this.state.programListing.zip}</div>
                        <br />
                        <div><strong>Tel:</strong> {this.state.programListing.telephone}</div>
                        <div><strong>Fax:</strong> {this.state.programListing.fax}</div>
                        <div><strong>Domain:</strong> <a target="_blank" rel="noopener noreferrer" href={'https://' + this.state.programListing.domain}>{this.state.programListing.domain}</a></div>
                        <br />
                        <button className="btn btn-warning btn-lg" onClick={() => this.setState({ showPane: true })}>Edit Show Program Listing</button>
                        <br />
                        <br />
                        <div><b>*Deadline is 30 days prior to the show. After this date changes will be on the addendum sheet inserted into each program.</b></div>
                        <br />
                        <div><b>The above information will be used in the show programs and other marketing materials for all shows in which you participate. Click the button above if you'd like to request changes to your listing.</b></div>
                    </Fragment>
                </div>

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='pinkCard'
                    isOpen={this.state.showPane}
                    title='Edit Show Program Listing'
                    onRequestClose={() => this.setState({ showPane: false })}
                >
                    <div className="slide-pane-body scrollableDiv">
                        <div className="row">
                            <div className="form-group col-lg-12">
                                <label className="form-label">Company Name</label>
                                <input type="text" name="name" value={this.state.formFields.name || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Start Year</label>
                                <input type="text" name="startyear" value={this.state.formFields.startyear || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Employees</label>
                                <input type="text" name="totalemployees" value={this.state.formFields.totalemployees || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Square Feet</label>
                                <input type="text" name="squarefeet" value={this.state.formFields.squarefeet || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-7">
                                <label className="form-label">Primary Category</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_Category1 || null} name="jsS_Category1" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.categoryList.map((cat, idx) => <option key={idx} value={cat.category}>{cat.categoryName}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-7">
                                <label className="form-label">Second Category</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_Category2 || null} name="jsS_Category2" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.categoryList.map((cat, idx) => <option key={idx} value={cat.category}>{cat.categoryName}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-7">
                                <label className="form-label">Third Category</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_Category3 || null} name="jsS_Category3" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.categoryList.map((cat, idx) => <option key={idx} value={cat.category}>{cat.categoryName}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-7">
                                <label className="form-label">Fourth Category</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_Category4 || null} name="jsS_Category4" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.categoryList.map((cat, idx) => <option key={idx} value={cat.category}>{cat.categoryName}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-7">
                                <label className="form-label">Fifth Category</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_Category5 || null} name="jsS_Category5" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.categoryList.map((cat, idx) => <option key={idx} value={cat.category}>{cat.categoryName}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-7">
                                <label className="form-label">Quality Certification 1</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_qc1 || null} name="jsS_qc1" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.qcList.map((qccert, idx) => <option key={idx} value={qccert.qcname}>{qccert.qcname}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-7">
                                <label className="form-label">Quality Certification 2</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_qc2 || null} name="jsS_qc2" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.qcList.map((qccert, idx) => <option key={idx} value={qccert.qcname}>{qccert.qcname}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-7">
                                <label className="form-label">Quality Certification 3</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_qc3 || null} name="jsS_qc3" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.qcList.map((qccert, idx) => <option key={idx} value={qccert.qcname}>{qccert.qcname}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-7">
                                <label className="form-label">Quality Certification 4</label>
                                <select className="form-control-custom" value={this.state.formFields.jsS_qc4 || null} name="jsS_qc4" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.qcList.map((qccert, idx) => <option key={idx} value={qccert.qcname}>{qccert.qcname}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="form-label">Quality Certification (Other - if not in drop down list):</label>
                                <input type="text" name="qcOther" value={this.state.formFields.qcOther || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-12">
                                <label className="form-label">Description (Max Characters: 375)</label>
                                <textarea type="text" name="description" value={this.state.formFields.description || ''} onChange={this.handleInputChange} className="form-control-custom" maxLength="375" onInput={this.maxLengthCheck} />
                                <label className="form-label">Characters Remaining: {this.state.chars_left}</label>
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label">Address 1</label>
                                <input type="text" name="street1" value={this.state.formFields.street1 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label">Address 2</label>
                                <input type="text" name="street2" value={this.state.formFields.street2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">City</label>
                                <input type="text" name="city" value={this.state.formFields.city || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>


                            <div className="form-group col-lg-4">
                                <label className="form-label">State</label>
                                <select className="form-control-custom" value={this.state.formFields.state} name="state" onChange={this.handleInputChange}>
                                    <option></option>
                                    {this.state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Zip</label>
                                <input type="text" name="zip" value={this.state.formFields.zip || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Phone</label>
                                <NumberFormat className="form-control-custom" name="telephone" value={this.state.formFields.telephone || ''} onChange={this.handleInputChange} format="###-###-####" mask="_" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Fax</label>
                                <NumberFormat className="form-control-custom" name="fax" value={this.state.formFields.fax || ''} onChange={this.handleInputChange} format="###-###-####" mask="_" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Domain</label>
                                <input type="text" name="domain" value={this.state.formFields.domain || ''} onChange={this.handleInputChange} className="form-control-custom" />
                            </div>

                            <div className="col col-12"><strong>Please note: The following changes will not be posted immediately. Please allow 24 hours for us to review and apply the changes to your account. You will be notified via Email when your request has been processed.</strong></div>

                            <div className="col col-12"><h4>If you have any questions, call Karen C. at 800-225-4535 ext. 138 or <a href="mailto:karenc@d2p.com">karenc@d2p.com</a></h4></div>
                             
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-danger btn-lg" onClick={this.handleSubmit} disabled={this.state.processingSubmitOrder}>Submit Change Request</button>
                            <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                            {this.state.processingSubmitOrder &&
                                <img src={spinnerLarge} alt="D2P Busy" className="margin-left-15" style={{ height: '30px', width: '30px' }} />
                            }
                        </div>
                    </div>
                </SlidingPane>
            </Fragment>
        );
    }
}

export default withRouter(ProgramListing);