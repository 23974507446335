import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './login.css';
import logo from '../../img/D2P-logo-show-login-sm.png';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import cookie from 'react-cookies';
import TFASetup from "./TFASetup";
import TFALogin from "./TFALogin";
import ReactLoading from 'react-loading';

import { smallBox } from "../../common/utils/functions";

import NumberFormat from 'react-number-format';

class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                name: '',
                company: '',
                email: '',
                phone: '',
                comments: ''
            }
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let postdata = this.state.formFields;

        if (postdata.name === '' ||
            postdata.company === '' ||
            postdata.email === '' ||
            postdata.comments === ''
        ) {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Please fill out all required fields.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            this.setState({
                loading: true
            });

            Axios.post(`/api/SendContactEmail`, postdata
            ).then(response => {

                this.setState({
                    loading: false
                });

                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Thank you for reaching out. We will get back to you as soon as possible.</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });
                this.props.goHome();
            }).catch(error => {
                this.setState({
                    loading: false
                });

                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });
            });
        }

        

    }

    render() {

        return (
            <form>
                <h3>Contact Us</h3>
                <p>We understand that you have confidential business information and want you to know that Design-2-Part Show is committed to safeguarding your privacy online.
                    We strive to make our site as secure and private as reasonably possible.
                    Design-2-Part Show is the sole owner of the information collected on this Web site and we will not sell, share or rent this information to others.</p>
                <div className="form-group">
                    <label className="form-label">Name *</label>
                    <input type="text" className="form-control-custom" name="name" value={this.state.formFields.name} onChange={this.handleInputChange} />
                </div>

                <div className="form-group">
                    <label className="form-label">Company *</label>
                    <input type="text" className="form-control-custom" name="company" value={this.state.formFields.company} onChange={this.handleInputChange} />
                </div>

                <div className="form-group">
                    <label className="form-label">Email *</label>
                    <input type="text" className="form-control-custom" name="email" value={this.state.formFields.email} onChange={this.handleInputChange} />
                </div>

                <div className="form-group">
                    <label className="form-label">Telephone</label>
                    <NumberFormat className="form-control-custom" name="phone" value={this.state.formFields.phone || ''} onChange={this.handleInputChange} format="###-###-####" mask="_" />
                </div>

                <div className="form-group">
                    <label className="form-label">Comments or Questions *</label>
                    <textarea type="text" className="form-control-custom" name="comments" value={this.state.formFields.comments} onChange={this.handleInputChange} />
                </div>
                {this.state.loading &&
                    <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#275282'} height={30} width={30} />
                }
                <button className="btn btn-default float-right" onClick={this.handleSubmit}>Submit</button>
            </form>
        );
    }
}

export default withRouter(ContactForm);
