import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import se1 from '../../../img/signExample1.png';
import se2 from '../../../img/signExample2.png';
import se3 from '../../../img/signExample3.png';

export class SignInfoSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true } : { showPane: false };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showPane: false
        }
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    render() {

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title='Sign Examples'
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    
                    <div className="row">
                        <div className="col col-sm-12 text-center mb-5">
                            <img src={se1} className="img-fluid" />
                        </div>
                        <div className="col col-sm-12 text-center mb-5">
                            <img src={se2} className="img-fluid" />
                        </div>
                        <div className="col col-sm-12 text-center mb-5">
                            <img src={se3} className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-primary btn-lg margin-left-15 float-right" onClick={this.hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}