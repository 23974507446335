import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import ExhibitorSearch from './ExhibitorSearch';
import QRCode from 'qrcode.react';
import { SmartMessageBox } from "../../common/utils/functions";
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import imgSpinnerLarge from '../../img/spinnerLarge.gif';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Moment from 'moment';
import ExhibitorLeadsSlider from './ExhibitorLeadsSlider';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Select, Spin, Alert, Empty, message } from 'antd';
import { Fragment } from 'react';

function ManageExhibitorLeadsTab(props) {
    const [state, setState] = useState({
        dataLoaded: false,
        selectedShow: props.selectedShow,
        selectedShowName: props.selectedShowName,
        exhibitorsList: [],
        selectedExhibitors: [],
        selectedSendLeadsFor: '',
        emailLeadsTo: '',
        sendingEmails: false,
        showLeadsPane: false,
        selectedExhibitorId: '',
        selectedExhibitorName: ''
    });

    const [processingSummary, setprocessingSummary] = useState('');

    var urlend = window.api.slice(-1) === '/' ? 'hubs/alert' : '/hubs/alert';
    const url = window.api + urlend;
    var connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(url)
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        connection.start()
            .then(result => {
                connection.on('ReceiveManageExhibitorLeadsAlert', alerts => {
                    //console.log(alerts);

                    setprocessingSummary(alerts);
                });
            })
            .catch(e => console.log('Connection failed: ', e));

    }, []);

    useEffect(() => {
        setState({
            ...state,
            selectedShow: props.selectedShow,
            selectedExhibitorId: ''
        });

        Axios.get(`/api/GetD2PLeadsSummaryForAllExhibitorsForShow`, {
            params: {
                showcode: props.selectedShow
            }
        }).then(response => {
            let exhibitorsList = response.data;

            setState({
                ...state,
                exhibitorsList: exhibitorsList,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });

    }, [props.selectedShow]);

    //function GetD2PLeadsSummaryForAllExhibitorsForShow() {
    //    console.log("selected show (grandchild): ", state.selectedShow);
    //    Axios.get(`/api/GetD2PLeadsSummaryForAllExhibitorsForShow`, {
    //        params: {
    //            showcode: state.selectedShow
    //        }
    //    }).then(response => {
    //        let exhibitorsList = response.data;

    //        console.log(exhibitorsList);

    //        setState({
    //            ...state,
    //            exhibitorsList: exhibitorsList,
    //            dataLoaded: true
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function editFormatter(cell, row) {
        return (
            <i className="fas fa-eye fa-125x color-pink text-center hover" id="editIcon"></i>
        );
    }

    const columns = [
    {
        isDummyField: true,
        text: 'View',
        formatter: editFormatter,
        align: 'center',
        headerStyle: { width: '50px', textAlign: 'center' }
        },
    {
        dataField: 'id',
        text: 'Id',
        hidden: true
    }, {
        dataField: 'name',
        text: 'Exhibitor'
    }, {
        dataField: 'jsS_Name',
        text: 'Contact'
    }, {
        dataField: 'email',
        text: 'Email'
    }, {
        dataField: 'totalLeads',
        text: '# of Leads',
        align: 'right',
        headerStyle: { width: '100px', textAlign: 'right' }
    }, {
        dataField: 'activated',
        text: 'Activated',
        align: 'center',
        headerStyle: { width: '100px', textAlign: 'center' }
    }
];

    const pagination = {
        sizePerPage: 50,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        hideSizePerPage: false,
        showTotal: true,

        onPageChange: function (page, sizePerPage) {

            setTableControl({
                ...tableControl,
                page
            });
        },
        onSizePerPageChange: function (page, sizePerPage) {
            
        }
    };

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const rowEvents = {
        onClick: (e, row) => {
            console.log("row selected: ", row);
            //console.log("showLeadsPane: ", state.showLeadsPane);
            setState({
                ...state,
                selectedExhibitorId: row.id,
                selectedExhibitorName: row.name,
                showLeadsPane: true
            });
        },
        onDoubleClick: (e, row, rowIndex) => {
            
        }
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <input
                type="checkbox"
                ref={(input) => {
                    if (input) input.indeterminate = indeterminate;
                }}
                {...rest}
            />
        ),
        selectionRenderer: ({ mode, ...rest }) => (
            <input type={mode} {...rest} />
        ),
        onSelect: (row, isSelect, rowIndex, e) => {
            let mySelectedExhibitorsList = state.selectedExhibitors;

            if (isSelect) {
                mySelectedExhibitorsList.push(row);
            }
            else {
                const index = mySelectedExhibitorsList.indexOf(row);
                if (index > -1) {
                    mySelectedExhibitorsList.splice(index, 1);
                }
            }

            setState({
                ...state,
                selectedExhibitors: mySelectedExhibitorsList
            });
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {

                //set selected exhibitors to entire list
                setState({
                    ...state,
                    selectedExhibitors: state.exhibitorsList
                });

                return state.exhibitorsList.map(row => row.id);
            } else {
                setState({
                    ...state,
                    selectedExhibitors: []
                });

                return [];
            }

            //let mySelectedExhibitorsList = [];

            //if (isSelect) {
            //    rows.forEach(exhibitor => {
            //        mySelectedExhibitorsList.push(exhibitor);
            //    })
            //}
            //else {
            //    //nothing needed since array was reset above
            //}

            //setState({
            //    ...state,
            //    selectedExhibitors: mySelectedExhibitorsList
            //});
        }
    };

    function handleSendLeadsForChange(e) {
        setState({
            ...state,
            selectedSendLeadsFor: e.target.value
        });
    }

    function onEmailLeadsToChange(e) {
        setState({
            ...state,
            emailLeadsTo: e.target.value
        });
    }

    function SendExhibitorLeadsEmails() {
        //console.log("selected show (grandchild): ", state.selectedShow);

        let whichDay = '';
        switch (state.selectedSendLeadsFor) {
            case 'day1':
                whichDay = 'Wednesday'
                break;
            case 'day2':
                whichDay = 'Thursday'
                break;
            case 'all':
                whichDay = 'both Wednesday & Thursday';
                break;
        }

        //indicates if emails should go to exhibitor email address or test email address
        let emailOverride = '';
        if (state.emailLeadsTo === '') {
            emailOverride = '';
        }
        else {
            emailOverride = state.emailLeadsTo
        }

        let postdata = {
            showName: state.selectedShowName,
            exhibitorList: state.selectedExhibitors,
            whichday: state.selectedSendLeadsFor,
            showcode: parseInt(state.selectedShow),
            emailOverride: emailOverride
        }

        //console.log(postdata);

        Axios.post(`/api/SendExhibitorLeadsEmails`, postdata
        ).then(response => {
            setState({
                ...state,
                sendingEmails: false
            });

            toast.success("Emails successfully sent to " + response.data + " exhibitors");

            setprocessingSummary("Emails successfully sent to " + response.data + " exhibitors");
        }).catch(error => {

        });
    }

    function handleSendEmails(e) {
        e.preventDefault();

        if (state.selectedSendLeadsFor === '') {
            toast.error("You must specify which day(s) to send leads for");

            return;
        }

        if (state.selectedExhibitors.length === 0) {
            toast.error("No exhibitors have been selected");

            return;
        }

        var myConfirmationMsg = "";
        if (state.emailLeadsTo === '') {
            myConfirmationMsg = "You are about to email " + state.selectedExhibitors.length + " selected exhibitors";
        }
        else {
            myConfirmationMsg = "You are about to send " + state.selectedExhibitors.length + " exhibitor emails to " + state.emailLeadsTo;
        }

        SmartMessageBox(
            {
                title: myConfirmationMsg,
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    e.preventDefault();

                    setState({
                        ...state,
                        sendingEmails: true
                    });

                    SendExhibitorLeadsEmails();
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function hidePane() {
        //console.log('ManageExhibitorLeads hidePane invoked');

        setState({
            ...state,
            showLeadsPane: false
        });
    }

    return (
        <div className="code-box-demo">
            <h3>Exhibitor Leads</h3>
            <div className="row">
                <div className="col-lg-9">
                    <div className="fullTable">
                        {/*{state.dataLoaded &&*/}
                        {/*    <BootstrapTable*/}
                        {/*      keyField='id'*/}
                        {/*      data={state.exhibitorsList}*/}
                        {/*      columns={ columns }*/}
                        {/*      selectRow={selectRow}*/}
                        {/*      rowEvents={rowEvents}*/}
                        {/*      pagination={paginationFactory(pagination)}*/}
                        {/*      hover condensed striped*/}
                        {/*    />*/}
                        {/*}*/}
                        <Spin spinning={!state.dataLoaded}>
                            <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                <Alert
                                    message="Loading Exhibitor Leads For Selected Show"
                                    description="Please stand by while we retrieve all exhibitor leads for the selected show"
                                    type="info"
                                />
                            </div>
                            {state.dataLoaded &&
                                <Fragment>
                                    {state.exhibitorsList.length === 0 ?
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                            <span>
                                                No Exhibitor Leads Found For Selected Show
                                            </span>
                                        } />
                                        :
                                        <div className="fullTable">
                                            <ToolkitProvider
                                                keyField='id'
                                                data={state.exhibitorsList}
                                                columns={columns}
                                                search
                                            >
                                                {props => (
                                                    <div>
                                                        <BootstrapTable
                                                            {...props.baseProps}
                                                            filterPosition="bottom"
                                                            striped hover condensed
                                                            pagination={paginationFactory(pagination)}
                                                            rowEvents={rowEvents}
                                                            selectRow={selectRow}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </Spin>

                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="row">
                        <h3>Email Leads To Exhibitor</h3>
                    </div>
                    <div className="row">
                        <div onChange={handleSendLeadsForChange}>
                            <label className="form-label">Send Leads For:</label>&nbsp;&nbsp;
                            <input type="radio" name="sendLeadsFor" value="day1" className="mt-1" checked={state.selectedSendLeadsFor === "day1"} />&nbsp;<label className="form-label">Day 1</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" name="sendLeadsFor" value="day2" className="mt-1" checked={state.selectedSendLeadsFor === "day2"} />&nbsp;<label className="form-label">Day 2</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" name="sendLeadsFor" value="all" className="mt-1" checked={state.selectedSendLeadsFor === "all"} />&nbsp;<label className="form-label">All</label>
                        </div>
                    </div>
                    <div className="row">
                        <label className="form-label">Send Leads To:</label>
                    </div>
                    <div className="row">
                        <input type="text" className="form-control-custom" name="emailLeadsto" value="" placeholder="Leaving blank will send to exhibitor's actual email address" value={state.emailLeadsTo}
                            onChange={onEmailLeadsToChange} />
                    </div>
                    <div className="row">
                        <button type="button" onClick={handleSendEmails} className="btn btn-danger btn-block mt-1" style={{ width: "150px" }} >
                            Send Email(s)
                        </button>&nbsp;&nbsp;
                        {state.sendingEmails == true &&
                            <img src={imgSpinnerLarge} width="50px" height="50px" />
                        }
                        
                    </div>
                    <div className="row">
                        <label className="form-label">{processingSummary}</label>
                    </div>
                </div>
            </div>

            <ExhibitorLeadsSlider showPane={state.showLeadsPane} selectedShow={state.selectedShow} selectedShowName={state.selectedShowName} selectedExhibitorId={state.selectedExhibitorId} selectedExhibitorName={state.selectedExhibitorName} hidePane={() => setState({ ...state, showLeadsPane: false })} {...props} />
        </div>
    );
}

export default withRouter(ManageExhibitorLeadsTab);