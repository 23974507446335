import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Fragment } from 'react';
import { Spin, Switch, Alert } from 'antd';
import * as ShowCards from './ShowCards/index';
import * as ShowSliders from './ShowSliders/index';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";

class ShowDashboard extends Component {

    

    constructor(props) {
        super(props);

        this.state = {
            showInfo: {},
            showInfoLoaded: false,
            showDetails: {},
            showInvoiceSlider: false,
            tempCheckOrderStatus: false,
            showFloorplanSlider: false,
            showContractModal: false,
            msgType: 'initial',
            declineButtonText: 'DECLINE',
            boothStr: '',
            base64FloorPlan: '',
        }
    }

    componentDidMount() {
        this.getShowInformation();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location?.state?.showcode !== this.props.location?.state?.showcode) {
            //console.log(this.props.location);

            this.setState({
                showInfoLoaded: false
            });

            this.getShowInformation();
        }
        if (this.props.updateOrder) {
            this.getShowInformation();
            this.setState({
                tempCheckOrderStatus: true,
                showInfoLoaded: true
            }, () => {
                    this.setState({
                        tempCheckOrderStatus: false
                    });
            })
        }
    }

    getShowInformation = () => {
        var myBoothStr = '';

        Axios.get(`/api/GetShowInformation`, {
            params: {
                id: this.props.location?.state?.showcode || Globals.defaultShowCode.toString(),
                companyId: Globals.userInfo.companyId
            }
        }).then(response => {
            myBoothStr = response.data.showDetail.booth1;
            if (response.data.showDetail.booth2) { myBoothStr += ', ' + response.data.showDetail.booth2; }
            if (response.data.showDetail.booth3) { myBoothStr += ', ' + response.data.showDetail.booth3; }
            this.setState({
                showInfo: response.data.showMaster,
                showDetails: response.data.showDetail,
                showInfoLoaded: true,
                boothStr: myBoothStr,
                base64FloorPlan: response.data.base64FloorPlan
            });

        }).catch(error => {
            console.log(error);
        });
    }

    constructQCString = () => {
        let qcString = "";
        let formFields = this.state.formFields;

        if (formFields.jsS_qc1) {
            qcString += formFields.jsS_qc1 + ", ";
        }
        if (formFields.jsS_qc2) {
            qcString += formFields.jsS_qc2 + ", ";
        }
        if (formFields.jsS_qc3) {
            qcString += formFields.jsS_qc3 + ", ";
        }
        if (formFields.jsS_qc4) {
            qcString += formFields.jsS_qc4;
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    render() {
        return (
            <div>
                {this.state.showInfoLoaded &&
                    <div className="row"><h3><b className="sub-header-title">{this.state.showInfo.name}, Booth # {this.state.boothStr}</b></h3> &emsp; <p className="a" onClick={() => this.setState({ showFloorplanSlider: true })}>View Floorplan</p> &emsp; <p className="a" onClick={() => this.setState({ showInvoiceSlider: true })}>View Invoice</p></div>
                }

                <Spin spinning={!this.state.showInfoLoaded}>
                    <div style={!this.state.showInfoLoaded ? {} : { display: 'none' }}>
                        <Alert
                            message="Loading Show Information"
                            description="Please stand by while personalize your show experience."
                            type="info"
                        />
                    </div>
                    {this.state.showInfoLoaded &&
                        <div className="row">
                            <div className="col col-lg-6 col-md-12">
                                <ShowCards.ShowInfoCard {...this.props} showInfo={this.state.showInfo} showDetails={this.state.showDetails} />
                            </div>
                            <div className="col col-lg-6 col-md-12">
                                <ShowCards.SuppliedServices {...this.props} showInfo={this.state.showInfo} showDetails={this.state.showDetails} showInfoLoaded={this.state.showInfoLoaded} tempCheckOrderStatus={this.state.tempCheckOrderStatus} />
                                <ShowCards.PremiumServicesCard {...this.props} showInfo={this.state.showInfo} />
                                <ShowCards.PayServicesCard {...this.props} showInfo={this.state.showInfo} />
                                <ShowCards.ShowProgramCard {...this.props} showInfo={this.state.showInfo} />
                            </div>
                        </div>
                    }
                </Spin>

                <ShowSliders.InvoiceSlider showPane={this.state.showInvoiceSlider} showInfo={this.state.showInfo} showDetails={this.state.showDetails} hidePane={() => this.setState({ showInvoiceSlider: false })} {...this.props} />
                <ShowSliders.FloorplanSlider showPane={this.state.showFloorplanSlider} showInfo={this.state.showInfo} showDetails={this.state.showDetails} base64FloorPlan={this.state.base64FloorPlan} hidePane={() => this.setState({ showFloorplanSlider: false })} {...this.props} />
            </div>
        );
    }
}

export default withRouter(ShowDashboard);