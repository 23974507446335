import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './login.css';
import logo from '../../img/D2P-logo-show-login-sm.png';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import cookie from 'react-cookies';
import TFASetup from "./TFASetup";
import TFALogin from "./TFALogin";
import ReactLoading from 'react-loading';
import { toast, confirm } from '@rickylandino/react-messages';
import { smallBox } from "../../common/utils/functions";

import { Modal } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

class ForgotPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                userid: ''
            }
        }
    }

    componentDidMount() {
        console.log('forgot pw hit');
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            loading: true
        });

        let postdata = {
            id: this.state.formFields.userid
        };

        //First, check to see if exhibitor is alreay registered
        Axios.get(`/api/GetAllMSOContactsByEmail`, {
            params: {
                email: this.state.formFields.userid
            }
        }).then(response => {
            var contactsList = response.data;

            console.log(contactsList.length);
            if (contactsList.length === 0) {
                this.setState({
                    loading: false
                });

                let message = "Email address not found. Please call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.";
                toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })
                return;
            }
            else {
                Axios.post(`/api/MSO_SendPasswordResetEmail`, postdata
                ).then(response => {

                    this.setState({
                        loading: false
                    });

                    toast.success("An email has been sent to " + this.state.formFields.userid + " with instructions and a link to reset your password");

                    this.props.goHome();
                }).catch(error => {
                    this.setState({
                        loading: false
                    });

                    let message = "Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.";
                    toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })
                });
            }
        }).catch(error => {
            console.log(error);
        });

        
    }

    render() {

        return (
            <form>
                <h3>Create/Reset Your Password</h3>
                <p>Enter the username/email address associated with your account. Instructions on how to update your password will be sent to this email address.</p>
                <div className="form-group">
                    <label className="form-label" htmlFor="userid">Enter the email address associated with the account</label>
                    <input type="text" id="userid" className="form-control-custom" name="userid" value={this.state.formFields.userid} onChange={this.handleInputChange} />
                    {/*<span className="help-block">*/}
                    {/*    Your email*/}
                    {/*            </span>*/}
                </div>
                {this.state.loading &&
                    <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#275282'} height={30} width={30} />
                }
                <button className="btn btn-default float-right" onClick={this.handleSubmit}>Submit</button>
            </form>
        );
    }
}

export default withRouter(ForgotPassword);
