import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Axios from '../../config/axios';
import { smallBox } from "../../common/utils/functions";
import { toast, confirm } from '@rickylandino/react-messages';

import ReactLoading from 'react-loading';

import './login.css';
import logo from '../../img/D2P-logo-show-login-sm.png';

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkExpired: false
        }

        this.setLinkExpired = this.setLinkExpired.bind(this);
        this.requestLink = this.requestLink.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidMount() {
        let postdata = {
            name: this.props.match.params.date
        }

        Axios.post(`/api/MSO_DecodeDate`, postdata
        ).then(response => {
            if (response.data) {
                this.setState({
                    linkExpired: true
                });
            }
        }).catch(error => {
            console.log(error);
        });
        
    }

    setLinkExpired() {
        this.setState({
            linkExpired: true
        });
    }

    requestLink() {
        var email = "";
        let postdata = {};
        postdata.email = this.props.match.params.id;
        Axios.post(`/api/MSO_ResendEmail`, postdata
        ).then(response => {
            toast.success("An email to reset your password has been sent.");
            
            this.props.history.push({
                pathname: '/'
            });
        }).catch(error => {
            console.log(error);
        });
        
        
    }

    sendEmail(username) {

        var date = new Date();
        var secret = 'mysecret';

        let postdata = {};
        postdata.email = username;
        Axios.post(`/api/SendUserEmail`, postdata
        ).then(response => {
        }).catch(error => {
            console.log(error);
        });
    }

    handleSubmit = (e) => {
        e.preventDefault(); 

        this.setState({
            loading: true
        });

        var newpass = document.getElementById("newpass");
        var newpass2 = document.getElementById("newpass2");

        if (newpass.value === '') {
            this.setState({
                loading: false
            });
            toast.error("Passwords cannot be blank. Please try again.");
        }
        else if (newpass.value !== newpass2.value) {
            this.setState({
                loading: false
            });
            toast.error("Passwords do not match. Please try again.");
        }
        else if (newpass.value.length < 8) {
            this.setState({
                loading: false
            });
            toast.error("Passwords must be a minumum of 8 characters. Please try again.");
        }
        else {
            let postdata = {};
            postdata.password = newpass.value;
            postdata.email = this.props.match.params.id;

            Axios.post(`/api/MSO_ResetUserPassword`, postdata
            ).then(response => {
                this.setState({
                    loading: false
                });

                if (response.data === true) {
                    toast.success("Your password has been reset.");
                }
                else {
                    let message = "Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.";
                    toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 });
                }
            }).catch(error => {
                this.setState({
                    loading: false
                });

                let message = "Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.";
                toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 });
            });

            let path = '/';
            this.props.history.replace({ pathname: path });
        }
    }

    goHome = () => {
        let path = '/';
        this.props.history.replace({ pathname: path });
    }

    render() {
        const expiredLink = this.state.linkExpired;
        return (
            
            <div className="login-container">
                        <div className="background">
                            <div className="blankpage-form-field">
                                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                                    <img src={logo} height="30px" alt="Design-2-Part Shows My Show Online" aria-roledescription="logo" />
                                    <div><div className="page-logo-text-header mr-1">MY SHOW ONLINE</div><div className="page-logo-text mr-1">Your D2P Customer Service Center</div></div>
                                </div>
                        <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                            {expiredLink ? (
                                <div>This link has expired. Please&nbsp;
                                    <span onClick={this.requestLink} className="a">click here</span> to request a new password reset link.</div>
                            ) : (
                                    <form>
                                        <h3>Password Reset</h3>
                                        <p>Enter your new password</p>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="password">New Password</label>
                                            <input id="newpass" type="password" className="form-control mb-4" />

                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="password">Confirm New Password</label>
                                            <input id="newpass2" type="password" className="form-control mb-4" />

                                        </div>
                                        {this.state.loading &&
                                            <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#275282'} height={30} width={30} />
                                        }
                                        <button className="btn btn-default float-right" onClick={this.handleSubmit}>Submit</button>
                                    </form>
                                )}
                                </div>


                                <div className="blankpage-footer text-center row">
                                    <div className="myshowonlineTextA col-6 align-items-right a" onClick={this.goHome}><strong>Go To Login</strong></div>
                                    <div className="myshowonlineTextA col-6 align-items-left"><strong>Contact Us</strong></div>
                        &nbsp;&nbsp;&nbsp;
                    </div>
                            </div>
                        </div>
                        

                
                
            </div>
        );
    }
}

export default withRouter(ResetPassword);