import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';
import EmailManager from './EmailManager';

export default function ManagementDashboard(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({

    });

    const { TabPane } = Tabs;

    useEffect(() => {

    }, [props]);

    return (
        <div className="row">
            <div className="form-group col-lg-2 col-12">
                <h3 className="frame-heading">
                    Management Dashboard
                </h3>
            </div>
            <div className="form-group col-lg-2 col-12"></div>

            <div className="col-lg-12">
                <Tabs defaultActiveKey='tab-email-manager' tabPosition='top' type='card' destroyInactiveTabPane={true}>
                    <TabPane tab='Email Manager' key='tab-email-manager'>
                        <EmailManager reload={true} />
                    </TabPane>
                    {/*<TabPane tab='Email Server' key='tab-email-server'>*/}
                    {/*    <MailServerManager />*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab='Email Templates' key='tab-email-templtes'>*/}
                    {/*    <EmailTemplateManager />*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab='Email Recipients' key='tab-email-recipients'>*/}
                    {/*    <EmailRecipientManager />*/}
                    {/*</TabPane>*/}
                </Tabs>
            </div>
        </div>
    );
}