import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Fragment } from 'react';

class ExhibitorScamWarning extends Component {

    constructor(props) {
        super(props);

    }

    componentDidUpdate(prevProps) {

    }

    componentDidMount() {

    }

    render() {
        return (
            <Fragment>
                
                <h1>{this.props.location.pathname === '/exhibitor-scam-warning' && <Fragment>Exhibitor Warning</Fragment>}</h1>
                
                <div className={this.props.location.pathname === '/exhibitor-scam-warning' ? "address" : ''}>
                    <Fragment>
                        <div className="row pb-5">
                            Design-2-Part has seen a recent increase in scam emails claiming to represent, or be affiliated with, our organization. The most common claim is they have access to
                            our attendee emails from various shows and in some cases, the solicitations include our company logo.<br /><br />

                            <b>For reference, D2P attendee emails and direct mail addresses are never sold on a per show basis and would ONLY BE AVAILABLE THROUGH D2P directly.</b>
                        </div>
                        <div className="row">
                            General Email Solicitation Guidelines and Red Flags:
                        </div>
                        <div className="row pb-5">
                            <ul>
                                <li><b>Always double check with parent companies to confirm they are behind an offer.</b></li>
                                <li>Check if the address matches the name of the sender and whether the domain of the company is correct.</li>
                                <li>Check for spelling errors in your name and the sender's name.</li>
                                <li>Do not respond to these emails (even to "unsubscribe"). It oftentimes confirms to them they have reached a valid email address.</li>
                            </ul>
                        </div>

                        <div className="row pb-5">
                            If you ever suspect an email offer from Design-2-Part to be fraudulent, just delete
                            or email your sales representative directly with questions. Our sales team can be
                            reached at 800-225-4535.
                        </div>

                        <div className="row pb-5">
                            Sincerely,<br />
                            Chris Davis<br />
                            V. P. Tradeshows
                        </div>
                    </Fragment>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(ExhibitorScamWarning);