import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import ellogo from '../../../img/Screengrab_ExhibListLogo.jpg';
import dlhomepg from '../../../img/Screengrab_DirectoryListingHomePg.jpg';
import fphomepg from '../../../img/Screengrab_FloorPlanHomePg.jpg';
import pcat from '../../../img/Screengrab_ProductCategory.jpg';
import lany from '../../../img/Screengrab_lanyards.jpg';
import gbags from '../../../img/Screengrab_bags.jpg';

export class SponsorshipsSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true } : { showPane: false };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showPane: false
        }
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    render() {

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title='Sponsorships'
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body scrollableDiv">

                    <div className="row">
                        <div>
                            <div className="top-banner-border-bottom top-banner"><strong>Sponsorships</strong></div><br /><br />
                            <div>Increase your booth traffic and bring awareness to attendees before during and after the show! According to the Center for Exhibition Industry Research (CIER), sponsorships can increase your booth traffic and attendee interest to your company by over 100%. Maximize your company's ROI by including sponsorships to your exhibiting experience.</div>
                            <br />

                            <div class="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Sponsorships include:</h2></div>

                            <ul>
                                <li>Company logo - Exhibitor list (D2P.com)</li>
                                <li>Digital Banners D2P.com (multiple options available)</li>
                                <li>Attendee Lanyards</li>
                                <li>Attendee Tote Bags</li>
                            </ul>
                            <br />


                            <div>
                                <div className="sub-header-title"><h2 style={{ textDecoration: 'none' }}><strong>Company Logo for Exhibitor Listing</strong></h2></div>
                                <div>Companies can add their company logo to their online exhibitor listing. There is a nominal fee of $90.00 per show <em>(A vector image of your logo is required)</em>.</div>
                                <div><br />
                                    <img src={ellogo} className="img-fluid" width="50%" alt="" style={{ border: '2px solid black' }} />
                                </div>
                            </div>
                            <br />

                            <div className="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Digital Banners</h2></div>
                            <div>Place a digital banner on our show website (D2P.com) where OEM attendees view who is exhibiting, research &amp; plan their visit and register to attend the show. All banners include a promotional message and link to your website. The cost to create a digital banner is included with this promotion. Three types of digital banners are available:</div><br /><br />
                            <ul>
                                <li><h3>Directory Listing Homepage Banner:&nbsp;</h3>
		                        <div>This banner is exclusive to only one company per show. It is live 8 weeks prior to the show. This banner is located on the main landing page where attendees plan their visit.</div></li>
                            </ul>
                            <div><img style={{ border: '2px solid black' }} src={dlhomepg} width="50%" alt="" /> </div>
                            <br />
                            <ul>
                                <li><h3>Floor Plan Homepage Banner:&nbsp;</h3>
                                <div>This banner is exclusive to only one company per show. It is live 6 weeks prior to the show. The banner is seen any time an attendee visits our interactive floor plan for planning their visit.</div></li>
                            </ul>
                                <div><img style={{ border: '2px solid black' }} src={fphomepg} width="50%" alt="" /> </div>
                            <br />
                            <ul>
                                <li><h3>Product Category Banner:&nbsp;</h3>
                                <div>This banner is shown on our Products/Service Categories page. It is live 8 weeks prior to the show. The banner is located on the main landing page where attendee plan their visit. Each product or service category banner is limited to a total of five companies and changes each time the page refreshes.</div></li>
                            </ul>
                            <div><img style={{ border: '2px solid black' }} src={pcat} width="50%" alt="" /> </div>

                            <br /><br />
                            <div class="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Attendee Lanyards</h2></div>
                            <div>Lanyards are handed out to every attendee that comes to visit the show. They are an inexpensive way to help with your company's branding. They offer high visibility throughout the show and become a take home item for all attendees. There is a variety of lanyard materials and ink colors to choose from. The cost for each show runs between $1,500 to $2,500 and is based on the number of attendees. Sponsor one show or choose a multi-show package for a volume discount.<br /></div><br />

                            <div><img style={{ border: '2px solid black' }} src={lany} width="50%" alt="" /></div>


                            <br /><br />
                            <div class="sub-header-title"><h2 style={{ textDecoration: 'none' }}>Attendee Tote Bags</h2></div>
                            <div>A sponsorship of attendee bags imprinted with your company name and logo are handed out to every attendee that comes to visit the show. These bags are an inexpensive way to help with your company's branding. They offer high visibility throughout the show and become a take home item for the attendees <em>(Offered as a multi-show sponsorship only)</em>.</div>

                            <br />
                            <div><img style={{ border: '2px solid black' }} src={gbags} width="50%" alt="" /></div>

                            <br /><br />
                            <div>
                                Don't see a sponsorship you had in mind? We welcome your suggestions. If you have a specific budget in mind for promotional items, we would be happy to create a package catered to your needs. Please call Jeff Griffin at <strong>800-225-4535 ext. 123</strong> or email <a href="mailto:jeff@d2p.com">jeff@d2p.com</a> for more details and cost of promotional items.<br /><br />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}