import React, { Component, Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import '../../../css/card.css';
import Axios from '../../../config/axios';
import Globals from '../../../config/globals';
import { Modal } from 'react-bootstrap';
import googlePlayImg from '../../../img/g_play.png';
import appStoreImg from '../../../img/a_store.png';
import { smallBox, SmartMessageBox } from "../../../common/utils/functions";
import NumberFormat from 'react-number-format';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { message } from 'antd';

export class PayServicesCard extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showInfo.name !== prevState.showInfo.name && nextProps.showInfo.show_abbrev !== prevState.showInfo.show_abbrev) {
            return { showInfo: nextProps.showInfo }
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showInfo: this.props.showInfo,
            showInstructionModal: false,
            showOrderModal: false,
            formFields: {},
            showOrderModalForm: true
        }
    }

    

    download = (name, blob) => {
        let postdata = {
            id: this.props.location?.state?.showcode || Globals.defaultShowCode,
            name,
            blob
        };

        const key = "downloadDoc";
        message.loading({
            content: 'Downloading Service Form...',
            key,
            duration: 0
        });

        Axios.post(`/api/DownloadAttachment`, postdata, { responseType: 'blob' }
        ).then(response => {
            message.destroy(key);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(error => {
            console.log(error);
        });

    }

    

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    

    render() {

        return (
            <Card className="showCard">
                <Card.Header><span>Decorator & Convention Center Service Forms</span></Card.Header>
                <Card.Body>
                    {this.state.showInfo.myJobShopShowManualFlag ?
                        <Fragment>
                            <div className="text-center a">
                                {this.state.showInfo.electricalFormText &&
                                    <p onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_Electrical_Order.pdf', this.state.showInfo.electricalForm)}>Electrical Order (PDF)</p>
                                }
                            </div>
                            <div className="text-center a">
                                {this.state.showInfo.internetFormText &&
                                    <p onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_Internet_Order.pdf', this.state.showInfo.internetForm)}>Internet Order (PDF)</p>
                                }
                            </div>
                            <div className="text-center a">
                                {this.state.showInfo.audioVisualFormText &&
                                    <p onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_Audio_Visual_Order.pdf', this.state.showInfo.audioVisualForm)}>Audio Visual Order (PDF)</p>
                                }
                            </div>
                            <div className="text-center a">
                                {this.state.showInfo.laborFormText &&
                                    <p onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_Labor_Services.pdf', this.state.showInfo.laborForm)}>Labor Services (PDF)</p>
                                }
                            </div>
                            <div className="text-center a">
                                {this.state.showInfo.supBoothFormText &&
                                    <p onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_Supplemental_Booth_Furnishings.pdf', this.state.showInfo.supBoothForm)}>Supplemental Booth Furnishings (PDF)</p>
                                }
                            </div>
                        </Fragment>
                        :
                        <div className="text-center p-5"><h4>You'll be notified by email when show order forms are available.</h4></div>
                        }
                </Card.Body>

                

            </Card>
        );
    }
}