import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { Select, List, message, AutoComplete, Spin } from 'antd';
import { smallBox, SmartMessageBox } from "../../common/utils/functions";
import Moment from 'moment';
import ribbonLogo from '../../img/D2P-logo-show-sm.png';
import { Button, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { LoadingOutlined } from '@ant-design/icons';
import { toast, confirm } from '@rickylandino/react-messages';
import { reloadCompany } from '../../common/services/CompanyService';
import { reloadLayout } from '../../common/services/CompanyService';

class LeftNav extends Component {
    constructor(props) {
        super(props);

        if (props.enrolledShows?.length > 0) {
            Globals.defaultShowCode = props.enrolledShows[0].showDetail.showcode.toString();
        }

        this.state = {
            showOnMobile: false,
            enrolledShows: props.enrolledShows,
            formFields: {
                company_id: Globals.userInfo.companyId
            },
            showContractModal: false,
            showImpersonateModal: false,
            msgType: 'initial',
            declineButtonText: 'DECLINE',
            selectedShow: '',
            showMenuId: '',
            lastGoodMenuId: '',
            exhibitorHasLeadsToManage: false,
            customersListData: [],
            contactsListData: [],
            impersonateCompany: '',
            impersonateContact: '',
            showDropdown: false,
            searchResultsLoaded: false,
        }
    }

    componentDidMount() {
        this.handleNavigationChange();

        this.reloadEnrolledShows(Globals.userInfo.companyId);

        Axios.get(`/api/DoesExhibitorHaveLeadsToManage`, {
            params: {
                id: Globals.userInfo.companyId
            }
        }).then(response => {
            var exhibitorHasLeadsToManage = response.data;

            this.setState({
                exhibitorHasLeadsToManage
            });

        }).catch(error => {
            console.log(error);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        //if (this.state !== prevState) {
        //    console.log('something really changed');
        //}
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : parseInt(target.value);
        const name = target.name;

        Globals.userInfo.companyId = value;
        window.sessionStorage.setItem("userInfo", JSON.stringify(Globals.userInfo));

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });

        this.reloadCompanyInfo(value);
        this.reloadEnrolledShows(value);
    }

    //antD handler
    //handleLocationChange = (value) => {
    //    Globals.userInfo.companyId = value;
    //    window.sessionStorage.setItem("userInfo", JSON.stringify(Globals.userInfo));

    //    this.setState({ formFields: { ...this.state.formFields, company_id: value } });

    //    this.reloadCompanyInfo(value);
    //    this.reloadEnrolledShows(value);
    //}

    reloadCompanyInfo = (myCompanyId) => {
        console.log(myCompanyId);

        Axios.get(`/api/GetAdminCompanyInfo`, {
            params: {
                id: myCompanyId
            }
        }).then(response => {
            console.log(response.data);

            let userInfo = Globals.userInfo;
            userInfo.customerInfo = response.data[0].customer;
            userInfo.companyName = response.data[0].customer.name;
            Globals.userInfo = userInfo;
            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

            reloadLayout.next(true);
        }).catch(error => {
            console.log(error);
        });
    }

    handleNavigationChange = () => {
        $('.nav li').removeClass("active-nav-item");
        //console.log(this.props.location.pathname);
        switch (this.props.location.pathname) {
            case '/exhibitor-home':
                $("#companyProfile").addClass("active-nav-item");
                break;
            case '/program-listing':
                $("#programListing").addClass("active-nav-item");
                break;
            case '/exhibitor-scam-warning':
                $("#exhibitorScamWarning").addClass("active-nav-item");
                break;
            case '/show-dashboard':
                $("#showDashboard"+0).addClass("active-nav-item");
                break;
            case '/manage-my-leads':
                $("#manageMyLeads").addClass("active-nav-item");
                break;
            default:
                break;
        }
    }

    handleMyShowLeadsClick = () => {
        $('.nav li').removeClass("active-nav-item");

        let path = '/manage-my-leads';
        this.props.history.replace(path);
    }

    handleNavigationClick = (event, id) => {
        //console.log(event.target.id);
        console.log(id);

        event.preventDefault();
        let path = '';
        $('.nav li').removeClass("active-nav-item");

        this.setState({
            showMenuId: id
        });

        switch (event.target.id) {
            case 'companyProfile':
                path = '/exhibitor-home';
                this.props.history.replace(path);
                $("#companyProfile").addClass("active-nav-item");
                break;
            case 'programListing':
                path = '/program-listing';
                this.props.history.replace(path);
                $("#programListing").addClass("active-nav-item");
                break;
            case 'exhibitorScamWarning':
                path = '/exhibitor-scam-warning';
                this.props.history.replace(path);
                $("#exhibitorScamWarning").addClass("active-nav-item");
                break;
            case 'showDashboard':
                this.setState({
                    selectedShow: event.target.getAttribute('name')
                });

                Axios.get(`/api/CheckForSignedExhibitionContract`, {
                    params: {
                        id: Globals.userInfo.companyId,
                        showcode: event.target.getAttribute('name')
                    }
                }).then(response => {
                    var ContractAccepted = response.data;
                    //console.log('ContractAccepted: ' + ContractAccepted);
                    if (ContractAccepted == false) {
                        this.setState({
                            agreesToContract: 'yes',
                            showContractModal: true
                        });
                    }
                    else {
                        this.setState({
                            lastGoodMenuId: id
                        });

                        path = {
                            pathname: '/show-dashboard', state: { showcode: this.state.selectedShow }
                        };
                        this.props.history.replace(path);
                        $("#showDashboard" + id).addClass("active-nav-item");
                    }
                }).catch(error => {
                    console.log(error);
                });

                break;
            case 'admin':
                path = '/admin-home';
                this.props.history.replace(path);
                break;
            case 'worklist':
                path = '/worklist';
                this.props.history.replace(path);
                break;
            case 'tab4a':
                $(".listHeader").removeClass("active open");
                $("#tab3List").toggleClass("active open");
                path = '/providers';
                this.props.history.replace(path);
                break;
            case 'manageMyLeads':
                path = '/manage-my-leads';
                this.props.history.replace(path);
                $("#manageMyLeads").addClass("active-nav-item");
                break;
            default:
                break;
        }
    }

    handleLogout = (event) => {
        SmartMessageBox(
            {
                title: "You are about to logout.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    event.preventDefault();
                    let path = '';
                    path = '/';

                    Globals.isDirtyPage = false;
                    Globals.userInfo = {};
                    this.props.history.replace(path);
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    toggleContractModal = (event) => {
        //event.preventDefault();

        this.setState({
            showContractModal: !this.state.showContractModal
        });
    }

    handleModalClick = (event) => {
        event.preventDefault();

        this.setState({
            showContractModal: !this.state.showContractModal
        });
    }

    handleModalSubmit = (event) => {
        let path = '';

        event.preventDefault();

        if (this.state.agreesToContract === 'yes') {
            var myContractConfirmationDetails = 'Accepted by ' + Globals.userInfo.username + ' on ' + Moment().format("MM-DD-YYYY hh:mm:ss A");
            //console.log(myContractConfirmationDetails.toString());

            let postdata = {
                id: Globals.userInfo.companyId.toString(),
                showcode: this.state.selectedShow,
                onlinecontractstatus: myContractConfirmationDetails.toString()
            };

            Axios.post(`/api/UpdateOnlineContractStatus`, postdata
            ).then(response => {
                if (response.data == "OK") {
                    smallBox({
                        title: "Notice",
                        content: "Thank you for agreeing to the Exhibition Contract in its entirety",
                        color: "#659265",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 7000
                    });
                }
            }).catch(error => {
                console.log(error);
            });

            this.setState({
                showContractModal: false,
                lastGoodMenuId: this.state.showMenuId
            });

            path = {
                pathname: '/show-dashboard', state: { showcode: this.state.selectedShow }
            };
            this.props.history.replace(path);
            $('.nav li').removeClass("active-nav-item");
            $("#companyProfile").addClass("active-nav-item");
        }
        else {
            if (this.state.declineButtonText === 'I REALLY WANT TO DECLINE') {
                this.setState({
                    msgType: 'initial',
                    declineButtonText: 'DECLINE',
                    showContractModal: false,
                });
                $('.nav li').removeClass("active-nav-item");
                $("#companyProfile").addClass("active-nav-item");

                let postdata = {
                    reqExhibitor: Globals.userInfo.companyName + " (" + Globals.userInfo.companyId + ")",
                    email: Globals.userInfo.username
                }

                Axios.post(`/api/SendContractDeclinedEmail`, postdata
                ).then(response => {
                    smallBox({
                        title: "Notice",
                        content: "A Customer Service Representative will be contacting you in regards to our Exhibition Contract shortly. You will be unable to make any changes to the selected show until we speak with you.",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 7000
                    });
                }).catch(error => {
                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.</i>",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });
                });
            }
            else {
                this.setState({
                    msgType: 'secondchance',
                    declineButtonText: 'I REALLY WANT TO DECLINE',
                    agreesToContract: 'yes'
                });
            }
        }
    }

    handleModalInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            agreesToContract: value
        });
    }

    handleImpersonateClick = (event) => {
        event.preventDefault();

        this.setState({
            impersonateContact: '',
            contactsListData: [],
            showImpersonateModal: !this.state.showImpersonateModal
        });
    }

    handleStopImpersonatingClick = (event) => {
        event.preventDefault();

        this.setState({
            impersonateContact: '',
            contactsListData: []
        });

        Globals.updateLayout = true;
        Globals.isImpersonating = false;
        window.sessionStorage.setItem("isImpersonating", false);

        //Reset user to the real user logged in, not the exhibitor being impersonated
        Globals.userInfo = JSON.parse(window.sessionStorage.getItem("realUserInfo"))
        window.sessionStorage.setItem("userInfo", JSON.stringify(Globals.userInfo));

        //Clear the user being impersonated
        Globals.impersonatingUserInfo = {};
        window.sessionStorage.setItem("impersonatingUserInfo", JSON.stringify(Globals.impersonatingUserInfo));

        reloadLayout.next(true);
        reloadCompany.next(true);

        this.reloadEnrolledShows(Globals.userInfo.companyId);

        this.setState({ formFields: { ...this.state.formFields, company_id: Globals.userInfo.companyId } });

        //let path = '/exhibitor-home';
        //this.props.history.replace(path);

        //$('.nav li').removeClass("active-nav-item");
        //$("#companyProfile").addClass("active-nav-item");
    }

    handleModalImpersonateSubmit = () => {
        if (typeof (this.state.impersonateContact) === "undefined" || this.state.impersonateContact === 'Select Contact' || this.state.impersonateContact === '') {
            toast.error("You must first select a contact to impersonate");
        }
        else {
            Axios.get(`/api/GetContactInfoByContactId`, {
                params: {
                    id: this.state.impersonateContact
                }
            }).then(response => {
                let retval = response.data;

                this.getUserInfoForImpersonation(retval.email, retval.myJSSPassword);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    toggleImpersonateModal = (event) => {
        //event.preventDefault();

        this.setState({
            impersonateCompany: '',
            showImpersonateModal: !this.state.showImpersonateModal
        });
    }

    loadOptions = (inputValue) => {
        if (inputValue.length > 0) {

            this.setState({
                showDropdown: true,
                searchResultsLoaded: false
            });

            let postdata = {};
            postdata.searchvalue = inputValue;

            Axios.post(`/api/GetCustomersForImpersonation`, postdata
            ).then(response => {

                let cListData = response.data;

                cListData = cListData.map(item => ({
                    label: item.name + " Id: " + item.id.toString() + ", " + item.shows,
                    value: item.name + " Id: " + item.id.toString() + ", " + item.shows,
                    id:item.id
                }));

                this.setState({
                    customersListData: cListData,
                    searchResultsLoaded: true,
                    showDropdown: true
                });

            }).catch(error => {
                console.log(error);
            });
        } else {
            this.setState({
                customersListData: []
            });
        }
    };

    onCompanySelect = (value, option) => {
        //console.log(value);
        //console.log(option.id);
        this.setState({
            impersonateCompany: value
        });

        if (typeof (option) != "undefined") {
            this.getCompanyContacts(option.id);

            this.setState({
                showDropdown: false,
                impersonateCompany: option.value,
                formFields: { ...this.state.formFields, company_id: option.id }
            });
        }
        else {
            this.setState({
                impersonateCompany: null,
                impersonateContact: 'Select Contact',
                contactsListData: [],
                showDropdown: false
            });
        }
    }

    getCompanyContacts = async (company_id) => {
        let postdata = {};
        postdata.company_id = company_id;

        await Axios.post(`/api/GetMSOCompanyContacts`, postdata
        ).then(response => {
            let contacts = response.data;

            if (contacts.length == 0) {
                this.setState({
                    impersonateCompany: ''
                });

                toast.error("No contacts configured with valid myshowonline.com accounts for selected company!");
                return;
            }
            else {
                this.setState({
                    contactsListData: contacts
                });
            }

        }).catch(error => {
            console.log(error);
        });
    }

    onCompanyChange = (value) => {
        this.setState({
            impersonateCompany: value,
            impersonateContact: 'Select Contact',
            contactsListData: []
        });
    }

    startTimer = (inputValue) => {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => this.loadOptions(inputValue), 1000);
    }

    onContactChange = (value, item) => {
        this.setState({
            impersonateContact: value
        });
    }

    getUserInfoForImpersonation = (email, pw) => {
        let commandresult = "";
        let myIndex = 0;

        let postdata = {};
        postdata.userId = email;
        postdata.password = pw;

        Axios.post(`/api/msoimpersonatetoken`, postdata
        ).then(response => {
            commandresult = response.data;

            //this loops trhough all locations and gets the index of the one that was actually selected to impersonate
            for (var key in commandresult.list) {
                if (commandresult.list[key].customer.id === this.state.formFields.company_id) {
                    myIndex = key;
                }
            }

            //Already have the authenticated Admin user saved in Globals.realUserInfo

            // set the impersonated user based off the index found above
            let impersonatingUserInfo = Globals.impersonatingUserInfo;
            impersonatingUserInfo.bearer = commandresult.token;
            impersonatingUserInfo.customerInfo = commandresult.list[myIndex].customer;
            impersonatingUserInfo.companyId = commandresult.list[myIndex].contact.company_id;
            impersonatingUserInfo.companyName = commandresult.list[myIndex].customer.name;
            impersonatingUserInfo.username = email;
            impersonatingUserInfo.userId = email;
            impersonatingUserInfo.userLocations = commandresult.list;
            impersonatingUserInfo.fullName = commandresult.list[myIndex].contact.jsS_name;

            //Now set the Globals.userInfo = the impersonatingUserInfo
            Globals.userInfo = impersonatingUserInfo;
            window.sessionStorage.setItem("userInfo", JSON.stringify(impersonatingUserInfo));

            Globals.impersonatingUserInfo = impersonatingUserInfo;
            window.sessionStorage.setItem("impersonatingUserInfo", JSON.stringify(impersonatingUserInfo));

            Globals.isImpersonating = true;
            window.sessionStorage.setItem("isImpersonating", true);

            Globals.updateLayout = true;

            this.setState({
                impersonateCompany: '',
                showImpersonateModal: false
            });

            this.reloadEnrolledShows(this.state.formFields.company_id);

            this.setState({ formFields: { ...this.state.formFields, company_id: Globals.impersonatingUserInfo.companyId } });

            reloadCompany.next(true);
            reloadLayout.next(true);
            
        }).catch(error => {
            console.log('here in catch');
        
        });
    }

    reloadEnrolledShows = (id) => {
        Axios.get(`/api/GetEnrolledShowsByCompanyId`, {
            params: {
                id: id
            }
        }).then(response => {
            let enrolledShows = Globals.enrolledShows;

            enrolledShows = response.data;

            Globals.enrolledShows = enrolledShows;
            window.sessionStorage.setItem("enrolledShows", JSON.stringify(enrolledShows));
            this.setState({
                enrolledShows
            });

            let path = '/exhibitor-home';
            this.props.history.replace(path);

            $('.nav li').removeClass("active-nav-item");
            $("#companyProfile").addClass("active-nav-item");

            reloadCompany.next(true);

        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="page-sidebar">
                <ul className="nav nav-tabs nav-clients nav-stacked flex-column" role="tablist">
                    <li className="text-center pt-5 pb-5">
                        <img src={ribbonLogo} width="75%"/>
                    </li>

                    {Globals.userInfo.isAdmin && Globals.adminMode == "ADMIN" && !Globals.isImpersonating &&
                        <div className="nav-title nav-title-lg cursor-pointer" onClick={this.handleImpersonateClick}><u>Impersonate User</u></div>
                    }
                    {Globals.isImpersonating &&
                        <div className="nav-title nav-title-lg cursor-pointer" onClick={this.handleStopImpersonatingClick}><u>Stop Impersonating</u></div>
                    }

                    {Globals.userInfo.userLocations.length > 1 &&
                        <div className="pt-5 p-3">
                            <label className="form-label fs-lg">Select a location</label>
                            <select className="form-control-custom" value={this.state.formFields.company_id} name="company_id" onChange={this.handleInputChange}>
                                {Globals.userInfo.userLocations.map((loc, idx) => <option key={idx} value={loc.contact.company_id}>{loc.customer.city}, {loc.customer.state}</option>)}
                            </select>

                            {/*AntD option*/}
                            {/*<Select className="form-control-custom"*/}
                            {/*    value={this.state.formFields.company_id}*/}
                            {/*    size="medium" placeholder="Select Location"*/}
                            {/*    showSearch={false}*/}
                            {/*    allowClear={false}*/}
                            {/*    optionFilterProp="children"*/}
                            {/*    onChange={this.handleLocationChange}*/}
                            {/*    filterOption={(input, option) =>*/}
                            {/*        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    {Globals.userInfo.userLocations?.length > 0 && Globals.userInfo.userLocations?.map((loc, idx) => <option index={idx} key={loc.contact.company_id} value={loc.contact.company_id}>{loc.customer.city}, {loc.customer.state}</option>)}*/}
                            {/*</Select>*/}
                        </div>
                    }
                    <li>&nbsp;</li>

                    <li className="nav-item" id="companyProfile" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="companyProfile" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="companyProfile" onClick={this.handleNavigationClick}>
                                Company Profile
                            </span>
                        </a>
                    </li>
                    <li className="nav-item" id="programListing" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="programListing" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="programListing" onClick={this.handleNavigationClick}>
                                Show Program Listing
                            </span>
                        </a>
                    </li>
                    <li className="nav-item" id="exhibitorScamWarning" onClick={this.handleNavigationClick}>
                        <a className="nav-link fs-lg px-4" id="exhibitorScamWarning" onClick={this.handleNavigationClick}>
                            <span className="hidden-sm-down ml-1" id="exhibitorScamWarning" onClick={this.handleNavigationClick}>
                                Exhibitor Email Scam Warning
                            </span>
                        </a>
                    </li>
                    <li>&nbsp;</li>
                    {this.state.exhibitorHasLeadsToManage &&
                        //<li className="nav-item" id="manageMyLeads" onClick={this.handleNavigationClick}>
                        //    <a className="nav-link fs-lg px-4" id="manageMyLeads" onClick={this.handleNavigationClick}>
                        //        <span className="hidden-sm-down ml-1" id="manageMyLeads" onClick={this.handleNavigationClick}>
                        //            Manage My Leads
                        //        </span>
                        //    </a>
                        //</li>
                        <div class="d-flex justify-content-center">

                            <button className="btn btn-warning btn-lg" onClick={this.handleMyShowLeadsClick}>My Show Leads</button>
                        </div>
                    }
                    {this.state.enrolledShows.length > 0 ?
                        <Fragment>
                            <li class="nav-title nav-title-lg">My Shows</li>

                            {this.state.enrolledShows.map((show, idx) => (
                                <li className="nav-item pl-5" id={"showDashboard" + idx} onClick={(e) => this.handleNavigationClick(e, idx)} name={show.showDetail.showcode}>
                                    <a className="nav-link fs-lg px-4" id="showDashboard" onClick={(e) => this.handleNavigationClick(e, idx)} name={show.showDetail.showcode}>
                                        <span className="hidden-sm-down ml-1" id="showDashboard" onClick={(e) => this.handleNavigationClick(e, idx)} name={show.showDetail.showcode} company_id={show.showDetail.Id}>
                                            {show.showMaster.name}
                                        </span>
                                    </a>
                                </li>
                            ))}
                        </Fragment>
                        :
                        <li class="nav-title nav-title-lg">No Active Shows for this Location</li>
                    }
                    
                    <div>
                        <span className="font-weight-bold header-icon mimicA top-nav-item font-size-large" onClick={this.handleLogout} title={'Logout, ' + Globals.userInfo.fullName}>
                            <span className="text-icon">LOGOUT</span>&nbsp;
                            <i className="fas fa-sign-out-alt logoutIcon text-icon"></i>
                        </span>
                    </div>
                </ul>

                <Modal size='lg' show={this.state.showContractModal} onHide={this.toggleContractModal} backdrop='static'>
                    <Modal.Header>
                        <h3>Exhibition Contract</h3>
                        <a className="alignRight display-5" id="closeContractModal" onClick={this.handleModalClick}><span style={{ color: 'red', border: '1px solid', padding: '2px' }}>X</span></a>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="panel-content">
                            <div className="pb-5">Please note, All customers must agree with our Exhibition Contract to be allowed to exhibit at any of our Design-2-Part Shows.</div>

                            {(() => {
                                switch (this.state.msgType) {
                                    case 'initial':
                                        return (<div>
                                            <div className="pb-5">
                                                On behalf of my company, I have reviewed and understand the Design-2-Part Exhibition Contract. I acknowledge that this is a legal document
                                                that governs the relationship of the parties. I am authorized on behalf of my company to sign such agreements. 'The Exhibitor
                                                hereby agrees to the Exhibition Contract in its entirety.'
                                                    </div>
                                        </div>);
                                        break;
                                    case 'secondchance':
                                        return (<div className="pb-5">
                                            To agree to our contract, click on 'Submit'.

                                            Or if you really wish to decline, select 'I REALLY WANT TO DECLINE' and click on 'Submit'.
                                        </div>);
                                        break;
                                    default:
                                        return 'yo';
                                }
                            })()}

                            <div className="pb-5">
                                <a href={process.env.PUBLIC_URL + '/downloads/ExhibitionContract.pdf'} target="new"><u>View Our Contract Here (PDF)</u></a>
                            </div>

                            <div className="form-control-custom no-border">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" name="agree" value='yes' className="custom-control-input" checked={this.state.agreesToContract === 'yes'} onChange={this.handleModalInputChange} />
                                    <span className="custom-control-label">I AGREE</span>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" name="agree" value='no' className="custom-control-input" checked={this.state.agreesToContract === 'no'} onChange={this.handleModalInputChange} />
                                    <span className="custom-control-label">{this.state.declineButtonText}</span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer class="text-left !important" style={{ padding: '20px' }}>
                        <div>
                            <button type="button" className="btn btn-danger" onClick={this.handleModalSubmit}>
                                Submit
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal size='lg' show={this.state.showImpersonateModal} onHide={this.toggleImpersonateModal} backdrop='static'>
                    <Modal.Header>
                        <h3>Impersonate User</h3>
                        <a className="alignRight display-5" id="closeImpersonateModal" onClick={this.toggleImpersonateModal}><span style={{ color: 'red', border: '1px solid', padding: '2px' }}>X</span></a>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="panel-content">
                            <div className="form-group col-lg-12">
                                <label className="custom-form-label">Select Company</label><br />
                                <fragment>
                                    <AutoComplete dropdownClassName="certain-category-search-dropdown"
                                        value={this.state.impersonateCompany}
                                        onSelect={this.onCompanySelect}
                                        onChange={this.onCompanyChange}
                                        dropdownMatchSelectWidth={500}
                                        style={{
                                            width: '100%',
                                        }}
                                        onSearch={this.startTimer}
                                        placeholder="Search Customers"
                                        options={this.state.customersListData}
                                        notFoundContent={this.state.searchResultsLoaded ? "No Results Found" : <div><Spin indicator={<LoadingOutlined spin />} /> Searching...</div>}
                                        open={this.state.showDropdown}
                                        onFocus={() => this.setState({ showDropdown: false })}
                                        onBlur={() => this.setState({ showDropdown: false })}
                                        allowClear={true}
                                    />
                                </fragment>
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="custom-form-label">Select Contact</label><br />
                                <Select
                                    size="large" placeholder="Select Contact"
                                    style={{ width: 400 }}
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onContractorSearch}
                                    onChange={this.onContactChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={this.state.impersonateContact}
                                    disabled={this.state.contactsListData?.length > 0 ? false : true}
                                >
                                    <option value='Select Contact' />
                                    {this.state.contactsListData?.length > 0 && this.state.contactsListData?.map((contact, idx) => <option index={idx} key={contact.contact_id} value={contact.contact_id}>{contact.jsS_name} ({contact.contact_type})</option>)}
                                </Select><br />
                                {/*{getValues().formFields?.contact != "Select Contact" ?*/}
                                {/*    <div>*/}
                                {/*        Phone: {getValues().formFields?.contactphone}&nbsp;&nbsp;&nbsp;Fax: {getValues().formFields?.contactfax}*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    <div></div>*/}
                                {/*}*/}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer class="text-left !important" style={{ padding: '20px' }}>
                        <div>
                            <button type="button" className="btn btn-danger" onClick={this.handleModalImpersonateSubmit}>
                                Impersonate Selected User
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default withRouter(LeftNav);