import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { Tabs } from 'antd';
import ActivateDeviceTab from './ActivateDeviceTab';
import ManageExhibitorLeadsTab from './ManageExhibitorLeadsTab';
import ReportingServicesTab from './ReportingServicesTab';
import ProcessOnsiteOrderTab from './ProcessOnsiteOrderTab';
import SendActivationCodeEmailsTab from './SendActivationCodeEmailsTab';
import SampleQRCodesTab from './SampleQRCodesTab';

import Globals from '../../config/globals';

function ShowLeadsAdmin(props) {
    const [state, setState] = useState({
        showsList: [],
        selectedShow: "SELECT A SHOW",
        showView: "TwoYear",
        selectedShowName: '',
        lrExhibitorsFound: false
    });

    useEffect(() => {
        if (state.showView == "TwoYear") {
            GetTwoYearShowsListForLeadRetrieval();
        }
        else {
            GetShowsForLeadRetrieval();
        }
    }, []);

    useEffect(() => {
        if (state.selectedShow != "SELECT A SHOW") {
            Axios.get(`/api/GetLeadRetrievalExhibitorsForShow`, {
                params: {
                    showcode: state.selectedShow
                }
            }).then(response => {
                let exhibitorsList = response.data;

                if (exhibitorsList.length > 0) {
                    setState({
                        ...state,
                        lrExhibitorsFound: true
                    });
                }
                else {
                    setState({
                        ...state,
                        lrExhibitorsFound: false
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }, [state.selectedShow]);

    function GetShowsForLeadRetrieval() {
        Axios.get(`/api/GetShowsForLeadRetrieval`
        ).then(response => {
            let showsList = response.data;

            setState({
                ...state,
                showsList,
                showView: "Expanded"
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function GetTwoYearShowsListForLeadRetrieval() {
        Axios.get(`/api/GetTwoYearShowsListForLeadRetrieval`
        ).then(response => {
            let showsList = response.data;

            setState({
                ...state,
                showsList,
                showView: "TwoYear"
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var i;
        let mySelectedShowName = '';

        //state.showsList.forEach(show => {})

        for (i = 0; i < state.showsList.length; i++) {
            if (state.showsList[i].showcode == value) {
                mySelectedShowName = state.showsList[i].name;
            }
        }

        if (name === 'selectedShow') {
            setState({
                ...state,
                selectedShow: value,
                selectedShowName: mySelectedShowName
            });
        }
    }

    function toggleShowListViewChange(event) {
        var myShowView;

        if (state.showView == "TwoYear") {
            GetShowsForLeadRetrieval();
            myShowView = "Full"
        }
        else {
            GetTwoYearShowsListForLeadRetrieval();
            myShowView = "TwoYear";
        }

        setState({
            ...state,
            showView: myShowView
        });
    }

    const { TabPane } = Tabs;

    return (
        <div className="code-box-demo">
            <div className="row">
                <div className="form-group col-lg-4">
                    <label className="form-label">Choose A Show To Work With</label>
                    <select className="form-control-custom" value={state.selectedShow} name="selectedShow" onChange={handleInputChange}>
                        <option>SELECT A SHOW</option>
                        {state.showsList.map((show, idx) => <option key={idx} value={show.showcode} onChange={handleInputChange}>{show.name}</option>)}
                    </select>
                </div>
                <div className="form-group col-lg-8 a" style={{ paddingTop: 35 }} >
                    <span className="form-group col-lg-2 a" onClick={toggleShowListViewChange}><u>{state.showView == "TwoYear" ? "Display Expanded Show List" : "Display Two Year Show List"}</u></span>
                </div>
            </div>

            {state.selectedShow == "SELECT A SHOW" ?
                <div className="form-group col-lg-12">
                    <h3>YOU MUST SELECT A SHOW TO WORK WITH</h3>
                </div>
                :
                <div>
                    {state.lrExhibitorsFound == true ?
                        <div className="card-container">
                            <Tabs defaultActiveKey='tab-activate-a-device' tabPosition='top' type='card'>
                                <TabPane tab='Activate A Device' key='tab-activate-a-device'>
                                    <ActivateDeviceTab selectedShow={state.selectedShow} selectedShowName={state.selectedShowName} />
                                </TabPane>

                                <TabPane tab='Manage Exhibitor Leads' key='tab-manage-exhibitor-leads'>
                                    <ManageExhibitorLeadsTab selectedShow={state.selectedShow} selectedShowName={state.selectedShowName} />
                                </TabPane>

                                <TabPane tab='Reporting Tools' key='tab-reporting-tools'>
                                    <ReportingServicesTab selectedShow={state.selectedShow} selectedShowName={state.selectedShowName} />
                                </TabPane>

                                {/*<TabPane tab='Process On Site Order' key='tab-process-onsite-order'>*/}
                                {/*    <ProcessOnsiteOrderTab selectedShow={state.selectedShow} selectedShowName={state.selectedShowName} />*/}
                                {/*</TabPane>*/}

                                <TabPane tab='Send Activation Code Emails' key='tab-send-activation-code-emails'>
                                    <SendActivationCodeEmailsTab selectedShow={state.selectedShow} selectedShowName={state.selectedShowName} />
                                </TabPane>

                                <TabPane tab='Sample QR Codes' key='tab-dample-qrcodes'>
                                    <SampleQRCodesTab selectedShow={state.selectedShow} selectedShowName={state.selectedShowName} />
                                </TabPane>
                            </Tabs>
                        </div>
                        :
                        <div>
                            <h3>NO LEAD RETRIEVAL EXHIBITORS FOUND FOR SELECTED SHOW</h3>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default withRouter(ShowLeadsAdmin);