import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import Globals from '../../config/globals';
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { message } from 'antd';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';

function ManageMyLeads(props) {
    const { SearchBar } = Search;

    const [state, setState] = useState({
        showsList: [],
        selectedShow: "SELECT A SHOW",
        selectedShowName: '',
        leadsList: [],
        dataLoaded: false,
        selectedExportType: 'Select Type'
    });

    const [selectedExhibitorID, setSelectedExhibitorID] = useState('');

    useEffect(() => {
        setSelectedExhibitorID(props.userInfo.companyId);

        GetExhibitorShowsForLeadRetrieval(props.userInfo.companyId);
    }, [props]);

    function GetExhibitorShowsForLeadRetrieval(myCompanyId) {
        Axios.get(`/api/GetExhibitorShowsForLeadRetrieval`, {
            params: {
                ExhibitorID: myCompanyId,
            }
        }).then(response => {
            let showsList = response.data;

            setState({
                ...state,
                showsList
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        var i;
        let mySelectedShowName = '';

        for (i = 0; i < state.showsList.length; i++) {
            if (state.showsList[i].showcode == value) {
                mySelectedShowName = state.showsList[i].name;
            }
        }

        if (name === 'selectedShow') {
            Axios.get(`/api/GetD2PLeadsForExhibitorShow`, {
                params: {
                    ExhibitorID: selectedExhibitorID,
                    showcode: value,
                    whichday: 'all'
                }
            }).then(response => {
                let leadsList = response.data;

                console.log(leadsList[0].originalScan);
                var mydate = new Date(Date.parse(leadsList[0].originalScan)).toLocaleString();
                console.log(mydate);

                setState({
                    ...state,
                    selectedShow: value,
                    selectedShowName: mySelectedShowName,
                    leadsList: leadsList,
                    dataLoaded: true
                });
            }).catch(error => {
                console.log(error);
            });
        }
        if (name === 'selectedExportType') {
            setState({
                ...state,
                selectedExportType: value
            });
        }
    }

    function handleExportLeads(event) {
        var myLeadsDay;
        var myFileName;

        if (state.selectedExportType == 'Select Type') {
            toast.error('Type of Export must be selected');

            return;
        }

        if (state.selectedExportType.includes("Both Days")) {
            myLeadsDay = "all";
            myFileName = "D2PLeads_" + state.selectedShowName.replace(' ', '_') + "_both_days";
        }
        else if (state.selectedExportType.includes("Day 1")) {
            myLeadsDay = "day1";
            myFileName = "D2PLeads_" + state.selectedShowName.replace(' ', '_') + "_day1";
        }
        else if (state.selectedExportType.includes("Day 2")) {
            myLeadsDay = "day2";
            myFileName = "D2PLeads_" + state.selectedShowName.replace(' ', '_') + "_day2";
        }

        let postdata = {
            exhibitorid: selectedExhibitorID,
            showcode: parseInt(state.selectedShow),
            showName: state.selectedShowName,
            whichday: myLeadsDay
        }

        const key = "downloadleads";
        message.loading({
            content: 'Downloading Your Leads...',
            key,
            duration: 0,
            //style: {
            //    position: "fixed",
            //    bottom: 0,
            //    left: 0
            //},
        });

        if (state.selectedExportType.includes("Excel")) {
            Axios.post(`/api/DownloadCollectedLeads_XLSX`, postdata, {
                responseType: 'blob'
            }).then(response => {
                message.destroy(key);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = myFileName + ".xlsx";
                a.click();
                window.URL.revokeObjectURL(url);
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            Axios.post(`/api/DownloadCollectedLeads_CSV`, postdata, {
                responseType: 'blob'
            }).then(response => {
                message.destroy(key);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = myFileName + ".csv";
                a.click();
                window.URL.revokeObjectURL(url);
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.originalScan)).format("LLL");
        return (
            <div>{mydate}</div>
        );
    }

    //function dateFormatter(cell, row) {
    //    var mydate = new Date(Date.parse(row.originalScan)).toLocaleString();

    //    return (
    //        <text>{mydate}</text>
    //    );
    //}

    function nameFormatter(cell, row) {
        return (
            <span className="color-blue"><text>{row.lName}, {row.fName}</text></span>
        );
    }

    const columns = [
        {
            dataField: 'leads_ID',
            text: 'Leads_ID',
            hidden: true
        }, {
            dataField: 'lName',
            text: 'Attendee Name',
            formatter: nameFormatter,
            sort: true,
            sortValue: (cell, row) => (row.lName + ', ' + row.fName),
        }, {
            dataField: 'company',
            text: 'Company Name',
            sort: true
        }, {
            dataField: 'title',
            text: 'Title'
        }, {
            dataField: 'originalScan',
            text: 'Scanned',
            formatter: dateFormatter,
            sort: true
        }];

    const expandRow = {
        renderer: row => (
            <div>
                <div>Address: <strong>{row.address}, {row.city}, {row.state} {row.zip}</strong></div>
                <div>&nbsp;</div>
                <div>Email: <strong><a href={`mailto:${row.email}`}>{row.email}</a></strong></div>
                <div>&nbsp;</div>
                <div>Phone: <strong>{row.phone}</strong></div>
                <div>&nbsp;</div>
                <div>Purchasing Role: <strong>{row.purchasingRole}</strong></div>
                <div>Timeframe To Purchase Parts or Services: <strong>{row.timeframe}</strong></div>
                <div>Industry: <strong>{row.industryCode}</strong></div>
                <div>&nbsp;</div>
                <div>Notes: <strong>{row.leadNotes}</strong></div>
            </div>
        ),
        showExpandColumn: true
    };

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="form-control-custom hover"
            tabIndex="-1"
            data-page={page}
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
            style={{ color: 'pink' }}
        >
            <span className="a hover"
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'rgba(243, 0, 121, 1)' }}
            >
                {text}
            </span>
        </li>
    );

    const pagination = {
        sizePerPage: 10,
        sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
        sizePerPageOptionRenderer
    };

    return (
        <div className="code-box-demo">
            <h1>My Show Leads</h1>
            <div className="row">
                <div className="form-group col-lg-4">
                    <label className="form-label">Choose A Show To Work With</label>
                    <select className="form-control-custom" value={state.selectedShow} name="selectedShow" onChange={handleInputChange}>
                        <option>SELECT A SHOW</option>
                        {state.showsList.map((show, idx) => <option key={idx} value={show.showcode} onChange={handleInputChange}>{show.name}</option>)}
                    </select>
                </div>
            </div>

            <div className="row">
                {state.selectedShow == "SELECT A SHOW" ?
                    <div className="form-group col-lg-12">
                        <p>&nbsp;</p>
                        <h3>YOU MUST SELECT A SHOW TO WORK WITH</h3>
                    </div>
                    :
                    <div>
                        <p>&nbsp;</p>
                        <div className="row">
                            <div className="form-group col-lg-6">
                                <h4>{state.leadsList.length} Leads Collected For {state.selectedShowName}</h4>
                            </div>
                            <div className="form-group col-lg-6">
                                <div class="d-flex flex-row">
                                    <div class="p-2">Export Your Leads:</div>
                                    <div class="p-2">
                                        <select className="form-control-custom" value={state.selectedExportType} name="selectedExportType" onChange={handleInputChange}>
                                            <option>Select Type</option>
                                            <option>Both Days in Excel Format</option>
                                            <option>Both Days in CSV Format</option>
                                            <option>Day 1 in Excel Format</option>
                                            <option>Day 1 in CSV Format</option>
                                            <option>Day 2 in Excel Format</option>
                                            <option>Day 2 in CSV Format</option>
                                        </select>
                                    </div>
                                    <div class="p-2">
                                        <button className="btn btn-primary btn-sm" onClick={handleExportLeads}>Export Leads</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <ToolkitProvider
                                keyField='leads_ID'
                                data={state.leadsList}
                                columns={columns}
                                columnToggle
                                search
                            >
                                {
                                    props => (
                                        <div>
                                            <SearchBar {...props.searchProps} />
                                            <BootstrapTable
                                                pagination={paginationFactory(pagination)}
                                                expandRow={expandRow}
                                                {...props.baseProps}
                                                hover condensed />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                        </div>
                    </div>
                }
            </div>
            <div className="row"></div>
        </div>
    );
}

export default withRouter(ManageMyLeads);