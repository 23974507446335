import React from 'react';

class Globals extends React.Component {

    static userInfo = {
        companyId: "",
        userId: "",
        companyName: "",
        userName: "",
        bearer: "",
        customerInfo: {},
        fullName: "",
        userLocations: []
    };

    static pathname = '/';

    static defaultShowCode = null;

    static isDirtyPage = false;
    static updateLayout = false;
    static enrolledShows = [];
    static loadingMembers = false;
    static adminMode = "";
    static isImpersonating = false;

    static realUserInfo = {
        companyId: "",
        userId: "",
        companyName: "",
        userName: "",
        bearer: "",
        customerInfo: {},
        fullName: "",
        userLocations: []
    };

    static impersonatingUserInfo = {
        companyId: "",
        userId: "",
        companyName: "",
        userName: "",
        bearer: "",
        customerInfo: {},
        fullName: "",
        userLocations: []
    };

}

export default Globals;
