import React, { useEffect, useState, useRef } from "react";
import { useTable } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import { Modal } from 'antd';

//import { combineLatest } from 'rxjs';
//import { take, takeWhile } from 'rxjs/operators';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import Card from 'react-bootstrap/Card';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { smallBox } from "../../common/utils/functions";
import Globals from '../../config/globals';

const Table = ({ columns, data, openModal }) => {
    const [records, setRecords] = React.useState(data);

    const getRowId = React.useCallback((row) => {
        return row.showcode;
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
        } = useTable({
            data: records,
            columns,
            getRowId,
            initialState: {
                hiddenColumns: ['halladdress', 'directionslink', 'showhome_day1times', 'showhome_day2times', 'showinfo_setuphours', 'showinfo_day1hours', 'showinfo_day2hours', 'hallimage', 'active', 'displayorder', 'hallimagefile']
            }
        });

    useEffect(() => {
        setRecords(data);
    }, [data])

    function UpdateD2PShowsOrder(list) {
        return Axios.post('/api/UpdateD2PShowsOrder', list).then(response => response.data);
    }

    function updateOrder() {
        let updateList = [];
        
        rows.forEach((item, idx) => {
            var i = item.values;
            i.displayorder = idx;
            updateList.push(i);
        });

        UpdateD2PShowsOrder(updateList).then(data => {
            if (data) {
                setRecords(updateList);
                toast.success("Show schedule order has been updated");

                //smallBox({
                //    title: "Notice",
                //    content: "<i class='fa fa-clock-o'></i> <i>Show schedule order has been updated</i>",
                //    color: "#659265",
                //    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                //    timeout: 4000
                //});
            }
        });
    }

    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = records[dragIndex];

        //console.log(dragIndex);
        //console.log(hoverIndex);

        setRecords(
            update(records, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord]
                ]
            })
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <table {...getTableProps()} className="w-100">
                <thead className="ant-table-thead">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            <th></th>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="ant-table-tbody">
                    {rows.map(
                        (row, index) =>
                            prepareRow(row) || (
                                <Row
                                    index={index}
                                    row={row}
                                    moveRow={moveRow}
                                    update={updateOrder}
                                    openModal={openModal}
                                    {...row.getRowProps()}
                                />
                            )
                    )}
                </tbody>
            </table>
        </DndProvider>
    );
};

const DND_ITEM_TYPE = "row";


const Row = ({ row, index, moveRow, update, openModal }) => {

    const [modal] = Modal.useModal();


    const dropRef = React.useRef(null);
    const dragRef = React.useRef(null);

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {

            if (!dropRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        drop(props, monitor, component) {
            update();
        }
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: { type: DND_ITEM_TYPE, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0 : 1;

    preview(drop(dropRef));
    drag(dragRef);

    return (
        <tr ref={dropRef} style={{ opacity }} onClick={(e) => { e.target.id !== 'delete' && openModal(row.values) }} className="hover">
            <td ref={dragRef}><i className="fas fa-arrows-alt-v"></i></td>
            {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
            })}
        </tr>
    );
};

export default function LRAdmin(props) {
    const { register, watch, getValues, setValue, control } = useForm();

    const [state, setState] = useState({
        showsList: [],
        targetKeys: [],
        formFields: {},
        hallimagefile: null,
        displayhallimagethumb: false,
        selectedShowStatus: 'active'
    });

    const [modalInfo, setModalInfo] = useState({
        showmodal: false,
        content: {}
    });

    useEffect(() => {

    }, []);

    var stringToHTML = function (str) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(str, 'text/html');
        return doc.body;
    };

    function handleUpdateAppController() {
        let message = "";

        if (state.formFields.dbVersion === '') {
            message += 'DB Version is a required field</br>';
        }
        if (state.formFields.contentVersion === '') {
            message += 'Content Version is a required field</br>';
        }
        if (state.formFields.showScheduleSunsetDate === '' || state.formFields.showScheduleSunsetDate === null) {
            message += 'Invalid Show Schedule Sunset Date specified</br>';
        }

        if (message != '') {
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return;
        }
        else {
            let postdata = {};

            postdata.dbVersion = getValues().formFields.dbVersion;
            postdata.contentVersion = getValues().formFields.contentVersion
            postdata.showScheduleSunsetDate = getValues().formFields.showScheduleSunsetDate;

            console.log(postdata);

            Axios.post(`/api/UpdateD2PAppController`, postdata
            ).then(response => {
                if (response.data) {
                    toast.success("D2P App Controller Info has been updated");
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    useEffect(() => {
        var alive = true;

        Axios.get(`/api/GetD2PAppInformation`
        ).then(response => {
            var showsList = response.data.shows;
            var controlInfo = response.data.controlInfo;

            setValue('formFields', controlInfo);

            setState({
                ...state,
                formFields: controlInfo,
                showsList: showsList
            });

            //var EventDate = "2018-10-10T00:00:00Z"

            //let date1 = Moment(this.state.formFields.showScheduleSunsetDate).format("MM/DD/YYYY");
            //console.log(date1)

            //let date2 = Moment(this.state.formFields.showScheduleSunsetDate).utc().format("MM/DD/YYYY");
            //console.log(date2)

        }).catch(error => {
            console.log(error);
        });

        //Axios.get(`/api/GetD2PAppControllerInfo`
        //).then(response => {
        //    var controlInfo = response.data;

        //    setValue('formFields', controlInfo);

        //    setState({
        //        ...state,
        //        formFields: controlInfo,
        //    });

        //    Axios.get(`/api/GetD2PAppShows`, {
        //        params: {
        //            showStatus: state.selectedShowStatus
        //        }
        //    }).then(response => {
        //        var showsList = response.data;

        //        setState({
        //            ...state,
        //            showsList: showsList
        //        });
        //    }).catch(error => {
        //        console.log(error);
        //    });
        //}).catch(error => {
        //    console.log(error);
        //});

        return () => { alive = false };
    }, []);

    function openModal(rowData) {
        if (fileRef.current) { fileRef.current.value = ""; }

        if (rowData) {
            setValue('showcode', rowData.showcode);
            setValue('showlocation', rowData.showlocation);
            setValue('hallname', rowData.hallname);
            setValue('halladdress', rowData.halladdress);
            setValue('directionslink', rowData.directionslink);
            setValue('showhome_showdates', rowData.showhome_showdates);
            setValue('showhome_day1times', rowData.showhome_day1times);
            setValue('showhome_day2times', rowData.showhome_day2times);
            setValue('showinfo_setuphours', rowData.showinfo_setuphours);
            setValue('showinfo_day1hours', rowData.showinfo_day1hours);
            setValue('showinfo_day2hours', rowData.showinfo_day2hours);
            setValue('hallimage', rowData.hallimage);
            setValue('active', rowData.active);
            setValue('displayorder', rowData.displayorder);

            var mydisplayhallimagethumb = false;
            if (rowData.hallimagefile != null) { mydisplayhallimagethumb = true; }

            setState({
                ...state,
                hallimagefile: rowData.hallimagefile,
                displayhallimagethumb: mydisplayhallimagethumb
            });

            setValue('addingnewshow', false);
        }
        else {
            setState({
                ...state,
                hallimagefile: null,
                displayhallimagethumb: false
            });

            setValue('displayorder', state.showsList.length);
            setValue('addingnewshow', true);
        }

        setModalInfo({
            ...modalInfo,
            showmodal: true,
            content: rowData
        });
    }

    function closeModal() {

        setValue('showcode', '');
        setValue('showlocation', '');
        setValue('hallname', '');
        setValue('halladdress', '');
        setValue('directionslink', '');
        setValue('showhome_showdates', '');
        setValue('showhome_day1times', '');
        setValue('showhome_day2times', '');
        setValue('showinfo_setuphours', '');
        setValue('showinfo_day1hours', '');
        setValue('showinfo_day2hours', '');
        setValue('hallimage', '');
        setValue('active', false);
        setValue('displayorder', 0);

        setModalInfo({
            ...modalInfo,
            showmodal: false,
            content: {}
        });
    }

    function updateShow() {
        let show = {};

        if (!/\D/.test(getValues().showcode) === false || getValues().showcode == '') {
            toast.error("D2P App show not added - invalid show code specified");
            return;
        }

        show.showcode = parseInt(getValues().showcode);
        show.showlocation = getValues().showlocation;
        show.hallname = getValues().hallname;
        show.halladdress = getValues().halladdress;
        show.directionslink = getValues().directionslink;
        show.showhome_showdates = getValues().showhome_showdates;
        show.showhome_day1times = getValues().showhome_day1times;
        show.showhome_day2times = getValues().showhome_day2times;
        show.showinfo_setuphours = getValues().showinfo_setuphours;
        show.showinfo_day1hours = getValues().showinfo_day1hours;
        show.showinfo_day2hours = getValues().showinfo_day2hours;
        show.hallimage = getValues().hallimage;
        show.active = getValues().active;
        show.displayorder = getValues().displayorder;

        //let formdata = new FormData();
        //if (typeof getValues().hallimagefile == 'string') {
        //    //this indicates the hall image was previously selecetd and not changed
        //    show.hallimagefile = getValues().hallimagefile;
        //}
        //else if (getValues().hallimagefile != null) {
        //    //this indicates a new hall image was selected
        //    formdata.append('fd_hallimagefile', getValues().hallimagefile[0]);
        //}
        //else {
        //    //no hall image was selected
        //}

        let formdata = new FormData();
        if (typeof state.hallimagefile == 'string') {
            //this indicates the hall image was previously selecetd and not changed
            show.hallimagefile = state.hallimagefile;
        }
        else if (state.hallimagefile != null) {
            //this indicates a new hall image was selected
            formdata.append('fd_hallimagefile', state.hallimagefile);
        }
        else {
            //no hall image was selected
        }

        //these are all the other show fields
        formdata.append('fd_show', JSON.stringify(show));

        if (getValues().addingnewshow) {
            //First, check to see if the entered show code already exists
            Axios.get(`/api/GetD2PAppShowByShowCode`, {
                params: {
                    showcode: show.showcode
                }
            }).then(response => {
                if (response.data) {
                    toast.error("D2P App show not added - duplicate show code found");

                    return;
                }
            }).catch(error => {
                console.log(error);
            });

            //Good to go - insert new show
            Axios.post(`/api/InsertD2PShow`, formdata
            ).then(response => {
                if (response.data) {
                    toast.success("Show has been added");

                    setState({
                        ...state,
                        showsList: response.data
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            Axios.post(`/api/UpdateD2PShow`, formdata
            ).then(response => {
                if (response.data) {
                    toast.success("Show has been updated");

                    setState({
                        ...state,
                        showsList: response.data
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        }
        closeModal();
    }

    function handleDelete(e, show) {
        e.preventDefault();

        confirm({
            title: "You are about to delete this D2P App show",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteD2PShow`, show
                ).then(response => {
                    if (response.data) {
                        toast.success("Show has been deleted");

                        setState({
                            ...state,
                            showsList: response.data
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleUploadNewHallImage() {
        setState({
            ...state,
            hallimagefile: null,
            displayhallimagethumb: false
        });
    }

    function handleUpload(event) {
        //console.log(event.target.files[0]);

        setState({
            ...state,
            hallimagefile: event.target.files[0]
        });
    }

    const columns = [
        {
            Header: "Show Code",
            accessor: "showcode",
            key: "showcode"
        },
        {
            Header: "Show Location",
            accessor: "showlocation"
        },
        {
            Header: "Hall Name",
            accessor: "hallname"
        },
        {
            Header: "Show Dates",
            accessor: "showhome_showdates"
        },
        {
            Header: "Active",
            accessor: "dummy1",
            Cell: props => props.row?.values?.active && <i id="delete" className="fa fa-check ml-3 hover" onClick={(e) => handleDelete(e, props.row.values)} />

        },
        {
            Header: "Delete",
            accessor: "dummy",
            Cell: props =>
                <i id="delete" className="far fa-trash-alt ml-3 hover text-danger" onClick={(e) => handleDelete(e, props.row.values)} />
            
        },
        {
            Header: "Hall Address",
            accessor: "halladdress"
        },
        {
            Header: "Directions Link",
            accessor: "directionslink"
        },
        {
            Header: "Day 1 Times",
            accessor: "showhome_day1times"
        },
        {
            Header: "Day 2 Times",
            accessor: "showhome_day2times"
        },
        {
            Header: "Setup Hours",
            accessor: "showinfo_setuphours"
        },
        {
            Header: "Day 1 Hours",
            accessor: "showinfo_day1hours"
        },
        {
            Header: "Day 2 Hours",
            accessor: "showinfo_day2hours"
        },
        {
            Header: "Hall Image",
            accessor: "hallimage"
        },
        {
            Header: "Active",
            accessor: "active"
        },
        {
            Header: "Display Order",
            accessor: "displayorder"
        },
        {
            Header: "Hall Image",
            accessor: "hallimagefile"
        }
    ];

    function cloneShow() {
        setValue('addingnewshow', true);
        setValue('showcode', '');

        setModalInfo({
            ...modalInfo
        });

        toast.success("Show successfully cloned. You are now working with the new show.");
    }

    //function handleDisplayShowsStatusChange(e) {
    //    console.log(e.target.value);

    //    setState({
    //        ...state,
    //        selectedShowStatus: e.target.value
    //    });

    //    Axios.get(`/api/GetD2PAppShows`, {
    //        params: {
    //            showStatus: e.target.value
    //        }
    //    }).then(response => {
    //        var showsList = response.data;

    //        setState({
    //            ...state,
    //            showsList: showsList
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    const data = React.useMemo(() => state.showsList, [state.showsList]);
    const fileRef = useRef();

    return (
        <>
            <Card className="pinkCard">
                <Card.Header><span>App Configuration</span></Card.Header>
                <Card.Body>
                    <form>
                        <div className="row">
                            <div className="form-group col-lg-4">
                                <label className="form-label">DB Version</label>
                                <input type="text" {...register("formFields.dbVersion")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Content Version</label>
                                <input type="text" {...register("formFields.contentVersion")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label not-required">Show Schedule Sunset Date</label>

                                <Controller
                                    name="formFields.showScheduleSunsetDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.showScheduleSunsetDate ? Moment(getValues().formFields.showScheduleSunsetDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                        selected={getValues().formFields?.showScheduleSunsetDate ? Moment(getValues().formFields.showScheduleSunsetDate).toDate() : null}
                                        onChange={date => setValue('formFields.showScheduleSunsetDate', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        } />
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-4">
                                <button type="button" className="btn btn-danger font-weight-bold font-size-large" onClick={handleUpdateAppController}>Update</button>
                            </div>
                        </div>
                    </form>
                </Card.Body>
            </Card>

            <Card className="pinkCard">
                <Card.Header><span>D2P App Tradeshows</span></Card.Header>
                <Card.Body>
                    <div className="row">
                        <div align="left" className="form-group col-lg-8">
                            <strong>Shows Schedule will be displayed in the order listed below</strong>
                        </div>
                        {/*<div onChange={handleDisplayShowsStatusChange} className="form-group col-lg-4">*/}
                        {/*    <label className="form-label">Shows Status:</label>&nbsp;&nbsp;*/}
                        {/*    <input type="radio" name="showStatus" value="active" className="mt-1" checked={state.selectedShowStatus === "active"} />&nbsp;<label className="form-label">Active</label>&nbsp;&nbsp;&nbsp;*/}
                        {/*    <input type="radio" name="showStatus" value="inactive" className="mt-1" checked={state.selectedShowStatus === "inactive"} />&nbsp;<label className="form-label">Inactive</label>&nbsp;&nbsp;&nbsp;*/}
                        {/*    <input type="radio" name="showStatus" value="all" className="mt-1" checked={state.selectedShowStatus === "all"} />&nbsp;<label className="form-label">All</label>*/}
                        {/*</div>*/}
                        <div align="right" className="form-group col-lg-4">
                            <button className="btn btn-submit" onClick={() => openModal(null)}><i className="far fa-plus-square"></i> Add New Show</button>
                                
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-lg-12">
                            <Table columns={columns} data={data} openModal={openModal} rowKey="showcode" />
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Modal
                visible={modalInfo.showmodal}
                title={getValues().addingnewshow ? "New D2P App Show" : "Update D2P App Show"}
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={updateShow}>
                        {getValues().addingnewshow ? "Add Show" : "Update Show"}
                    </button>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Cancel
                    </button>,
                    <button className="btn btn-submit pull-right" onClick={cloneShow} style={!getValues().addingnewshow ? {} : { display: 'none' }}>
                        Clone Show
                    </button>
                ]}
            >
                <div className="row">
                    <div className="form-group col-lg-4">
                        <label className="form-label">Show Code</label>
                        <input {...register("showcode")} readOnly={!getValues().addingnewshow} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-8">
                        <label className="form-label">Show Location</label>
                        <input {...register("showlocation")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Hall Name</label>
                        <input {...register("hallname")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Hall Image</label><br />
                        {state.displayhallimagethumb === true ?
                            <>
                                <img style={{ maxHeight: "70px", width: "125px" }} src={`data:image/jpeg;base64, ${state.hallimagefile}`} /><br />
                                <span className="a" onClick={handleUploadNewHallImage}><u>Upload New Hall Image</u></span>             
                            </>
                         :
                            <>
                                <input type="file" ref={fileRef} onChange={handleUpload} className="form-label" />
                            </>
                        }
                    </div>
                    <div className="form-group col-lg-12">
                        <label className="form-label">Hall Address</label>
                        <input {...register("halladdress")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-12">
                        <label className="form-label">Directions Link</label>
                        <input {...register("directionslink")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Show Dates</label>
                        <input {...register("showhome_showdates")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Setup Hours</label>
                        <input {...register("showinfo_setuphours")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Show Home Day 1 Times</label>
                        <input {...register("showhome_day1times")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Show Home Day 2 Times</label>
                        <input {...register("showhome_day2times")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Show Info Day 1 Hours</label>
                        <input {...register("showinfo_day1hours")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-6">
                        <label className="form-label">Show Info Day 2 Hours</label>
                        <input {...register("showinfo_day2hours")} type="text" className="form-control-custom" />
                    </div>
                    <div className="form-group col-lg-12">
                        <label className="form-label">Active</label>
                        <div className="form-control-custom no-border">
                            <div className="custom-control custom-checkbox custom-control-inline">
                                <input {...register("active")} type="checkbox" className="custom-control-input" />
                                <label className="custom-control-label"></label>
                            </div>
                        </div>
                    </div> 
                </div>
            </Modal> 
        </>
    );
}