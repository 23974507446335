import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, AutoComplete, Skeleton } from 'antd';
import Axios from '../../config/axios';

function ExhibitorSearch(props) {
    const [state, setState] = useState({
        selectedShow: props.selectedShow,
        exhibitorsList: [],
        results: [],
        searchValue: '',
        selectedExhibitorId: '',
        selectedExhibitorName: ''
    });

    useEffect(() => {
        GetYesExhibitorsForShow();
    }, []);

    useEffect(() => {
        GetYesExhibitorsForShow();
    }, [state.selectedShow]);

    useEffect(() => {
        setState({
            ...state,
            selectedShow: props.selectedShow,
            searchValue: '',
            selectedExhibitorId: ''
        });

    }, [props.selectedShow]);

    useEffect(() => {
        //console.log("here in useEffect for props.resetFields: ", props.resetFields);

        if (props.resetFields === true) {
            setState({
                ...state,
                searchValue: '',
                selectedExhibitorId: ''
            });
        }
    }, [props.resetFields]);

    function GetYesExhibitorsForShow() {
        //console.log("selected show (grandchild): ", state.selectedShow);
        Axios.get(`/api/GetYesExhibitorsForShow`, {
            params: {
                showcode: state.selectedShow
            }
        }).then(response => {
            let exhibitorsList = response.data;

            setState({
                ...state,
                exhibitorsList: exhibitorsList,
                results: exhibitorsList.slice(0, 10)
            });
        }).catch(error => {
            console.log(error);
        });
    }

    //function filterSearch(inputValue, option) {
    //    return option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    //}

    function handleSearch(value) {
        let results = state.exhibitorsList.filter(c => c.name.toUpperCase().includes(value.toUpperCase()));

        setState({
            ...state,
            results,
            searchValue: value
        });
    }

    function handleSelect(option, value) {
        props.handleSearchReturn(option, value.label.props.children)

        setState({
            ...state,
            searchValue: value.label.props.children
        });

        //console.log("value: ", value);
        //console.log(value.label.props.children);
    }

    var results = state.results;

    const renderTitle = (title) => (
        <span>
            {title}
        </span>
    );

    const renderItem = (item) => ({
        value: item.id.toString(),
        label: (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {item.name}
            </div>
        ),
    });

    let list = [];
    results.forEach(c => {
        list.push(renderItem(c));
    });

    const options = [
        {
            label: renderTitle('Exhibitors'),
            options: list,
        },
    ];

    return (
        <>
            {state.exhibitorsList.length > 0 ?
                <AutoComplete
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={500}
                    style={{
                        width: '40%',
                    }}
                    options={options}
                    onSearch={handleSearch}
                    notFoundContent="Nothing"
                    onSelect={handleSelect}
                    value={state.searchValue}
                    allowClear={true}
                >
                <Input.Search size="large" placeholder="Search here..." />
                </AutoComplete>
                :
                <Skeleton.Input className="form-group w-100 mt-2" active={true} size='large' />
            }
        </>
    );
}

export default withRouter(ExhibitorSearch);