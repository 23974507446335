import React, { Component } from 'react';
import TopNav from './Navigation/TopNav';
import $ from 'jquery';
import LeftNav from './Navigation/LeftNav';
import LeadRetrievalNav from './Navigation/LeadRetrievalNav';
import Globals from '../config/globals';
import Axios from '../config/axios';
import { withRouter, Link } from 'react-router-dom';
import { smallBox, SmartMessageBox } from "../common/utils/functions";
import { Fragment } from 'react';
import SlidingPane from 'react-sliding-pane';
import NumberFormat from 'react-number-format';
import ReactLoading from 'react-loading';
import { reloadLayout } from '../common/services/CompanyService';
import { takeWhile } from 'rxjs/operators';
import { BsGear } from 'react-icons/bs';
import { Table, Skeleton, Select, Tabs } from 'antd';
import { toast } from '@rickylandino/react-messages';
import EmailRecipientManager from './Admin/EmailRecipientManager';
import MailServerManager from './Admin/MailServerManager';
import EmailManager from './Admin/EmailManager';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLeftNav: false,
            showPane: false,
            showPrivacyPolicyPane: false,
            showAdminSettingsPane: false,
            formFields: {
                name: '',
                company: '',
                email: '',
                phone: '',
                comments: ''
            },
            companyName: ''
        }

        this.alive = false;

        if (window.sessionStorage.getItem("adminMode")) {
            Globals.adminMode = window.sessionStorage.getItem("adminMode");
        }

        //this.renderSwitch = this.renderSwitch.bind(this);
        /*this.showLeftNav = this.showLeftNav.bind(this);*/
    }

    componentDidMount() {
        this.alive = true;

        if (this.alive) {
            reloadLayout.pipe(takeWhile(() => this.alive)).subscribe(response => {
                if (response) {
                    this.updateCompanyName();
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        this.alive = false;
    }

    updateCompanyName = () => {
        let myCompanyName = "";

        if (Globals.userInfo.isAdmin && Globals.adminMode == "LRADMIN") {
            myCompanyName = Globals.userInfo.companyName;
        }
        else if (Globals.isImpersonating == true) {
            //myCompanyName = Globals.userInfo.companyName + " (Impersonating " + Globals.userInfo.fullName + ")";
            myCompanyName = "Impersonating " + Globals.userInfo.companyName + " (" + Globals.userInfo.fullName + ")";
        }
        else {
            myCompanyName = Globals.userInfo.companyName;
        }

        this.setState({
            companyName: myCompanyName
        });
    }

    //showLeftNav() {
    //    this.setState({
    //        showLeftNav: !this.state.showLeftNav
    //    }, () => {
    //        if (this.state.showLeftNav) {
    //            $(".page-content-overlay").addClass("yes");
    //        }
    //        else {
    //            $(".page-content-overlay").removeClass("yes");
    //        }
    //    });
    //}

    handleLogout = (event) => {
        SmartMessageBox(
            {
                title: "You are about to logout.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    event.preventDefault();
                    let path = '';
                    path = '/';

                    window.sessionStorage.removeItem("userInfo");

                    Globals.isDirtyPage = false;
                    Globals.userInfo = {};
                    Globals.pathname = '/';
                    this.props.history.push(path);
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    clearTestOrders = () => {
        this.setState({
            showInfoLoaded: false
        });

        Axios.post(`/api/ClearTestOrders`).then(response => {
            if (response.data) {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Test orders have been cleared.</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });

                this.props.updateOrder();
            }

        }).catch(error => {
            console.log(error);
        });
    }

    encryptMSOPasswords = () => {
        Axios.post(`/api/EncryptMSOPasswords`).then(response => {
            if (response.data) {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>MSO User Passwords Have Been Encrypted.</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });
            }

        }).catch(error => {
            console.log(error);
        });
    }

    hidePane = () => {
        this.setState({
            formFields: {
                name: '',
                company: '',
                email: '',
                phone: '',
                comments: ''
            },
            showPane: false,
            loading: false
        });
    }

    handleContactUsInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleContactUsSubmit = (e) => {
        e.preventDefault();

        let postdata = this.state.formFields;

        if (postdata.name === '' ||
            postdata.company === '' ||
            postdata.email === '' ||
            postdata.comments === ''
        ) {
            smallBox({
                title: "Notice",
                content: "<i class='fa fa-clock-o'></i> <i>Please fill out all required fields.</i>",
                color: "#C46A69",
                iconSmall: "fa fa-times fa-2x fadeInRight animated",
                timeout: 4000
            });
        } else {
            this.setState({
                loading: true
            });

            Axios.post(`/api/SendContactEmail`, postdata
            ).then(response => {

                //this.setState({
                //    loading: false,
                //    showPane: false
                //});

                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Thank you for reaching out. We will get back to you as soon as possible.</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });
                this.hidePane();
                //this.props.goHome();
            }).catch(error => {
                this.setState({
                    loading: false
                });

                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Something went wrong, please try again later or call Karen C. at 800-225-4535 ext. 138 or <a href=\"mailto: karenc@d2p.com\">karenc@d2p.com</a>.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });
            });
        }
    }

    hidePrivacyPolicyPane = () => {
        this.setState({
            showPrivacyPolicyPane: false
        });
    }

    hideAdminSettingsPane = () => {
        this.setState({
            showAdminSettingsPane: false
        });
    }

    showAdminSettingsPane = () => {
        //this.setState({
        //    showAdminSettingsPane: true
        //});

        //this.props.navigation.navigate('LoginPage')

        let path = '/management-dashboard';
        this.props.history.replace(path);

        //navigate("/management-dashboard", { replace: true });
    }

    render() {
        const { TabPane } = Tabs;

        return (
          <Fragment>
            <div className="page-wrapper">
                <div className="page-inner">
                    {/* BEGIN Page Header */}
                      {Globals.adminMode == "LRADMIN" ?
                          <LeadRetrievalNav {...this.props} />
                          :
                          <LeftNav {...this.props} />
                      }
                      
                    <div className="page-content-wrapper">
                        <div>
                              <span className="font-weight-bold float-right text-right header-icon mimicA top-nav-item font-size-large line-height-unset" onClick={this.handleLogout} title={'Logout, ' + Globals.realUserInfo.fullName}>
                                <span className="text-icon">LOGOUT</span>&nbsp;
                                <i className="fas fa-sign-out-alt logoutIcon text-icon"></i>
                              </span>
                                <span className="nav-title nav-title-lg float-right">
                                    {Globals.adminMode == "SYSADMIN" ?
                                        <div>
                                            <span style={{ color: 'black' }}>Current User:&nbsp;</span>{Globals.userInfo.fullName}&nbsp;&nbsp;&nbsp;
                                            <BsGear className="fa-1x text-icon cursor-pointer" id="managementPanelBtn" onClick={this.showAdminSettingsPane} />
                                        </div>
                                        :
                                        <>
                                            {Globals.adminMode == "ADMIN" ?
                                                <div><span style={{ color: 'black' }}>Current User:&nbsp;</span>{Globals.realUserInfo.fullName}&nbsp;&nbsp;&nbsp;</div>
                                                :
                                                <div><span style={{ color: 'black' }}>Current User:&nbsp;</span>{Globals.userInfo.fullName}&nbsp;</div>
                                            }
                                        </>
                                    }
                              </span>
                        </div>
                        <div className="row top-banner-border-bottom">
                            <div className="col col-9">
                                  <div className="top-banner">
                                      {this.state?.companyName}
                                </div>
                            </div>

                            <div className="col col-3">
                                  {/*{Globals.userInfo.isAdmin && Globals.adminMode == "ADMIN" &&*/}
                                  {/*    <div className="pt-5 p-3 text-right a" onClick={this.clearTestOrders}> Clear Test Orders</div>*/}
                                  {/*}*/}
                                      {Globals.userInfo.isAdmin && (Globals.adminMode == "ADMIN" || Globals.adminMode == "SYSADMIN") &&
                                      <div className="pt-5 p-3 text-right">
                                          <span className="a" onClick={this.clearTestOrders}> Clear Test Orders</span>&nbsp;&nbsp;&nbsp;
                                          {/*<span className="a" onClick={this.encryptMSOPasswords}> Encrypt Passwords</span>*/}
                                      </div>
                                  }

                                {/*{Globals.userInfo.isAdmin && Globals.adminMode == "ADMIN" &&*/}
                                {/*    <fragment>*/}
                                {/*        <div className="pt-5 p-3 text-right a" onClick={this.clearTestOrders}> Clear Test Orders</div>*/}
                                {/*        <div className="pt-5 p-3 text-right a" onClick={this.encryptMSOPasswords}> Encrypt Passwords</div>*/}
                                {/*    </fragment>*/}
                                {/*}*/}
                            </div>
                        </div>
                    
                    
                   
                        {/* END Page Header */}
                        {/* BEGIN Page Content */}
                        {/* the #js-page-content id is needed for some plugins to initialize */}
                        <main id="js-page-content" role="main" className="page-content">
                        
                            {this.props.children}
                        </main>


                        {/* this overlay is activated only when mobile menu is triggered */}
                        <div onClick={this.showLeftNav} className="page-content-overlay" data-action="toggle" data-class="mobile-nav-on" /> {/* END Page Content */}
                        {/* BEGIN Page Footer */}
                        <footer className="text-center">
                                <p>
                                    The Job Shop Company &#x25cf; 16 Waterbury Road, Prospect, CT 06712 &#x25cf; 800-225-4535<br />
                                    Copyright &#169;{new Date().getFullYear()} Design-2-Part Shows. All rights reserved.<br />
                                    <a onClick={() => this.setState({ showPane: true })}><u>Contact Us</u></a>
                                  <a className="margin-left-15" onClick={() => this.setState({ showPrivacyPolicyPane: true })}><u>Privacy Policy</u></a>
                            </p>
                        </footer>
                        {/* END Page Footer */}
                        {/* BEGIN Shortcuts */}
                    </div>
                </div>
            </div>
            <SlidingPane
                className='some-custom-class'
                overlayClassName='pinkCard'
                isOpen={this.state.showPane}
                title='Contact Us'
                onRequestClose={() => this.setState({ showPane: false })}
            >
                <div className="slide-pane-body scrollableDiv">
                      <h3>Contact Us</h3>
                      <p>We understand that you have confidential business information and want you to know that Design-2-Part Show is committed to safeguarding your privacy online.
                      We strive to make our site as secure and private as reasonably possible.
                    Design-2-Part Show is the sole owner of the information collected on this Web site and we will not sell, share or rent this information to others.</p>
                      <div className="form-group">
                          <label className="form-label">Name *</label>
                          <input type="text" className="form-control-custom" name="name" value={this.state.formFields.name} onChange={this.handleContactUsInputChange} />
                      </div>

                      <div className="form-group">
                          <label className="form-label">Company *</label>
                          <input type="text" className="form-control-custom" name="company" value={this.state.formFields.company} onChange={this.handleContactUsInputChange} />
                      </div>

                      <div className="form-group">
                          <label className="form-label">Email *</label>
                          <input type="text" className="form-control-custom" name="email" value={this.state.formFields.email} onChange={this.handleContactUsInputChange} />
                      </div>

                      <div className="form-group">
                          <label className="form-label">Telephone</label>
                          <NumberFormat className="form-control-custom" name="phone" value={this.state.formFields.phone || ''} onChange={this.handleContactUsInputChange} format="###-###-####" mask="_" />
                      </div>

                      <div className="form-group">
                          <label className="form-label">Comments or Questions *</label>
                          <textarea type="text" className="form-control-custom" name="comments" value={this.state.formFields.comments} onChange={this.handleContactUsInputChange} />
                      </div>
                      {this.state.loading &&
                          <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#275282'} height={30} width={30} />
                      }
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                          <button className="btn btn-submit btn-lg" onClick={this.handleContactUsSubmit}>Submit</button>
                          <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                    </div>
                </div>
              </SlidingPane>
              <SlidingPane
                  className='some-custom-class'
                  overlayClassName='pinkCard'
                  isOpen={this.state.showPrivacyPolicyPane}
                  title='Our Privacy Policy'
                  onRequestClose={() => this.setState({ showPrivacyPolicyPane: false })}
              >
                  <div className="slide-pane-body scrollableDiv">
                      <p>
                            Design-2-Part is the producer of America's premier design and contract manufacturing tradeshows and magazines;

                            Design-2-Part Shows are America's largest and longest running contract manufacturing shows for the sourcing of custom parts, components, services and design. Design-2-Part Magazine helps OEMs find solutions to tough manufacturing problems with cutting-edge information and education.

                            This Privacy Policy lets you know how we collect and use data about you as individuals in your business and professional roles. The Policy covers information which you give us about yourself in a business or professional role as you:

                            <ul>
                                <li>register for or attend events that take place in the United States;</li>
                                <li>subscribe to any of our print publications or access digital editions of those publications, or</li>
                                <li>use one of our mobile apps.</li>
                            </ul>
                      </p>
                      <p></p>

                      <p>
                          <b>Information Collection & Use</b><br />
                            When registering for our trade shows, using our website, ordering products and signing up for certain selected services (such as free subscriptions), you may be asked for information about yourself, including but not
                            limited to contact information (such as name, e-mail address, mailing address and phone number) or demographic information (such as job title and purchasing responsibilities, company information and professional certification).
                      </p>
                      <p>
                            From time to time we may invite you to provide information via surveys. Participation in these surveys is completely voluntary and therefore you have a choice whether to disclose requested contact information (such as name
                            and mailing address) and demographic information (such as zip code or job title).
                      </p>
                      <p></p>

                      <p>
                          <b>Information About You</b><br />
                            We collect and store in server logs non-personally identifiable information, including but not limited to browser type, IP address, operating system, the date and time of visits, the pages visited on the site, time spent viewing the site, and
                            return visits to the site. We also use Google Analytics, a service that records non-personally identifiable data such as browser type, operating system, the date and time of a visit, where visitors came from, the pages visited on this website,
                            the time spent viewing site, where visitors went when they left the site, and return visits to the site.
                       </p>
                       <p>
                            We aggregate this non-personally identifiable data to better understand how visitors use our site, and to help manage, maintain, and report on use of our website. We store IP addresses for fraud detection and prevention purposes.
                       </p>
                       <p>
                            We may share this non-personally identifiable data with third parties for the limited purpose of reporting on use of our website, or to comply with applicable law. We also may share this data with our service providers.
                            We do not rent, sell or share any non-personally identifiable data collected on this website with third parties for marketing purposes.
                       </p>
                      <p></p>

                      <p>
                          <b>Cookies</b><br />
                            Cookies are used on this website. Cookies are small text files created by a web server, delivered through a web browser, and stored on your computer. They provide a means for websites that you visit to keep track of online
                            patterns and preferences, as well as to identify return visitors. Cookies make the personalization of your web experiences possible, so that you do not need to re-enter your information each time you visit a website. We
                            also serve cookies to help us anonymously track web traffic, as described above.
                      </p>
                      <p>
                            You have the ability to manage the use of cookies on your computer using controls in your browser. To learn more about how to manage cookies, visit http://www.allaboutcookies.org/.
                      </p>
                      <p></p>

                      <p>
                          <b>Third Party Content, Links, and Plug-ins</b><br />
                            This site possibly contains links to other websites not owned by D2P. Please be aware that D2P cannot be responsible for the privacy practices of other sites. We urge you to read the privacy statements of websites that collect
                            personally identifiable information.
                      </p>
                      <p></p>

                      <p>
                          <b>Students and Children</b><br />
                            D2P will not knowingly collect personally identifiable information from anyone under 16 years of age, unless it is necessary to respond to a one-time request. Once the request has been answered, D2P will delete the requesting
                            child's personally identifiable information.
                      </p>
                      <p></p>

                      <p>
                          <b>Changes to Our Privacy Policy</b><br />
                            As our organization and services evolve, this Privacy Policy may be revised to reflect those changes. We reserve the right to amend this Privacy Policy at any time, for any reason. Notice of such amendments will be posted as
                            an updated Privacy Policy on this website, and an effective date will be indicated. Users are encouraged to check our site frequently to review the current Privacy Policy in effect.
                      </p>
                      <p></p>

                      <p>
                            <b>Preferences, permission, opt-out</b><br />
                            If you wish to be excluded from marketing by D2P via mail and/or e-mail, phone or fax, please contact D2P at 800.225.4535 or e-mail privacypolicy(at)d2p.com to make that change to your account.
                      </p>
                      <p></p>

                        <p>
                            <b>Questions?</b><br />
                            Please contact us with any questions about the D2P Privacy Policy at 800.225.4535.
                        </p>
                        <p></p>
                  </div>

                  <div className="modal-footer">
                      <div className="form-group col-12 padding-25-10">
                          <button className="btn btn-primary btn-lg" onClick={this.hidePrivacyPolicyPane}>Close</button>
                      </div>
                  </div>
              </SlidingPane>
              <SlidingPane
                  className='some-custom-class'
                  overlayClassName='pinkCard'
                  isOpen={this.state.showAdminSettingsPane}
                  title='MyShowOnline Admin Settings'
                  onRequestClose={() => this.setState({ showAdminSettingsPane: false })}
              >
                    <div className="slide-pane-body scrollableDiv">
                        <Tabs defaultActiveKey='tab-email-manager' tabPosition='top' type='card'>
                            {/*<TabPane tab='Email Server' key='tab-email-server'>*/}
                            {/*    <MailServerManager />*/}
                            {/*</TabPane>*/}
                            {/*<TabPane tab='Email Recipients' key='tab-email-recipients'>*/}
                            {/*    <EmailRecipientManager />*/}
                            {/*</TabPane>*/}
                            <TabPane tab='Email Manager' key='tab-email-manager'>
                                <EmailManager reload={true} />
                            </TabPane>
                        </Tabs>
                  </div>

                  <div className="modal-footer">
                      <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-primary" onClick={this.hideAdminSettingsPane}>Close</button>&nbsp;&nbsp;&nbsp;
                            
                      </div>
                  </div>
              </SlidingPane>
        </Fragment>
    );
  }
}

export default withRouter(Layout);