import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Button, Input, notification, Popover, message } from "antd";
import Axios from '../../config/axios';
import ExhibitorSearch from './ExhibitorSearch';
import QRCode from 'qrcode.react';
import { SmartMessageBox } from "../../common/utils/functions";
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import imgSpinnerLarge from '../../img/spinnerLarge.gif';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Moment from 'moment';
import ExhibitorLeadsSlider from './ExhibitorLeadsSlider';
import EmailTemplatePane from './EmailTemplatePane';
import { FaEdit, FaDownload, FaInfoCircle } from 'react-icons/fa';
import Globals from '../../config/globals';

function SendActivationCodeEmailsTab(props) {
    const [state, setState] = useState({
        dataLoaded: true,
        selectedShow: props.selectedShow,
        selectedShowName: props.selectedShowName,
        exhibitorsList: [],
        selectedExhibitors: [],
        emailLeadsTo: '',
        sendingEmails: false,
        showLeadsPane: false,
        selectedExhibitorId: '',
        selectedExhibitorName: '',
        emailTemplate: {}
    });

    const [processingSummary, setprocessingSummary] = useState('');

    const [showEmailTemplatePane, setShowEmailTemplatePane] = useState(false);

    var urlend = window.api.slice(-1) === '/' ? 'hubs/alert' : '/hubs/alert';
    const url = window.api + urlend;
    var connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(url)
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        GetLeadRetrievalExhibitorsForActivationCodeEmails();

        connection.start()
            .then(result => {
                connection.on('ReceiveActivationCodeEmailsAlert', alerts => {
                    //console.log(alerts);

                    setprocessingSummary(alerts);
                });
            })
            .catch(e => console.log('Connection failed: ', e));

    }, []);

    function GetLeadRetrievalExhibitorsForActivationCodeEmails() {
        Axios.get(`/api/GetLeadRetrievalExhibitorsForActivationCodeEmails`, {
            params: {
                showcode: state.selectedShow,
                templatetype: 'LeadRetrievalActivationCodeEmails'
            }
        }).then(response => {
            let exhibitorsList = response.data.exhibitorsList;
            let emailTemplate = response.data.emailTemplate;

            console.log(emailTemplate);

            setState({
                ...state,
                exhibitorsList: exhibitorsList,
                emailTemplate: emailTemplate,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function editFormatter(cell, row) {
        return (
            <i className="fas fa-eye fa-125x color-pink text-center hover" id="editIcon"></i>
        );
    }

    const columns = [
    {
        dataField: 'id',
        text: 'Id',
        hidden: true
    }, {
        dataField: 'name',
        text: 'Exhibitor'
    }
];

    const pagination = {
        sizePerPage: 50,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        hideSizePerPage: false,
        showTotal: true,

        onPageChange: function (page, sizePerPage) {

            setTableControl({
                ...tableControl,
                page
            });
        },
        onSizePerPageChange: function (page, sizePerPage) {

        }
    };

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const rowEvents = {
        onClick: (e, row) => {

        },
        onDoubleClick: (e, row, rowIndex) => {
            
        }
    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <input
                type="checkbox"
                ref={(input) => {
                    if (input) input.indeterminate = indeterminate;
                }}
                {...rest}
            />
        ),
        selectionRenderer: ({ mode, ...rest }) => (
            <input type={mode} {...rest} />
        ),
        onSelect: (row, isSelect, rowIndex, e) => {
            let mySelectedExhibitorsList = state.selectedExhibitors;

            if (isSelect) {
                mySelectedExhibitorsList.push(row);
            }
            else {
                const index = mySelectedExhibitorsList.indexOf(row);
                if (index > -1) {
                    mySelectedExhibitorsList.splice(index, 1);
                }
            }

            setState({
                ...state,
                selectedExhibitors: mySelectedExhibitorsList
            });
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                //set selected exhibitors to entire list
                setState({
                    ...state,
                    selectedExhibitors: state.exhibitorsList
                });

                return state.exhibitorsList.map(row => row.id);
            } else {
                setState({
                    ...state,
                    selectedExhibitors: []
                });

                return [];
            }
        }
    };

    function onEmailLeadsToChange(e) {
        setState({
            ...state,
            emailLeadsTo: e.target.value
        });
    }

    function SendActivationCodeEmails() {
        //console.log("selected show (grandchild): ", state.selectedShow);

        //indicates if emails should go to exhibitor email address or test email address
        let emailOverride = '';
        if (state.emailLeadsTo === '') {
            emailOverride = '';
        }
        else {
            emailOverride = state.emailLeadsTo
        }

        //console.log(state.selectedExhibitors);

        let postdata = {
            showName: state.selectedShowName,
            lrexhList: state.selectedExhibitors,
            showcode: parseInt(state.selectedShow),
            emailoverride: emailOverride
        }

        //console.log(postdata);

        Axios.post(`/api/SendLeadRetrievalActivationCodeEmails`, postdata
        ).then(response => {
            setState({
                ...state,
                sendingEmails: false
            });

            toast.success("Emails successfully sent to " + response.data + " exhibitors");

            setprocessingSummary("Emails successfully sent to " + response.data + " exhibitors");
        }).catch(error => {

        });
    }

    function handleSendEmails(e) {
        e.preventDefault();

        console.log(state.emailTemplate);

        if (state.selectedExhibitors.length === 0) {
            toast.error("No exhibitors have been selected");

            return;
        }

        if (state.emailTemplate === null) {
            toast.error("No email template has been selected");

            return;
        }

        var myConfirmationMsg = "";
        if (state.emailLeadsTo === '') {
            myConfirmationMsg = "You are about to email " + state.selectedExhibitors.length + " selected exhibitors";
        }
        else {
            myConfirmationMsg = "You are about to send " + state.selectedExhibitors.length + " exhibitor emails to " + state.emailLeadsTo;
        }

        SmartMessageBox(
            {
                title: myConfirmationMsg,
                content:
                    "Are you sure you would like to proceed?",
                buttons: "[No][Yes]"
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    e.preventDefault();

                    setState({
                        ...state,
                        sendingEmails: true
                    });

                    SendActivationCodeEmails();
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function hidePane() {
        //console.log('ManageExhibitorLeads hidePane invoked');

        setState({
            ...state,
            showLeadsPane: false
        });
    }

    function setEmailTemplateInfo(emailTemplate) {
        console.log(emailTemplate);

        setState({
            ...state,
            emailTemplate: emailTemplate
        });

        //toast.success("I made it back");
        //setState({
        //    patientAttachments,
        //    loaded: true
        //});
    }

    const content = (
        <div>
            {state.emailTemplate ?
                <div>
                    <p>Uploaded By: {state.emailTemplate.uploadedBy}</p>
                    <p>Upload Date: {Moment(state.emailTemplate.templateDate).format('L')}</p>
                    <p>Notes: {state.emailTemplate.notes}</p>
                </div>
                :
                <div>Who cares, will never display anyhow</div>
            }
        </div>
    );

    function downloadEmailTemplate() {
        let postdata = {
            emailtemplate: state.emailTemplate.templateType
        }

        const key = "downloademailtemplate";
        message.loading({
            content: 'Downloading Email Template...',
            key,
            duration: 0,
            //style: {
            //    position: "fixed",
            //    bottom: 0,
            //    left: 0
            //},
        });

        Axios.post(`/api/DownloadEmailTemplate`, postdata, {
            responseType: 'blob'
        }).then(response => {
            message.destroy(key);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = state.emailTemplate.templateName;
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="code-box-demo">
            <h3>Lead Retrieval Exhibitors</h3>
            <div className="row">
                <div className="col-lg-9">
                    <div className="fullTable">
                        {state.dataLoaded &&
                            <BootstrapTable
                              keyField='id'
                              data={state.exhibitorsList}
                              columns={ columns }
                              selectRow={selectRow}
                              rowEvents={rowEvents}
                              pagination={paginationFactory(pagination)}
                              hover condensed striped
                            />
                        }
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="row">
                        <h3>Send Lead Retrieval Activation Code Emails</h3>
                    </div>
                    <div className="row">
                        Email Template
                    </div>
                    <div className="row">
                        {state.emailTemplate ?
                            <div>
                                <span style={{ color: "green" }}>{state.emailTemplate.templateName}</span>&nbsp;&nbsp;
                                <Popover content={content} title={state.emailTemplate.templateName} trigger="hover" style={{ marginTop: 10 }}>
                                    <FaInfoCircle />
                                </Popover>&nbsp;&nbsp;&nbsp;
                            </div>
                            :
                            <div style={{ color: "red" }}>No Template Defined&nbsp;&nbsp;&nbsp;</div>
                        }
                        <span className="a" onClick={() => setShowEmailTemplatePane(true)}><FaEdit /></span>&nbsp;&nbsp;&nbsp;
                        <span className="a" onClick={() => downloadEmailTemplate()}><FaDownload /></span>
                    </div>
                    <div className="row">
                        &nbsp;
                    </div>
                    <div className="row">
                        <label className="form-label">Send Test Email(s):</label>
                    </div>
                    <div className="row">
                        <input type="text" className="form-control-custom" name="emailLeadsto" value="" placeholder="Leaving blank will send to exhibitor's actual email address" value={state.emailLeadsTo}
                            onChange={onEmailLeadsToChange} />
                    </div>
                    <div className="row">
                        <button type="button" onClick={handleSendEmails} className="btn btn-danger btn-block mt-1" style={{ width: "150px" }} >
                            Send Email(s)
                        </button>&nbsp;&nbsp;
                        {state.sendingEmails == true &&
                            <img src={imgSpinnerLarge} width="50px" height="50px" />
                        }
                    </div>
                    <div className="row">
                        <label className="form-label">{processingSummary}</label>
                    </div>
                </div>
            </div>
            {showEmailTemplatePane && <EmailTemplatePane {...props} hidePane={() => setShowEmailTemplatePane(false)} emailTemplate={state.emailTemplate} updateEmailTemplateInfo={(template) => setEmailTemplateInfo(template)} paneTitle='Activation Code Email Template' templateType='LeadRetrievalActivationCodeEmails' />}
        </div>
    );
}

export default withRouter(SendActivationCodeEmailsTab);