import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import { message } from 'antd';
import QRCode from 'qrcode.react';

function SampleQRCodesTab(props) {
    const [state, setState] = useState({
        qrcode1: '',
        qrcode2: '',
        qrcode3: '',
        qrcode4: ''
    });

    useEffect(() => {
        var myQRCodeText1 = EncryptQRCodeText("ActivateD2PShow|" + props.selectedShow + "|" + props.selectedShowName + "|3547|DESIGN-2-PART MAGAZINE");
        var myQRCodeText2 = EncryptQRCodeText("xxD2P|" + props.selectedShow + "|JOHN|SMITH|SMITH & ASSOCIATES|BOSTON|MA|ENGINEER|JSMITH@ACMECORPORATION.COM|C|R|617.555.1212|17");
        var myQRCodeText3 = EncryptQRCodeText("xxD2P|" + props.selectedShow + "|MARY|JONES|JONES CONSULTING SERVICES|HARTFORD|CT|ENGINEER|MJONES@JONESCONSULTING.COM|C|R|912.555.6382|17");
        var myQRCodeText4 = EncryptQRCodeText("xxD2P|" + props.selectedShow + "|DAVE|THOMPSON|NORTHERN AVIATION|MINNEAPOLIS|MN|PRESIDENT|DAVE@NORTHERNAVIATION.COM|A|F|952.555.6513|54");

        setState({
            ...state,
            qrcode1: myQRCodeText1,
            qrcode2: myQRCodeText2,
            qrcode3: myQRCodeText3,
            qrcode4: myQRCodeText4
        });
    }, [props]);

    const EncryptQRCodeText = (myQRCodeText) => {
        let EncryptedQRCodeText = '';

        for (let i = 0; i < myQRCodeText.length; i++) {
            EncryptedQRCodeText += String.fromCharCode(myQRCodeText.charCodeAt(i) + 1);
        }

        return EncryptedQRCodeText;
    }

    return (
        <div className="code-box-demo">
            <h3>Sample QR Codes for {props.selectedShowName}</h3>

            <div className="row" style={{ paddingTop: '100px' }}>
                <div className="col col-lg-4 col-12 text-center">
                    <QRCode value={state.qrcode1} /><br />
                    <label className="form-label mt-1">ACTIVATE {props.selectedShowName} SHOW</label>
                </div>
                <div className="col col-lg-4 col-12 text-center">
                    <QRCode value={state.qrcode2} /><br />
                    <label className="form-label mt-1">SAMPLE ATTENDEE 1 - JOHN SMITH</label>
                </div>
                <div className="col col-lg-4 col-12 text-center"></div>
            </div>
            <div className="row" style={{ paddingTop: '100px' }}>
                <div className="col col-lg-4 col-12 text-center">
                    <QRCode value={state.qrcode3} /><br />
                    <label className="form-label mt-1">SAMPLE ATTENDEE 2 - MARY JONES</label>
                </div>
                <div className="col col-lg-4 col-12 text-center">
                    <QRCode value={state.qrcode4} /><br />
                    <label className="form-label mt-1">SAMPLE ATTENDEE 3 - DAVE THOMPSON</label>
                </div>
                <div className="col col-lg-4 col-12 text-center"></div>
            </div>
        </div>
    );
}

export default withRouter(SampleQRCodesTab);