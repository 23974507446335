import React, { Component, Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import '../../../css/card.css';

import Axios from '../../../config/axios';
import Globals from '../../../config/globals';

import { Modal } from 'react-bootstrap';

import googlePlayImg from '../../../img/g_play.png';
import appStoreImg from '../../../img/a_store.png';

import { smallBox, SmartMessageBox } from "../../../common/utils/functions";

import NumberFormat from 'react-number-format';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Moment from 'moment';

export class ShowInfoCard extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showInfo.name !== prevState.showInfo.name && nextProps.showInfo.show_abbrev !== prevState.showInfo.show_abbrev) {
            return { showInfo: nextProps.showInfo, formFields: nextProps.showInfo }
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showInfo: this.props.showInfo,
            showDetails: this.props.showDetails,
            formFields: this.props.showInfo,
            showPane: false,
            showHotelReservations: false,
            showSWPane: false,
            showEmailScamPane: false,
            setupStartTime: '',
            showIndicator: ''
        }
    }

    componentDidMount() {
        let mySetupStartTime = this.state.showInfo.setupDay1Time.split("-")[0];
        //console.log(this.state.showInfo.startdate);

        var year = (new Date(this.state.showInfo.startdate)).getFullYear();
        //console.log(this.state.showInfo.invcode + year.toString().substring(year.toString().length - 2));

        //console.log(mySetupStartTime);

        this.setState({
            setupStartTime: mySetupStartTime,
            showIndicator: this.state.showInfo.invcode + year.toString().substring(year.toString().length - 2)
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });
    }

    download = (name, blob) => {
        let postdata = {
            id: this.props.location?.state?.showcode || Globals.defaultShowCode,
            name,
            blob
        };


        Axios.post(`/api/DownloadAttachment`, postdata, { responseType: 'blob' }
        ).then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(error => {
            console.log(error);
        });

    }

    render() {
        return (
            <Card className="showCard">
                <Card.Header>Show Information & Hours</Card.Header>
                <Card.Body>
                    <div className="row">
                        <div className="col col-lg-6 col-12 text-center pb-5">
                            <div className="">
                                <h3>Convention Center</h3>
                            </div>

                            <div className="">
                                {this.state.showInfo.location}
                            </div>
                            <div className="">
                                {this.state.showInfo.street1}
                            </div>
                            <div className="">
                                {this.state.showInfo.street2}
                            </div>
                            <div className="">
                                {this.state.showInfo.city}, {this.state.showInfo.state} {this.state.showInfo.zip}
                            </div>
                            
                        </div>

                        <div className="col col-12 d-block d-lg-none">
                            <div className="p-3 text-center">
                                <button className="btn btn-warning btn-lg" onClick={() => this.setState({ showSWPane: true })}>Shipping & Warehousing Information</button>
                            </div>
                        </div>

                        <div className="col col-lg-6 col-12 text-center pb-5">
                            <div className="">
                                <h3>Trade Show Hours</h3>
                            </div>

                            <div className="">
                                {this.state.showInfo.showDates}
                            </div>
                            <div className="">
                                {this.state.showInfo.showHoursDay1}
                            </div>
                            <div className="">
                                {this.state.showInfo.showHoursDay2}
                            </div>
                            
                        </div>

                        <div className="col col-lg-6 col-12 d-none d-lg-block">
                            <div className="p-3 text-center">
                                <button className="btn btn-warning btn-lg" onClick={() => this.setState({ showSWPane: true })}>Shipping & Warehousing Information</button>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-12">
                            <div className="p-3 text-center">
                                <button className="btn btn-warning btn-lg btn-resize" onClick={() => this.setState({ showHotelReservations: true })}>Hotel Reservation Information</button>
                            </div>
                        </div>

                        <div className="col col-12 pb-5">
                            <h3 className="text-center">Exhibitor Set-Up Hours</h3>
                            <ul>
                                <li>{this.state.showInfo.setupDay1Date}: {this.state.showInfo.setupDay1Time}</li>
                                <li><b>No set-up</b> is allowed prior to {this.state.setupStartTime} on {this.state.showInfo.setupDay1Date}</li>
                            </ul>
                        </div>

                        <div className="col col-12 pb-5">
                            <h3 className="text-center">Exhibitor Breakdown Hours</h3>
                            <ul>
                                <li>{this.state.showInfo.breakdownDay}: {this.state.showInfo.breakdownTime}</li>
                                <li>All Pick-ups must be made from {this.state.showInfo.breakdownTime} on {this.state.showInfo.breakdownDay}.</li>
                                <li><b>No dismantling</b> prior to end of show {this.state.showInfo.breakdownDay}</li>
                            </ul>
                        </div>

                        <div className="col col-12 pb-5">
                            <h3 className="text-center">Show Information</h3>
                            <ol>
                                <li>Rules and Regulations <span className="a" onClick={() => this.setState({ showPane: true })}>(click here)</span></li>
                                <li>Security: Show Management has overnight security, however, exhibitors have the responsibility for personal items i.e., phones, briefcase, laptop computers, etc. THE JOB SHOP COMPANY is not responsible for any missing parts during the show. &nbsp;
                                    <b>NOTE: Do not leave your show leads in your booth overnight.</b>
                                </li>
                                <li>OEM Solutions Showcase: This display offers the visitors an opportunity to browse through and study exhibitor samplings of parts and services represented on the trade show floor.
                                Tables are located at the show entrance for exhibitors to tag up to two parts with your company name and booth location in the show. These tables are for samples of your custom parts only.
                                No finished products, company brochures, flyers or business cards are permitted. Parts must be small enough to fit on the table top. Photos are permitted of very large parts.
                                Remember to take your parts from this display area when the show closes. Show Management is not responsible for parts left on the tables after the show closes. Tags will be available at the Show Manager's Office. <br />
                                <b>* NOTE:</b> Due to tradeshow booth layout and overall lack of space, the Showcase is not available at the Marlborough, MA Show.
                                    </li>
                                {/* <li>Exhibitor Email Scam Warning <span className="a" onClick={() => this.setState({ showEmailScamPane: true })}>(click here)</span></li> */}
                            </ol>
                        </div>

                        <div className="col col-12 pb-5 pl-6">
                            <b>Exhibitor Warning</b> <span className="a" onClick={() => this.setState({ showEmailScamPane: true })}>(click here)</span>
                        </div>
                    </div>
                </Card.Body>

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='showCard'
                    isOpen={this.state.showPane}
                    title='Rules and Regulations'
                    onRequestClose={() => this.setState({ showPane: false })}
                >
                    <div className="slide-pane-body scrollableDiv line-height-1-5">
                        <div className="row pb-5">
                            <h3 className="text-center">Rules and Regulations</h3>
                            <ol>
                                <li>Only ONE company is allowed to occupy at least 10 feet of space. The exception is when two companies are a division of the same corporation.</li>
                                <li>Only companies manufacturing in North America are eligible to exhibit. Companies that have divisions in other countries are not allowed to display the services those divisions offer. This restriction applies to parts & services and/or literature detailing overseas operations.</li>
                                <li><b>Food Giveaways</b> (i.e. pretzels, popcorn, cookies, etc.), are not allowed except for candy items and mints.</li>
                                <li><b>Alcohol</b> - consumption or serving is strictly prohibited in the booth or on the tradeshow floor.</li>
                                <li>Reps can work a booth, but only principals can purchase exhibit space.</li>
                                <li>Proper Business Attire is required by all persons staffing the booth.</li>
                                <li>Absolutely no Entertainment in facility allowed (i.e., clowns, magicians, dancers, balloons, etc.).</li>
                                <li>Contests and collection of business cards for a prize drawing are not permitted, unless approved by show management.</li>
                                <li>Equipment is allowed, however, it must be owned by the exhibitor - not leased, rented or borrowed from a dealer. It must also be used for demonstration purposes only and should be shut-off at all other times in order to allow neighboring booths to hold meaningful conversations.</li>
                                <li>A/V Equipment is allowed but the sound must be kept at a level not to effect conversations in your neighbor's booth.</li>
                                <li>Booths are required to be manned constantly during the show. Dismantling must not begin until the show officially ends as posted by show management.</li>
                                <li>Printed promotional material must be handed out to visitors in your booth area only. Printed material is NOT allowed in the concession or registration areas.</li>
                            </ol>
                        </div>
                        <div className="row">
                            <h3 className="text-center">Booth Restrictions</h3>
                            <ol>
                                <li><b>Booth Size Restrictions -</b> The height of the rear of the booth must not exceed 10' feet without special permission from Show Management. Sides cannot extend higher than 4 feet so as not to obstruct the view of a neighbor's display. Pre-built Displays can only extend 4 feet off the back of the booth.</li>
                                <li><b>All Archway or Island Booths</b> must be a minimum three (3) feet off the side rail. Exceptions will be made for corner booth locations on the open side.</li>
                                
                            </ol>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                        </div>
                    </div>
                </SlidingPane>

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='showCard'
                    isOpen={this.state.showHotelReservations}
                    title='Hotel Reservation Information'
                    onRequestClose={() => this.setState({ showHotelReservations: false })}
                >
                    <div className="slide-pane-body scrollableDiv">
                        {(this.state.showInfo.hotelLink1Active || this.state.showInfo.hotelLink2Active || this.state.showInfo.hotelLink3Active) ?
                            <Fragment>
                                {this.state.showInfo.hotelRoomBlockSoldOut ?
                                    <p>The hotel room block is sold-out. Please contact Stephanie at 800-225-4535, ext. 150.</p>
                                    :
                                    <div className="row pb-5">
                                        <b className="text-center w-100">To obtain the Special Show Rate, Hotel Reservations must be made through our on-line reservation system.</b> <br />
                                        <b className="text-center w-100">Our room block may be sold-out before our deadline date of</b><br /><br />
                                        {this.state.showInfo.hotelReservationDeadline ?
                                            <h3 className="text-center w-100 pb-5">{Moment(this.state.showInfo.hotelReservationDeadline).format('dddd, MMMM Do YYYY')}</h3>
                                            :
                                            <h3 className="text-center w-100 pb-5">N/A</h3>
                                        }

                                        {this.state.showInfo.hotelLink1Active &&
                                            <div className="col col-12 text-center">
                                                <a href={this.state.showInfo.hotelLink1} target="_blank" rel="noopener noreferrer" className="font-weight-normal"><button className="btn btn-danger btn-lg mb-3">Click here to book at The {this.state.showInfo.hotelName1} - {this.state.showInfo.hotelRoomRate1}</button></a>
                                                {this.state.showInfo.hotelDetails1 &&
                                                    <div>
                                                        {this.state.showInfo.hotelDetails1.split('\u2022').map(str => <p className="h6">{'\u2022' + ' ' + str}</p>)}
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {this.state.showInfo.hotelLink2Active &&
                                            <div className="col col-12 text-center">
                                                <a href={this.state.showInfo.hotelLink2} target="_blank" rel="noopener noreferrer" className="font-weight-normal"><button type="submit" formTarget="_blank" className="btn btn-danger btn-lg mb-3">Click here to book at The {this.state.showInfo.hotelName2} - {this.state.showInfo.hotelRoomRate2}</button></a>

                                                {/*
                                                <form action={this.state.showInfo.hotelLink2}>
                                                    <button type="submit" formtarget="_blank" className="btn btn-danger btn-lg mb-3">Click here to book at The {this.state.showInfo.hotelName2} - {this.state.showInfo.hotelRoomRate2}</button>
                                                </form>
                                                */}

                                                {this.state.showInfo.hotelDetails2 &&
                                                    <div>
                                                        {this.state.showInfo.hotelDetails2.split('\u2022').map(str => <p className="h6">{'\u2022' + ' ' + str}</p>)}
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {this.state.showInfo.hotelLink3Active &&
                                            <div className="col col-12 text-center">
                                                <a href={this.state.showInfo.hotelLink3} target="_blank" rel="noopener noreferrer" className="font-weight-normal"><button type="submit" formTarget="_blank" className="btn btn-danger btn-lg mb-3">Click here to book at The {this.state.showInfo.hotelName3} - {this.state.showInfo.hotelRoomRate3}</button></a>

                                                {/*
                                                <form action={this.state.showInfo.hotelLink3}>
                                                    <button type="submit" formtarget="_blank" className="btn btn-danger btn-lg mb-3">Click here to book at The {this.state.showInfo.hotelName3} {this.state.showInfo.hotelRoomRate3}</button>
                                                </form>
                                                */}

                                                {this.state.showInfo.hotelDetails3 &&
                                                    <div>
                                                        {this.state.showInfo.hotelDetails3.split('\u2022').map(str => <p className="h6">{'\u2022' + ' ' + str}</p>)}
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </Fragment>
                            :
                            <p>Hotel information is currently not available; you will be notified by email when it is activated.</p>
                        }
                        
                        
                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-primary btn-lg margin-left-15" onClick={() => this.setState({ showHotelReservations: false })}>Close</button>
                        </div>
                    </div>
                </SlidingPane>

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='showCard'
                    isOpen={this.state.showSWPane}
                    title='Shipping & Warehousing Information'
                    onRequestClose={() => this.setState({ showSWPane: false })}
                >
                    <div className="slide-pane-body scrollableDiv">
                        {this.state.showInfo.myJobShopShowManualFlag ?
                            <Fragment>
                                <div className="col col-12 text-center pb-3"><h3>Warehouse/Direct to Show Site Shipping/Breakdown/Dismantle</h3></div>
                                <div className="row pb-5">
                                    <div className="text-left col swradiusbordered mr-4 ml-5">
                                        <div className="mb-2 pt-3">
                                            <h3>Warehousing Service Package</h3>
                                            Use of the warehouse for shipments prior to the show is classified "complete service" and will be billed to your company separately by the decorating company. Minimum charge 200 lbs.
                                        </div>
                                        <div className="mb-2">
                                            Rate for the Warehouse Service Package is <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.warehouseRate}</span>. Exhibitors must pay in advance for warehouse shipments.
                                        </div>
                                        <div className="mb-2">
                                            <div>
                                                Package Includes
                                            </div>
                                            <div>
                                                <ol className="pl-5">
                                                    <li>Crated, cased or packaged exhibit materials stored at the warehouse for a period of 30 days prior to show opening.</li>
                                                    <li>Deliver materials to the exhibit hall and place in exhibit space.</li>
                                                    <li>Remove crates, cases etc. at the end of show set-up, return to exhibit space for breakdown.</li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            {this.state.showInfo.shippingFormText &&
                                                <div className="a" style={{ fontSize: "1.00rem", fontWeight: 'bold' }}>
                                                    <div onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_Warehouse_Shipment_Order.pdf', this.state.showInfo.shippingForm)}>Warehouse Shipment Order Form (click here)</div>
                                                </div>
                                            }

                                            <div className="pb-3">
                                                To verify receipt of your shipment, contact exhibitor services: <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.exhServicesPhone}.</span>
                                            </div>

                                            <div className="pb-4">
                                                <strong>REMINDER:</strong><br />
                                                    Shipments may be consigned to the warehouse <b>up to 30 days in advance. Please ship to arrive by <span style={{ color: "blue" }}>{this.state.showInfo.arriveByDate}.</span> This gives
                                                        you a chance to check delivery prior to arriving at the show.</b>
                                            </div>

                                            <div className="pb-1">
                                                <b>WAREHOUSE ADDRESS:</b><br />
                                                {this.state.showInfo.warehouseName}<br />
                                                {this.state.showInfo.warehouseFreightCo}<br />
                                                {this.state.showInfo.warehouseStreet1}<br />
                                                {this.state.showInfo.warehouseStreet2}<br />
                                                {this.state.showInfo.warehouseCityStateZip}<br />
                                            </div>

                                            <div>
                                                <b>For:</b><br />
                                                {Globals.userInfo.companyName}<br />
                                                Booth # {this.state.showDetails.booth1}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-left col ml-4 mr-5">
                                        <div className="text-left col swradiusbordered mb-4 pt-3">
                                            <h3>Direct to Show Site Shipping</h3>

                                            <div className="pb-4">
                                                The exhibit hall does not contain storage facilities, nor will it have personnel on hand to unload shipments until&nbsp;
                                                <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.setupDay1Date}</span>, <b>8:00 a.m.- 6:00 p.m. Any shipments delivered to show site outside of this receiving window will incur a surcharge.</b>&nbsp;
                                                If you plan to ship by common carrier or deliver with your own truck, please plan accordingly.
                                            </div>

                                            <div className="pb-1">
                                                <b>DIRECT TO SHOWSITE:</b><br />
                                                Ship To Arrive: <span style={{ color: "red" }}>BETWEEN 8:00 AM - 6:00 PM ON SET-UP DAY</span><br />
                                                {this.state.showInfo.location}<br />
                                                Design-2-Part Show<br />
                                                {this.state.showInfo.street1}<br />
                                                {this.state.showInfo.city},  {this.state.showInfo.state}  {this.state.showInfo.zip}<br />
                                                <span className="a">
                                                    {this.state.showInfo.freightFormText &&
                                                        <div onClick={(name, blob) => this.download(this.state.showIndicator + '_Shipping Labels.pdf', this.state.showInfo.freightForm)}><u>Download Shipping Labels</u></div>
                                                    }
                                                </span>
                                                <br />
                                            </div>

                                            <div>
                                                <b>For:</b><br />
                                                {Globals.userInfo.companyName}<br />
                                                Booth # {this.state.showDetails.booth1}
                                            </div>
                                        </div>
                                        <div className="text-left col swradiusbordered mr-5 pt-3">
                                            <h3>Breakdown/Dismantle Instructions</h3>
                                            Dismantling begins at the close of the show. No dismantling is allowed prior to this time. Empty crates will be brought to your booth within one hour of show close.
                                            If you have a truck scheduled to pick up your freight, please have them arrive at the convention hall by 4:30 - 5:00 pm. Please have the driver check in with the
                                            decorator upon arrival with your company name & booth number. The exhibitor must also inform the decorator their trucking company of choice is picking up their freight.<br /><br />
                                            <b>All freight must be off the show floor by 6:30 pm, if not it will be shipped by the show carrier at the exhibitor's expense.</b>
                                            <br /><br />

                                            A freight carrier will be available with bill of ladings and shipping labels at the Hall by 12:30 pm on <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.freightCoOnHand}.</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-5 ml-5 mr-5" style={{ color: "red" }}>
                                    Due to an increase in shipping problems and delays throughout the country, Design-2-Part strongly recommends all exhibitors ship to the dedicated advance warehouse.
                                    This will help ensure that exhibit materials arrive on time and that you have everything you need at the show.
                                </div>
                            </Fragment>
                            :
                            <p>You'll be notified by email when shipping information is made available.</p>
                        }

                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-primary btn-lg margin-left-15" onClick={() => this.setState({ showSWPane: false })}>Close</button>
                        </div>
                    </div>
                </SlidingPane>

                {/*<SlidingPane*/}
                {/*    className='some-custom-class'*/}
                {/*    overlayClassName='showCard'*/}
                {/*    isOpen={this.state.showSWPane}*/}
                {/*    title='Shipping & Warehousing Information'*/}
                {/*    onRequestClose={() => this.setState({ showSWPane: false })}*/}
                {/*>*/}
                {/*    <div className="slide-pane-body scrollableDiv">*/}
                {/*        {this.state.showInfo.myJobShopShowManualFlag ?*/}
                {/*            <div className="row pb-5">*/}
                {/*                <div className="col col-12 text-center"><h3>Shipping & Warehousing Information</h3></div>*/}
                {/*                <div className="text-left col col-12">*/}
                {/*                    <div>Material handling of up to 1,000 lbs. of parts or display material (within the hall) is provided as part of your booth package price for each 10' booth.*/}
                {/*                        This means that you will not be charged anything for in-hall freight handling as long as your shipment is (a) under 1,000 lbs. and (b) delivered to the exhibit hall after 12:00 pm on set-up day.</div>*/}
                {/*                </div>*/}
                {/*                <div className="col col-12 pb-5"></div>*/}
                {/*                <div className="col col-12 text-center"><h3>Warehousing Service Package</h3></div>*/}
                {/*            <div className="text-left col col-12">*/}
                {/*                    <strong>*/}
                {/*                        Use of the warehouse for shipments prior to the show is classified "complete service" and will be billed to your company separately by the decorating company. Minimum charge 200 lbs.<br /><br />*/}
                {/*                        Rate for the Warehouse Service Package is {this.state.showInfo.warehouseRate}. Exhibitors must pay in advance for warehouse shipments.<br /><br />*/}
                {/*                    </strong>*/}
                {/*            </div>*/}
                {/*                <div className="text-left">*/}
                {/*                    <div className="col col-12">*/}
                {/*                        <strong>Package Includes:</strong>*/}
                {/*                    </div>*/}
                {/*                    <div className="col col-12">*/}
                {/*                        <ol className="pl-5">*/}
                {/*                            <li>Crated, cased or packaged exhibit materials stored at the warehouse for a period of 30 days prior to show opening.</li>*/}
                {/*                            <li>Deliver materials to the exhibit hall and place in exhibit space.</li>*/}
                {/*                            <li>Remove crates, cases etc. at the end of show set-up, return to exhibit space for breakdown.</li>*/}
                {/*                        </ol>*/}
                {/*                    </div>*/}
                {/*                    <div className="col col-12 pb-5">*/}
                {/*                        Exhibitors must pay in advance for warehouse shipments. Click and fill out the Warehouse Shipment Order Form below.*/}
                {/*                    </div>*/}

                {/*                    {this.state.showInfo.shippingFormText &&*/}
                {/*                        <div className="col col-12 a" style={{ fontSize: "1.50rem", fontWeight: 'bold' }}>*/}
                {/*                            <div onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_Warehouse_Shipment_Order.pdf', this.state.showInfo.shippingForm)}>Warehouse Shipment Information</div>*/}
                {/*                        </div>*/}
                {/*                    }*/}

                {/*                    {this.state.showInfo.freightFormText &&*/}
                {/*                        <div className="col col-12 a" style={{ fontSize: "1.50rem", fontWeight: 'bold' }}>*/}
                {/*                            <div onClick={(name, blob) => this.download(this.state.showInfo.name.replace(' ', '_') + '_YRC_Freight_Discount.pdf', this.state.showInfo.freightForm)}>YRC Freight Discount Form (PDF)</div>*/}
                {/*                        </div>*/}
                {/*                    }*/}

                {/*                    <div className="col col-12 pb-5">*/}
                {/*                        <a href="https://www.yrc.com/" target="_blank" rel="noopener noreferrer">Plan your shipment at YRC.com</a>*/}
                {/*                    </div>*/}
                {/*                        <div className="col col-12 pb-5">*/}
                {/*                            If you wish to verify receipt of your shipment, please contact exhibitor services*/}
                {/*                        at <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.exhServicesPhone}</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="col col-12 pb-5">*/}
                {/*                        The exhibit hall does not contain storage facilities, nor will it have personnel on hand to unload shipments until&nbsp;*/}
                {/*                        <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.setupDay1Date}</span> <b>after 12:00 p.m. and before 6:00 p.m. There will be a charge for shipments delivered to showsite prior */}
                {/*                        to 12:00 p.m.</b> If you plan to ship by common carrier or deliver with your own truck, please plan accordingly.*/}

                {/*                        The exhibit hall does not contain storage facilities, nor will it have personnel on hand to unload shipments until&nbsp;*/}
                {/*                        <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.setupDay1Date}</span> <b>8:00 a.m.- 6:00 p.m. There will be a charge for shipments delivered to show site prior to exhibitor set-up day.</b>&nbsp;*/}
                {/*                        If you plan to ship by common carrier or deliver with your own truck, please plan accordingly.*/}
                {/*                    </div>*/}
                {/*                    <div className="col col-12 pb-5">*/}
                {/*                        <b>REMINDER:<br />*/}
                {/*                        UP TO 30 DAYS IN ADVANCE</b>, shipments may be consigned to the warehouse. <b>Please ship to arrive by <span style={{color: "blue"}}>{this.state.showInfo.arriveByDate}</span> This gives */}
                {/*                        you a chance to check delivery prior to arriving at the show.</b>*/}
                {/*                    </div>*/}
                {/*                    <div className="col col-12 pb-5">*/}
                {/*                        <span style={{ color: "red" }}>*/}
                {/*                            Due to an increase in shipping problems and delays throughout the country, Design-2-Part strongly recommends all exhibitors ship to the dedicated advance warehouse.*/}
                {/*                            This will help ensure that exhibit materials arrive on time and that you have everything you need at the show.*/}
                {/*                        </span>*/}
                {/*                    </div>*/}

                {/*                </div>*/}
                {/*                <div className="text-left col col-6">*/}
                {/*                    <b>WAREHOUSE ADDRESS:</b><br />*/}
                {/*                    {this.state.showInfo.warehouseName}<br />*/}
                {/*                    {this.state.showInfo.warehouseFreightCo}<br />*/}
                {/*                    {this.state.showInfo.warehouseStreet1}<br />*/}
                {/*                    {this.state.showInfo.warehouseStreet2}<br />*/}
                {/*                    {this.state.showInfo.warehouseCityStateZip}<br />*/}
                {/*                </div>*/}

                {/*                <div className="text-left col col-6">*/}
                {/*                    <b>DIRECT TO SHOWSITE:</b><br />*/}
                {/*                    Ship To Arrive: <span style={{ color: "red" }}>BETWEEN 8:00 AM - 6:00 PM ON SET-UP DAY</span><br />*/}
                {/*                    {this.state.showInfo.location}<br />*/}
                {/*                    Design-2-Part Show<br />*/}
                {/*                    {this.state.showInfo.street1}<br />*/}
                {/*                    {this.state.showInfo.city},  {this.state.showInfo.state}  {this.state.showInfo.zip}<br />*/}
                {/*                    <span className="a">*/}
                {/*                        {this.state.showInfo.freightFormText &&*/}
                {/*                            <div onClick={(name, blob) => this.download(this.state.showIndicator + '_Shipping Labels.pdf', this.state.showInfo.freightForm)}><u>Download Shipping Labels</u></div>*/}
                {/*                        }*/}
                {/*                    </span>*/}
                {/*                    <br />*/}
                {/*                </div>*/}

                {/*                <div className="text-left col col-6">*/}
                {/*                    <b>For:</b><br />*/}
                {/*                    {Globals.userInfo.companyName}<br />*/}
                {/*                    Booth # {this.state.showDetails.booth1}*/}
                {/*                </div>*/}
                {/*                <div className="text-left col col-6">*/}
                {/*                    <b>For:</b><br />*/}
                {/*                    {Globals.userInfo.companyName}<br />*/}
                {/*                    Booth # {this.state.showDetails.booth1}*/}
                {/*                </div>*/}

                                
                {/*                <div className="col col-12 pb-5">*/}
                {/*                    <br /><br />*/}
                {/*                    <b>TEAR DOWN:</b><br />*/}
                {/*                    Dismantling begins at the close of the show on Thursday. No dismantling is allowed prior to this time. Empty crates */}
                {/*                    will be brought to your booth within one hour of show close. If you have a truck scheduled to pick-up your freight, */}
                {/*                    please have them arrive at the convention hall when your crates are packed and ready to be loaded. Upon arrival have */}
                {/*                    the driver check in with the decorator as to whose booth they are picking up (company name & booth number).<br /><br />*/}
                                    	
                {/*                    A freight carrier will be available with bill of ladings and shipping labels at the Hall by 12:30 pm on <span style={{ color: "blue", fontWeight: "bold" }}>{this.state.showInfo.freightCoOnHand}</span>*/}
                {/*                </div>*/}
                {/*        </div>*/}
                {/*            :*/}
                {/*            <p>You'll be notified by email when shipping information is made available.</p>*/}
                {/*        }*/}
                        
                {/*    </div>*/}

                {/*    <div className="modal-footer">*/}
                {/*        <div className="form-group col-12 padding-25-10">*/}
                {/*            <button className="btn btn-primary btn-lg margin-left-15" onClick={() => this.setState({ showSWPane: false })}>Close</button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</SlidingPane>*/}

                <SlidingPane
                    className='some-custom-class'
                    overlayClassName='showCard'
                    isOpen={this.state.showEmailScamPane}
                    title='Exhibitor Warning'
                    onRequestClose={() => this.setState({ showEmailScamPane: false })}
                >
                    <div className="slide-pane-body scrollableDiv line-height-1-5">
                        <div className="row pb-5">
                            <h3 className="text-center">Exhibitor Email Scam Warning</h3>
                            Design-2-Part has seen a recent increase in scam emails claiming to represent, or be affiliated with, our organization. The most common claim is they have access to
                            our attendee emails from various shows and in some cases, the solicitations include our company logo.<br /><br />

                            <b>For reference, D2P attendee emails and direct mail addresses are never sold on a per show basis and would ONLY BE AVAILABLE THROUGH D2P directly.</b>
                        </div>
                        <div className="row">
                            General Email Solicitation Guidelines and Red Flags:
                        </div>
                        <div className="row pb-5">
                            <ul>
                                <li><b>Always double check with parent companies to confirm they are behind an offer.</b></li>
                                <li>Check if the address matches the name of the sender and whether the domain of the company is correct.</li>
                                <li>Check for spelling errors in your name and the sender's name.</li>
                                <li>Do not respond to these emails (even to "unsubscribe"). It oftentimes confirms to them they have reached a valid email address.</li>
                            </ul>
                        </div>

                        <div className="row pb-5">
                            If you ever suspect an email offer from Design-2-Part to be fraudulent, just delete
                            or email your sales representative directly with questions. Our sales team can be
                            reached at 800-225-4535.
                        </div>

                        <div className="row pb-5">
                            Sincerely,<br />
                            Chris Davis<br />
                            V. P. Tradeshows
                        </div>
                    </div>

                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-primary btn-lg margin-left-15" onClick={() => this.setState({ showEmailScamPane: false })}>Close</button>
                        </div>
                    </div>
                </SlidingPane>
            </Card>
        );
    }
}