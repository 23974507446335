import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './login.css';
import logo from '../../img/D2P-logo-show-login-sm.png';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import cookie from 'react-cookies';
import TFASetup from "./TFASetup";
import TFALogin from "./TFALogin";
import ReactLoading from 'react-loading';

import { smallBox } from "../../common/utils/functions";

import { Modal } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ForgotPassword from './ForgotPassword';
import ContactForm from './ContactForm';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            validUserFound: true,
            mode: "noShowTwoFactor",
            formFields: {
                userid: '',
                password: ''
            },
            showToast: false,
            toastContent: {
                title: '',
                content: ''
            },
            loading: false,
            showSelectModal: false,
            locationList: []
        }

        Globals.userInfo = {};
        Globals.enrolledShows = [];

        window.sessionStorage.setItem("userInfo", JSON.stringify(Globals.userInfo));
        window.sessionStorage.setItem("enrolledShows", JSON.stringify(Globals.enrolledShows));
    }

    goLogin = () => {
        this.setState({
            mode: "noShowTwoFactor",
        });
    }

    toggleToast = (toastContent) => {
        if (toastContent) {
            this.setState({
                showToast: !this.state.showToast,
                toastContent
            });
        } else {
            this.setState({
                showToast: !this.state.showToast
            });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit = (event) => {

        event.preventDefault();

        //let path = '/exhibitor-home';
        //this.props.history.replace(path);

        let myuserid = this.state.formFields.userid;
        let mypwd = this.state.formFields.password;

        if (myuserid === null || myuserid === "") {
            document.getElementById("userid").focus();
            return;
        }
        if (mypwd === null || mypwd === "") {
            document.getElementById("password").focus();
            return;
        }
        this.setState({
            loading: true
        });

        // ***** First check to see if this is a D2P Admin user *****
        if ((myuserid.toUpperCase() == "ADMIN" && mypwd.toUpperCase() == "PROSPECT16") || (myuserid.toUpperCase() == "LRADMIN" && mypwd.toUpperCase() == "PROSPECT16") || (myuserid.toUpperCase() == "SYSADMIN" && mypwd.toUpperCase() == "PROSPECT16")) {
            // ***** Validate user against db table *****
            let commandresult = "";

            let postdata = {};
            postdata.userId = this.state.formFields.userid;
            postdata.password = this.state.formFields.password;

            Axios.post(`/api/adminToken`, postdata
            ).then(response => {
                commandresult = response.data;

                // ***** If no limited scope bearer token returned, the user credentials were invalid *****
                if (commandresult === "") {
                    this.setState({ validUserFound: false, loading: false, });

                    smallBox({
                        title: "Notice",
                        content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
                        color: "#C46A69",
                        iconSmall: "fa fa-times fa-2x fadeInRight animated",
                        timeout: 4000
                    });

                    return;
                } else {

                    // set limited scope bearer token where Axios knows where to find it
                    let userInfo = Globals.userInfo;

                    if (myuserid.toUpperCase() == "ADMIN") {
                        Globals.adminMode = "ADMIN";
                        window.sessionStorage.setItem("adminMode", Globals.adminMode);

                        userInfo.bearer = commandresult.token;
                        userInfo.companyId = 3547;
                        userInfo.userId = "support@myjobshopshow.com";
                        userInfo.username = "Admin";
                        userInfo.fullName = "D2P Administrator";
                        userInfo.userLocations = [];
                        Globals.userInfo = userInfo;

                        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                        Axios.get(`/api/GetAdminCompanyInfo`, {
                            params: {
                                id: 3547
                            }
                        }).then(response => {

                            userInfo.companyName = response.data[0].customer.name;

                            userInfo.customerInfo = response.data[0].customer;
                            userInfo.isAdmin = true;

                            Globals.userInfo = userInfo;
                            Globals.isImpersonating = false;

                            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                            //Save in case impersonation mode is chosen
                            Globals.realUserInfo = userInfo;
                            window.sessionStorage.setItem("realUserInfo", JSON.stringify(userInfo));

                            Axios.get(`/api/GetEnrolledShowsByCompanyId`, {
                                params: {
                                    id: userInfo.companyId.toString()
                                }
                            }).then(response => {
                                let enrolledShows = Globals.enrolledShows;
                                enrolledShows = response.data;

                                Globals.enrolledShows = enrolledShows;
                                window.sessionStorage.setItem("enrolledShows", JSON.stringify(enrolledShows));

                                let path = '/exhibitor-home';

                                if (Globals.pathname !== '/') {
                                    path = Globals.pathname;
                                }

                                this.props.history.replace(path);
                            }).catch(error => {
                                console.log(error);
                            });

                        }).catch(error => {
                            console.log(error);
                        });
                    }
                    else if (myuserid.toUpperCase() == "LRADMIN") {
                        Globals.adminMode = "LRADMIN";
                        window.sessionStorage.setItem("adminMode", Globals.adminMode);

                        userInfo.bearer = commandresult.token;
                        userInfo.companyId = 3547;
                        userInfo.userId = "support@myjobshopshow.com";
                        userInfo.username = "LRAdmin";
                        userInfo.isAdmin = true;
                        userInfo.companyName = "Lead Retrieval Administrator";
                        userInfo.fullName = "D2P Lead Retrieval Administrator";
                        
                        Globals.userInfo = userInfo;
                        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                        Globals.isImpersonating = false;

                        let path = '/show-leads-admin';
                        this.props.history.replace(path);
                    }
                    else {
                        Globals.adminMode = "SYSADMIN";
                        window.sessionStorage.setItem("adminMode", Globals.adminMode);

                        userInfo.bearer = commandresult.token;
                        userInfo.companyId = 3547;
                        userInfo.userId = "support@myjobshopshow.com";
                        userInfo.username = "SYSAdmin";
                        userInfo.isAdmin = true;
                        userInfo.companyName = "D2P System Administrator";
                        userInfo.fullName = "D2P System Administrator";
                        userInfo.userLocations = [];
                        Globals.userInfo = userInfo;
                        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                        Globals.isImpersonating = false;

                        let path = '/exhibitor-home';
                        this.props.history.replace(path);
                    }
                }
            }).catch(error => {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });

                let formFields = {
                    userid: '',
                    password: ''
                };

                this.setState({
                    formFields,
                    loading: false
                });
            });

            

        } else {

            // ***** Validate user against db table *****
            let commandresult = "";

            let postdata = {};
            postdata.userId = this.state.formFields.userid;
            postdata.password = this.state.formFields.password;

            Axios.post(`/api/token`, postdata
                ).then(response => {
                    commandresult = response.data;

                    // ***** If no limited scope bearer token returned, the user credentials were invalid *****
                    if (commandresult === "") {
                        this.setState({ validUserFound: false, loading: false, });

                        smallBox({
                            title: "Notice",
                            content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
                            color: "#C46A69",
                            iconSmall: "fa fa-times fa-2x fadeInRight animated",
                            timeout: 4000
                        });

                        return;
                    } else {
                    
                        // set limited scope bearer token where Axios knows where to find it
                        let userInfo = Globals.userInfo;
                        userInfo.bearer = commandresult.token;
                        userInfo.customerInfo = commandresult.list[0].customer;
                        userInfo.companyId = commandresult.list[0].contact.company_id;
                        userInfo.companyName = commandresult.list[0].customer.name;
                        userInfo.username = this.state.formFields.userid;
                        userInfo.fullName = commandresult.list[0].contact.jsS_name;
                        userInfo.userId = this.state.formFields.userid;
                        userInfo.isAdmin = false;
                        userInfo.userLocations = commandresult.list;

                        Globals.userInfo = userInfo;
                        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                        Globals.isImpersonating = false;
                        window.sessionStorage.setItem("isImpersonating", "");

                        Globals.adminMode = "";
                        window.sessionStorage.setItem("adminMode", "");

                        Axios.get(`/api/GetEnrolledShowsByCompanyId`, {
                            params: {
                                id: userInfo.companyId.toString()
                            }
                        }).then(response => {
                            let enrolledShows = Globals.enrolledShows;

                            enrolledShows = response.data;
                            Globals.enrolledShows = enrolledShows;
                            window.sessionStorage.setItem("enrolledShows", JSON.stringify(enrolledShows));
                            let path = '/exhibitor-home';
                            this.props.history.replace(path);
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                }).catch(error => {
                    console.log('here in catch');
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Invalid User Credentials.</i>",
                    color: "#C46A69",
                    iconSmall: "fa fa-times fa-2x fadeInRight animated",
                    timeout: 4000
                });

                let formFields = {
                    userid: '',
                    password: ''
                };

                this.setState({
                    formFields,
                    loading: false
                });
            });
        }
    }

    validateUser = async () => {
        let commandresult = "";

        let postdata = {};
        postdata.userid = this.state.formFields.userid;
        postdata.password = this.state.formFields.password;

        await Axios.post(`/api/token`, postdata
        ).then(response => {    
            commandresult = response.data;

            let userInfo = { ...Globals.userInfo };
            userInfo.bearer = commandresult.token;
            userInfo.userId = commandresult.userID;
            userInfo.username = commandresult.username;
            userInfo.fullName = commandresult.fName + " " + commandresult.lName;
            userInfo.isLoggedIn = true;
            Globals.userInfo = userInfo;

            let pdata = {
                uniqueID: commandresult.userID
            }
        }).catch(error => {
            console.log(error);
        });
    }

    forgotPasswordClick = () => {
        this.setState({
            mode: 'forgotPassword'
        });
    }

    goHome = () => {
        this.setState({
            mode: 'noShowTwoFactor'
        });
    }

    contactUsClick = () => {
        this.setState({
            mode: 'contactUs'
        });
    }

    render() {

        const columns = [{
            dataField: 'customer.street1',
            text: 'Address'
        }, {
            dataField: 'customer.city',
            text: 'City'
        }, {
            dataField: 'customer.state',
            text: 'State'
        }];

        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            hideSelectColumn: true
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                // set limited scope bearer token where Axios knows where to find it
                let userInfo = Globals.userInfo;
                userInfo.bearer = row.token;
                userInfo.customerInfo = row.customer;
                userInfo.companyId = row.contact.company_id;
                userInfo.companyName = row.customer.name;

                Globals.userInfo = userInfo;
                window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));

                Axios.get(`/api/GetEnrolledShowsByCompanyId`, {
                    params: {
                        id: userInfo.companyId.toString()
                    }
                }).then(response => {
                    let enrolledShows = Globals.enrolledShows;

                    enrolledShows = response.data;

                    Globals.enrolledShows = enrolledShows;
                    window.sessionStorage.setItem("enrolledShows", JSON.stringify(enrolledShows));

                    let path = '/exhibitor-home';
                    this.props.history.replace(path);
                }).catch(error => {
                    console.log(error);
                });
            }
        };

        return (
            <div className="background">
                <div className="blankpage-form-field">
                    <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                        <img src={logo} height="30px" alt="Design-2-Part Shows My Show Online" aria-roledescription="logo" />
                        <div><div className="page-logo-text-header mr-1">MY SHOW ONLINE</div><div className="page-logo-text mr-1">Your D2P Customer Service Center</div></div>
                    </div>
                    <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">

                        {this.state.mode === "showTwoFactor" &&
                            <TFALogin validateUser={this.validateUser} goLogin={this.goLogin} userid={this.state.formFields.userid} password={this.state.formFields.password} />
                        }
                        {this.state.mode === "showTwoFactorSetUp" &&
                            <TFASetup validateUser={this.validateUser} goLogin={this.goLogin} userid={this.state.formFields.userid} password={this.state.formFields.password} />
                        }
                        {this.state.mode === "noShowTwoFactor" &&


                        <form>
                            <div className="form-group">
                                <label className="form-label" htmlFor="userid">Username</label>
                                <input type="text" id="userid" className="form-control-custom" name="userid" value={this.state.formFields.userid} onChange={this.handleInputChange} />
                                <span className="help-block">
                                    Your username
                                </span>
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="password">Password</label>
                                <input type="password" id="password" name="password" value={this.state.formFields.password} onChange={this.handleInputChange} className="form-control-custom" />
                                <span className="help-block">
                                    Your password
                                </span>
                            </div>
                            {this.state.loading &&
                                <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#275282'} height={30} width={30} />
                            }
                            <button className="btn btn-default float-right" onClick={this.handleSubmit}>Login</button>
                        </form>
                        }

                        {this.state.mode === 'forgotPassword' &&
                            <ForgotPassword goHome={this.goHome} />
                        }

                        {this.state.mode === 'contactUs' &&
                            <ContactForm goHome={this.goHome} />
                        }

                    </div>

                    <div className="blankpage-footer text-center row">
                        {this.state.mode === 'forgotPassword' ?
                            <div className="myshowonlineTextA col-6 align-items-right a" onClick={this.goHome}><strong>Go Back To Login</strong></div>
                            :
                            <div className="myshowonlineTextA col-6 align-items-right a" onClick={this.forgotPasswordClick}><strong>Create/Reset Password</strong></div>
                        }

                        {this.state.mode === 'contactUs' ?
                            <div className="myshowonlineTextA col-6 align-items-right a" onClick={this.goHome}><strong>Go Back To Login</strong></div>
                            :
                            <div className="myshowonlineTextA col-6 align-items-left a" onClick={this.contactUsClick}><strong>Contact Us</strong></div>
                        }

                        &nbsp;&nbsp;&nbsp;
                    </div>
                    
                    {/*<div className="blankpage-footer text-center row">*/}
                    {/*    {this.state.mode === 'contactUs' ?*/}
                    {/*        <div className="myshowonlineTextA col-12 align-items-right a" onClick={this.goHome}><strong>Go Back To Login</strong></div>*/}
                    {/*        :*/}
                    {/*        <div className="myshowonlineTextA col-12 align-items-left a" onClick={this.contactUsClick}><strong>Contact Us</strong></div>*/}
                    {/*    }*/}
                    {/*</div>*/}

                </div>
                {this.state.locationList.length > 0 &&
                    <Modal size='lg' show={this.state.showSelectModal} onHide={() => this.setState({ showSelectModal: false })} backdrop='static' className="modal-popup">
                        <Modal.Header>
                            <i className="fas fa-exclamation-circle color-blue fa-15x mr-3"></i>
                        <Modal.Title>{this.state.locationList[0].customer.name}
                            <h4>Select A Location To Work With</h4>
                        </Modal.Title>
                            <a className="alignRight close" id="closeModal" onClick={() => this.setState({ showSelectModal: false })}>&times;</a>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="panel-content">
                            <BootstrapTable keyField='patientExamModel.patientExamID'
                                data={this.state.locationList}
                                columns={columns}
                                rowEvents={rowEvents}
                                selectRow={selectRow}
                                hover condensed striped />
                        </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" onClick={() => this.setState({ showSelectModal: false })} className="btn btn-submit">
                                OK
                        </button>

                        </Modal.Footer>
                    </Modal>
                }
            </div>
        );
    }
}

export default withRouter(Login);
