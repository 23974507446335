import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import ExhibitorSearch from './ExhibitorSearch';
import QRCode from 'qrcode.react';
import { toast, confirm } from '@rickylandino/react-messages';
import logo from '../../img/D2P-logo-show-login-sm.png';

function QRCodes(props) {
    const [state, setState] = useState({
        qrcode1: '',
        qrcode2: '',
        qrcode3: '',
        qrcode4: '',
        showsList: [],
        showcode: '375',
        showlocation: 'ONTARIO, CA'
    });

    useEffect(() => {
        //Axios.get(`/api/GetD2PAppInformation`
        //).then(response => {
        //    var showsList = response.data.shows;

        //    SetQRCodeText(showsList);

        //}).catch(error => {
        //    console.log(error);
        //});

        SetQRCodeText();
        
    }, []);

    const EncryptQRCodeText = (myQRCodeText) => {
        let EncryptedQRCodeText = '';

        for (let i = 0; i < myQRCodeText.length; i++) {
            EncryptedQRCodeText += String.fromCharCode(myQRCodeText.charCodeAt(i) + 1);
        }

        return EncryptedQRCodeText;
    }

    function SetQRCodeText() {
        //var myFirstActivatedShowCode;
        //var myFirstActivatedShowLocation;

        //for (var i = 0; i < showsList.length; i++) {
        //    if (showsList[i].active === true) {
        //        myFirstActivatedShowCode = showsList[i].showcode;
        //        myFirstActivatedShowLocation = showsList[i].showlocation;

        //        break;
        //    }
        //}

        var myQRCodeText1 = EncryptQRCodeText("ActivateD2PShow|" + state.showcode + "|" + state.showlocation + "|3547|DESIGN-2-PART MAGAZINE");
        var myQRCodeText2 = EncryptQRCodeText("xxD2P|" + state.showcode + "|JOHN|SMITH|SMITH & ASSOCIATES|BOSTON|MA|ENGINEER|JSMITH@ACMECORPORATION.COM|C|R|617.555.1212|17");
        var myQRCodeText3 = EncryptQRCodeText("xxD2P|" + state.showcode + "|MARY|JONES|JONES CONSULTING SERVICES|HARTFORD|CT|ENGINEER|MJONES@JONESCONSULTING.COM|C|R|912.555.6382|17");
        var myQRCodeText4 = EncryptQRCodeText("xxD2P|" + state.showcode + "|DAVE|THOMPSON|NORTHERN AVIATION|MINNEAPOLIS|MN|PRESIDENT|DAVE@NORTHERNAVIATION.COM|A|F|952.555.6513|54");

        setState({
            ...state,
            qrcode1: myQRCodeText1,
            qrcode2: myQRCodeText2,
            qrcode3: myQRCodeText3,
            qrcode4: myQRCodeText4
        });
    }

    return (
        <div>

            <div className="container">
                <div className="row" style={{ paddingTop: '100px' }}>
                    <div className="col col-lg-12 col-12 text-center">
                        <img src={logo} alt="Design-2-Part Shows My Show Online" />
                    </div>
                </div>
                <div className="row" style={{paddingTop: '100px'}}>
                    <div className="col col-lg-6 col-12 text-center">
                        <QRCode value={state.qrcode1} /><br />
                        <label className="form-label mt-1">ACTIVATE {state.showlocation} SHOW</label>
                    </div>
                    <div className="col col-lg-6 col-12 text-center">
                        <QRCode value={state.qrcode2} /><br />
                        <label className="form-label mt-1">SAMPLE ATTENDEE 1 - JOHN SMITH</label>              
                    </div>
                </div>
                <div className="row" style={{ paddingTop: '100px' }}>
                    <div className="col col-lg-6 col-12 text-center">
                        <QRCode value={state.qrcode3} /><br />
                        <label className="form-label mt-1">SAMPLE ATTENDEE 2 - MARY JONES</label>
                    </div>
                    <div className="col col-lg-6 col-12 text-center">
                        <QRCode value={state.qrcode4} /><br />
                        <label className="form-label mt-1">SAMPLE ATTENDEE 3 - DAVE THOMPSON</label>
                    </div>
                </div>
            </div>

            {/*<div className="blankpage-form-field">*/}
            {/*    <div className="align-items-center justify-content-center">*/}
            {/*        <div className="row">*/}
            {/*            <div className="form-group col-lg-6 block-example border border-dark">*/}
            {/*                <QRCode value={state.qrcode1} />*/}
            {/*            </div>*/}
            {/*            <div className="form-group col-lg-6 block-example border border-dark">*/}
            {/*                <QRCode value={state.qrcode2} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        //<div className="border d-flex align-items-center justify-content-center" style={{ "height": "100%" }}>

        //</div>
    );
}

export default withRouter(QRCodes);