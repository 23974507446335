import React, { Component, Fragment } from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router';
import Layout from './components/Layout';
import Globals from '../src/config/globals';
import Login from './components/Auth/Login';
import ExhibitorHome from './components/Exhibitors/ExhibitorHome';
import ProgramListing from './components/Exhibitors/ProgramListing';
import ExhibitorScamWarning from './components/Exhibitors/ExhibitorScamWarning';
import ShowDashboard from './components/Shows/ShowDashboard';
import ManageD2PAppContent from './components/ShowLeadsAdmin/ManageD2PAppContent';
import ShowLeadsAdmin from './components/ShowLeadsAdmin/ShowLeadsAdmin';
import ManageMyLeads from './components/ShowLeadsAdmin/ManageMyLeads';
import QRCodes from './components/ShowLeadsAdmin/QRCodes';
import ResetPassword from './components/Auth/ResetPassword';
import ManagementDashboard from './components/Admin/ManagementDashboard';

class App extends Component {

    constructor(props) {
        super(props);

        if (window.sessionStorage.getItem("adminMode")) {
            Globals.adminMode = (window.sessionStorage.getItem("adminMode"));
        }

        if (window.sessionStorage.getItem("userInfo")) {
            Globals.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
        }

        if (window.sessionStorage.getItem("enrolledShows")) {
            Globals.enrolledShows = JSON.parse(window.sessionStorage.getItem("enrolledShows"));
            if (Globals.enrolledShows?.length > 0) {
                Globals.defaultShowCode = Globals.enrolledShows[0].showDetail.showcode.toString();
            }
        }

        this.state = {
            updateOrder: false
        }
    }

    componentDidMount() {
        if (!Globals.userInfo?.userId && this.props.locaion?.pathname !== '/') {
            Globals.pathname = this.props.location.pathname;
        } else {
            Globals.pathname = '/';
        }
    }

    updateOrder = () => {
        this.setState({
            updateOrder: true
        }, () => {
                this.setState({
                    updateOrder: false
                });
        })
    }

    render() {
        return (
            <div className="">

                {/* If user is not LR Admin and they try to hit a LR Admin page, redirect them to the /exhibitor-home page */}
                {Globals.userInfo.userId && (this.props.location.pathname === '/lead-retrieval-admin' || this.props.location.pathname === '/show-leads-admin') && Globals.adminMode !== "LRADMIN" &&
                    <Redirect to='/exhibitor-home' />
                }

                {/* If user the LR Admin and they try to hit a non LR Admin page, redirect them to the /lead-retrieval-admin page */}
                {Globals.userInfo.userId && (this.props.location.pathname === '/exhibitor-home' || this.props.location.pathname === '/program-listing' || this.props.location.pathname === '/show-dashboard' || this.props.location.pathname === '/exhibitor-scam-warning' || this.props.location.pathname === '/lead-retrieval-admin' || this.props.location.pathname === '/show-leads-admin') && Globals.adminMode === "LRADMIN" &&
                    <Redirect to='/lead-retrieval-admin' />
                }

                {
                    this.props.location.pathname === '/' || this.props.location.pathname.includes('reset-password') || this.props.location.pathname.includes('qrcodes') ? (
                        <div>
                            <Route exact path='/' component={Login} />
                            <Route exact path='/qrcodes' component={QRCodes} />
                            <Route path="/reset-password/:id/:date" component={ResetPassword} />
                        </div>
                    ) : (Globals.userInfo.userId) ? (

                        <Layout updateMembersLoading={this.updateMembersLoading} updateMembers={this.updateMembers} enrolledShows={Globals.enrolledShows} updateOrder={this.updateOrder}>
                            <Fragment>
                                <Route path='/exhibitor-home' render={(props) => <ExhibitorHome userInfo={Globals.userInfo} />} />
                                <Route path='/program-listing' render={(props) => <ProgramListing />} />
                                <Route path='/show-dashboard' render={(props) => <ShowDashboard {...this.props} updateOrder={this.state.updateOrder} />} />
                                <Route path='/exhibitor-scam-warning' render={(props) => <ExhibitorScamWarning />} />
                                <Route path='/manage-my-leads' render={(props) => <ManageMyLeads userInfo={Globals.userInfo} />} />
                                <Route path='/show-leads-admin' render={(props) => <ShowLeadsAdmin />} />
                                <Route path='/lead-retrieval-admin' render={(props) => <ManageD2PAppContent />} />
                                <Route path='/reset-password' render={(props) => <ResetPassword />} />
                                <Route path='/management-dashboard' render={(props) => <ManagementDashboard />} />
                            </Fragment>
                        </Layout>)
                        :
                        <Redirect to='/' />
                }
            </div>
        );
    }
}

export default withRouter(App);
