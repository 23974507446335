import React from 'react';
import { Toast } from 'react-bootstrap';

import { FaCheck } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

export function MyToast(props) {
    return (
        <div>
            <Toast
                show={props.showToast}
                onClose={props.toggleToast}
                delay={5000}
                autohide
                style={{
                position: 'absolute',
                top: 0,
                right: 0,
            }}>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded mr-2"
                        alt=""
                    />
                    <strong className="mr-auto">{props.toastContent.title}</strong>
                </Toast.Header>
                <Toast.Body>
                    <div className="row">
                        <div className="col-2">
                            {props.toastContent.title === 'Success' &&
                                <FaCheck className="checkmark" />
                            }
                            {props.toastContent.title !== 'Success' &&
                                <MdCancel className="cancel" />
                            }
                            
                        </div>
                        <div className="col-10">
                            {props.toastContent.content}
                        </div>
                    </div>
                </Toast.Body>
            </Toast>
        </div>
    );
}