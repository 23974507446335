import React, { Component, Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import '../../../css/card.css';
import Axios from '../../../config/axios';
import Globals from '../../../config/globals';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { smallBox, SmartMessageBox } from "../../../common/utils/functions";
import spinnerLarge from '../../../img/spinnerLarge.gif';
import NumberFormat from 'react-number-format';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import * as ShowSliders from '../ShowSliders/index';

import statesList from '../../Helpers/StatesList';

import Moment from 'moment';

export class SuppliedServices extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showInfoLoaded !== prevState.showInfoLoaded) {
            return {
                showInfoLoaded: nextProps.showInfoLoaded
            };
        }
        if (nextProps.showInfo.name !== prevState.showInfo.name && nextProps.showInfo.show_abbrev !== prevState.showInfo.show_abbrev) {
            //console.log('test');
            return { showInfo: nextProps.showInfo, formFields: nextProps.showInfo }
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showInfo: this.props.showInfo,
            showDetails: this.props.showDetails,
            showInfoLoaded: this.props.showInfoLoaded,
            formFields: {},
            showPane: false,
            showIPSlider: false,
            showBFPane: false,
            showBFSlider: false,
            showSignsPane: false,
            showSignsSlider: false,
            showRepBoardPane: false,
            states: statesList,
            orderAlreadySubmitted: false,
            badgeList: [],
            deletedBadgeList: [],
            selectedBadge: {},
            badgeIdx: null,
            selected: [],
            selectedBadges: [],
            checkedExistingOrder: false,
            processingSubmitOrder: false,
            totalBooths: 0,
            maxTables: 0,
            boothType: ''
        }

        this.isDirty = false;
    }

    componentDidMount() {
        this.checkExistingOrder();
        this.getBadgesByCompany();
        this.getBadgesByShow();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showInfoLoaded && this.props.tempCheckOrderStatus) {
            this.setState({
                orderAlreadySubmitted: false,
                showInfo: this.props.showInfo,
                showDetails: this.props.showDetails,
                showInfoLoaded: this.props.showInfoLoaded,
                formFields: {}
            });

            this.getInitialShowFurnishings();
            this.checkExistingOrder();
            this.getBadgesByShow(); this.getBadgesByCompany();
        }

        if (this.props.showInfo.name !== prevState.showInfo.name && this.props.showInfo.show_abbrev !== prevState.showInfo.show_abbrev) {
            this.getInitialShowFurnishings();
            this.checkExistingOrder();
            this.getBadgesByShow();
            this.getBadgesByCompany();
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } }, () => {
            this.isDirty = true;
        });
    }

    triggerError = (message) => {
        smallBox({
            title: "Notice",
            content: "<i class='fa fa-clock-o'></i> <i>" + message + "</i> ",
            color: "#C46A69",
            iconSmall: "fa fa-times fa-2x fadeInRight animated",
            timeout: 7000
        });
    }

    hidePane = () => {

        if (this.isDirty) {
            SmartMessageBox(
                {
                    title: "You are about to lose your changes.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: "[No][Yes]"
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        this.isDirty = false;
                        this.setState({
                            showPane: false,
                            formFields: {},
                            deletedBadgeList: [],
                        }, () => { this.checkExistingOrder(); this.getBadgesByShow(); this.getBadgesByCompany(); });
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            this.setState({
                showPane: false,
                formFields: {},
                deletedBadgeList: [],
            }, () => { this.checkExistingOrder(); this.getBadgesByShow(); this.getBadgesByCompany(); });
        }

        
    }

    getBadgesByShow = () => {
        Axios.get(`/api/GetBadgesByShow`, {
            params: {
                showcode: this.props.location?.state?.showcode || Globals.defaultShowCode,
                id: this.state.showDetails.id
            }
        }).then(response => {
                this.setState({
                    selectedBadges: response.data
                });
        }).catch(error => {
            console.log(error);
        });
    }

    getBadgesByCompany = () => {
        Axios.get(`/api/GetBadgesByCompany`, {
            params: {
                id: this.state.showDetails.id.toString()
            }
        }).then(response => {
            this.setState({
                badgeList: response.data
            });
            
        }).catch(error => {
            console.log(error);
        });
    }

    checkExistingOrder = () => {
        Axios.get(`/api/CheckExistingOrder`, {
            params: {
                showcode: this.props.location?.state?.showcode || Globals.defaultShowCode,
                id: this.state.showDetails.id
            }
        }).then(response => {
            if (response.data) {
                //console.log(response.data);
                this.setState({
                    checkedExistingOrder: true,
                    formFields: response.data,
                    orderAlreadySubmitted: true
                });
            } else {
                this.setState({
                    checkedExistingOrder: true,
                    orderAlreadySubmitted: false
                });
                this.getInitialShowFurnishings();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    getInitialShowFurnishings = () => {
        Axios.get(`/api/GetInitialShowFurnishings`, {
            params: {
                id: this.state.showDetails.id.toString(),
                showcode: this.props.location?.state?.showcode || Globals.defaultShowCode
            }
        }).then(response => {
            if (response.data) {
                let formFields = response.data;
                formFields.needTables = (formFields.table4 || formFields.table6 || formFields.table8) ? 'yes' : 'no';
                formFields.needTTCovers = (formFields.blackTop || formFields.whiteTop) ? 'yes' : 'no';
                formFields.needTSkirts = (formFields.blackSkirt || formFields.burgundySkirt || formFields.graySkirt) ? 'yes' : 'no';
                formFields.blackSkirt = formFields.blackSkirt.toString();
                formFields.burgundySkirt = formFields.burgundySkirt.toString();
                formFields.graySkirt = formFields.graySkirt.toString();
                formFields.blackTop = formFields.blackTop.toString();
                formFields.whiteTop = formFields.whiteTop.toString();
                formFields.table4 = formFields.table4.toString();
                formFields.table6 = formFields.table6.toString();
                formFields.table8 = formFields.table8.toString();

                var myTotalBooths = 0;
                var myMaxTables = 0;
                var myBoothType = '';

                if (this.props.showDetails.booth1 !== '' && this.props.showDetails.booth1 !== null) { myTotalBooths += 1; }
                if (this.props.showDetails.booth2 !== '' && this.props.showDetails.booth2 !== null) { myTotalBooths += 1; }
                if (this.props.showDetails.booth3 !== '' && this.props.showDetails.booth3 !== null) { myTotalBooths += 1; }
                if (this.props.showDetails.booth4 !== '' && this.props.showDetails.booth4 !== null) { myTotalBooths += 1; }

                switch (myTotalBooths) {
                    case 0:
                        myMaxTables = 3;
                        myBoothType = 'Single Booth';
                        break;
                    case 1:
                        myMaxTables = 3;
                        myBoothType = 'Single Booth';
                        break;
                    case 2:
                        myMaxTables = 5;
                        myBoothType = 'Double Booth';
                        break;
                    case 3:
                        myMaxTables = 7;
                        myBoothType = 'Triple Booth';
                        break;
                    case 4:
                        myMaxTables = 9;
                        myBoothType = 'Quad Booth';
                        break;
                }

                this.setState({
                    formFields,
                    totalBooths: myTotalBooths,
                    maxTables: myMaxTables,
                    boothType: myBoothType
                });

                console.log(this.state);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    handleSubmit = () => {

        this.setState({
            processingSubmitOrder: true
        });

        var myTablesOrdered = 0;
        var message = '';
        let selectedBadges = this.state.selectedBadges;

        if (!this.state.formFields.needTables) {
            message += 'You must specify if tables are required (Yes or No) <br /> <br />';
        }
        if (this.state.formFields.needTables === 'yes') {

            //table qty validation
            if (this.state.formFields.table4) { myTablesOrdered += parseInt(this.state.formFields.table4); }
            if (this.state.formFields.table6) { myTablesOrdered += parseInt(this.state.formFields.table6); }
            if (this.state.formFields.table8) { myTablesOrdered += parseInt(this.state.formFields.table8); }

            if (myTablesOrdered === 0) {
                message += 'You indicated tables are required but you did not specify quantities <br /> <br />';
            }

            if (myTablesOrdered > this.state.maxTables) {
                message += 'You exceeded the maximum tables allowed for a ' + this.state.boothType + ' (' + this.state.maxTables + ' are allowed) <br /> <br />';
            }

            if (!this.state.formFields.ttops) {
                message += 'You must make a selection for Vinyl Table Tops (White or Black) <br /> <br />';
            }
            if (!this.state.formFields.tskirts) {
                message += 'You must make a selection for table skirts (None, Black, Gray or Royal Blue) <br /> <br />';
            }
        }

        if (this.state.formFields.chkNoSigns === 'yes' && !this.state.formFields.easel1 && !this.state.formFields.easel2 && !this.state.formFields.easel3 && !this.state.formFields.easel4 && !this.state.formFields.easel5 && !this.state.formFields.easel6) {
            message += 'You indicated that signs are required but no content was specified <br /> <br />';
            //this.triggerError(message);
        } if (!this.state.formFields.chkNoSigns) {
            message += 'You must specify if you require signs for your booth (Yes or No) <br /> <br />';
            //this.triggerError(message);
        } if (this.state.formFields.needFreeRepBoard === 'yes' && (!this.state.formFields.rCity || !this.state.formFields.rState || !this.state.formFields.rContact || !this.state.formFields.rServices || !this.state.formFields.rTerritory)) {
            message += 'You indicated that you wanted a free rep board posting, but required fields are missing <br /> <br />';
            //this.triggerError(message);
        } if (!this.state.formFields.needFreeRepBoard) {
            message += 'You must specify if you would like a free rep board posting <br /> <br />';
        } if (this.state.selected.length === 0) {
            message += 'You must select at least one badge for this show <br /> <br />';
        }

        if (message !== '') {
            this.triggerError(message);

            this.setState({
                processingSubmitOrder: false
            });
        } else {
            this.submitOrder(myTablesOrdered);
        }
    }

    submitOrder = (myTablesOrdered) => {
        let vm = {
            onlineOrder: {},
            badgeList: [],
            deletedBadgeList: this.state.deletedBadgeList
        }
        let postdata = {};
        let badgeList = this.state.badgeList;

        let selectedBadges = this.state.selectedBadges;

        for (var i = 0; i < badgeList.length; i++) {
            
            if (this.state.selected.find(selected => selected === badgeList[i].rowID)) {
                badgeList[i].isSelected = true;
                selectedBadges.push(badgeList[i]);
            }
            badgeList[i].rowID = null;
        }

        vm.badgeList = badgeList;

        postdata.showcode = parseInt(this.props.location?.state?.showcode || Globals.defaultShowCode);
        postdata.id = this.state.showDetails.id;
        postdata.chkNoSigns = this.state.formFields.chkNoSigns === 'yes' ? false : true;
        postdata.chkEasel = this.state.formFields.chkNoSigns === 'yes' ? true : false;
        if (this.state.formFields.needTables === 'yes') {
            postdata.table4 = this.state.formFields.table4 || '0';
            postdata.table6 = this.state.formFields.table6 || '0';
            postdata.table8 = this.state.formFields.table8 || '0';

            if (this.state.formFields.ttops === 'white') {
                postdata.whiteTop = myTablesOrdered.toString();
                postdata.blackTop = '0';
            }
            else {
                postdata.whiteTop = '0';
                postdata.blackTop = myTablesOrdered.toString();
            }

            postdata.blackSkirt = '0';
            postdata.graySkirt = '0';
            postdata.burgundySkirt = '0';
            switch (this.state.formFields.tskirts) {
                case 'black':
                    postdata.blackSkirt = myTablesOrdered.toString();
                    break;
                case 'gray':
                    postdata.graySkirt = myTablesOrdered.toString();
                    break;
                case 'royalblue':
                    postdata.burgundySkirt = myTablesOrdered.toString();
                    break;
            }
        }
        else {
            //the fields are hidden but still may have values in them even though exhibitor indicated they do not need tables
            postdata.table4 = '0';
            postdata.table6 = '0';
            postdata.table8 = '0';

            postdata.whiteTop = '0';
            postdata.blackTop = '0';

            postdata.blackSkirt = '0';
            postdata.graySkirt = '0';
            postdata.burgundySkirt = '0';
        }
        
        if (this.state.formFields.chkNoSigns === 'yes') {
            postdata.easel1 = this.state.formFields.easel1 || null;
            postdata.easel2 = this.state.formFields.easel2 || null;
            postdata.easel3 = this.state.formFields.easel3 || null;
            postdata.easel4 = this.state.formFields.easel4 || null;
            postdata.easel5 = this.state.formFields.easel5 || null;
            postdata.easel6 = this.state.formFields.easel6 || null;
        }
        else {
            //the fields are hidden but still may have values in them even though exhibitor indicated they do not need signs
            postdata.easel1 = null;
            postdata.easel2 = null;
            postdata.easel3 = null;
            postdata.easel4 = null;
            postdata.easel5 = null;
            postdata.easel6 = null;
        }

        vm.onlineOrder = postdata;
        vm.company = Globals.userInfo.companyName;
        vm.showName = this.state.showInfo.name;

        if (this.state.formFields.needFreeRepBoard === 'yes') {
            vm.sendRepBoardEmail = true;
            vm.location = (this.state.formFields.rCity && this.state.formFields.rState) ? this.state.formFields.rCity + ", " + this.state.formFields.rState : (this.state.formFields.rCity ? this.state.formFields.rCity : (this.state.formFields.rState ? this.state.formFields.rState : null));
            vm.contact = this.state.formFields.rContact ? this.state.formFields.rContact : null;
            vm.services = this.state.formFields.rServices ? this.state.formFields.rServices : null;
            vm.territory = this.state.formFields.rTerritory ? this.state.formFields.rTerritory : null;
            vm.userId = Globals.userInfo.userId;
        } else {
            vm.sendRepBoardEmail = false;
        }

        Axios.post(`/api/InsertOnlineOrder`, vm
        ).then(response => {
            console.log(response.data);

            if (response.data) {
                smallBox({
                    title: "Notice",
                    content: "<i class='fa fa-clock-o'></i> <i>Your order has been submitted.</i>",
                    color: "#659265",
                    iconSmall: "fa fa-check fa-2x fadeInRight animated",
                    timeout: 4000
                });

                this.checkExistingOrder();
                this.getBadgesByCompany();
                this.getBadgesByShow();

                this.setState({
                    showPane: false,
                    orderAlreadySubmitted: true,
                    processingSubmitOrder: false
                });

                this.isDirty = false;
            }
        }).catch(error => {
            console.log(error);
        });
    }

    updateBadge = (badge, type) => {

        this.isDirty = true;

        let badgeList = this.state.badgeList;

        console.log(this.state.badgeList);
        console.log(this.state.deletedBadgeList);

        if (type === 'new') {
            badge.rowID = (badgeList.length + 1).toString();

            //Auto-select newly added badge
            this.setState(() => ({
                selected: [...this.state.selected, badge.rowID]
            }));

            badgeList.push(badge);
        } else if (type === 'delete') {
            let deletedBadgeList = this.state.deletedBadgeList;
            deletedBadgeList.push(...badgeList.splice(this.state.badgeIdx, 1));

            this.setState({
                deletedBadgeList
            });
        } else {
            badgeList[this.state.badgeIdx] = badge;
        }
        
        this.setState({
            badgeList,
            selectedBadge: {}
        });


    }

    handleOnSelect = (row, isSelect) => {
        this.isDirty = true;

        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.rowID]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.rowID)
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        let selected = [];

        if (isSelect) {
            selected = rows.map(r => (r.rowID));
        }

        this.setState({
            selected
        });
        
    }

    render() {
        let ff = this.state.formFields;
        var tableString = '';
        var skirtsString = '';
        var topsString = '';

        if (this.state.orderAlreadySubmitted) {
            if (this.state.formFields.table4) {
                switch (this.state.formFields.table4) {
                    case 1:
                        tableString += "(1) 4' Table, ";
                        break;
                    default:
                        tableString += "(" + this.state.formFields.table4 + ") 4' Tables, "
                }
            }
            if (this.state.formFields.table6) {
                switch (this.state.formFields.table6) {
                    case 1:
                        tableString += "(1) 6' Table, ";
                        break;
                    default:
                        tableString += "(" + this.state.formFields.table6 + ") 6' Tables, "
                }
            }
            if (this.state.formFields.table8) {
                switch (this.state.formFields.table8) {
                    case 1:
                        tableString += "(1) 8' Table, ";
                        break;
                    default:
                        tableString += "(" + this.state.formFields.table8 + ") 8' Tables, "
                }
            }

            tableString = tableString !== '' ? tableString.slice(0, -2) : 'None';

            if (ff.blackSkirt) 
                skirtsString += ff.blackSkirt === 1 ? "(1) Black Skirt, " : "(" + ff.blackSkirt + ") Black Skirts, ";
            if (ff.graySkirt)
                skirtsString += ff.graySkirt === 1 ? "(1) Gray Skirt, " : "(" + ff.graySkirt + ") Gray Skirts, ";
            if (ff.burgundySkirt)
                skirtsString += ff.burgundySkirt === 1 ? "(1) Royal Blue Skirt, " : "(" + ff.burgundySkirt + ") Royal Blue Skirts, ";

            skirtsString = skirtsString !== '' ? skirtsString.slice(0, -2) : 'None';

            if (ff.whiteTop) 
                topsString += ff.whiteTop === 1 ? "(1) White Top, " : "(" + ff.whiteTop + ") White Tops, ";
            if (ff.blackTop)
                topsString += ff.blackTop === 1 ? "(1) Black Top, " : "(" + ff.blackTop + ") Black Tops, ";

            topsString = topsString !== '' ? topsString.slice(0, -2) : 'None';
        }

        function editFormatter(cell, row) {
            return (
                <i className="fas fa-edit fa-125x color-pink text-center hover" id="editIcon"></i>
            );
        }

        function deleteFormatter(cell, row) {
            return (
                <i className="fas fa-times fa-125x text-danger text-center hover" id="deleteIcon"></i>
            );
        }

        const columns = [{
            dataField: 'badge.exhibitorBadges_ID',
            text: 'exhibitorBadges_ID',
            hidden: true
        }, {
            dataField: 'badge.lName',
            text: 'Last Name'
        }, {
                dataField: 'badge.fName',
            text: 'First Name'
        }, {
                dataField: 'badge.title',
            text: 'Title'
            }, {
                isDummyField: true,
                text: 'Edit',
                formatter: editFormatter
            }, {
                isDummyField: true,
                text: 'Delete',
                formatter: deleteFormatter
            }];

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.tagName === 'I' && e.target.id === 'deleteIcon') {

                    if (this.state.selected.includes(row.rowID)) {
                        this.triggerError('You cannot delete a badge that has been selected for the show. Please unselect badge before deleting a badge from your master list');
                        return;
                    }

                    let postdata = {
                        id: row.badge.shows
                    }
                    Axios.post(`/api/CheckBadgeRegistration`, postdata
                    ).then(response => {
                        if (response.data) {
                            SmartMessageBox(
                                {
                                    title: "WARNING: Deleting this badge will remove this person from all past and future shows.",
                                    content:
                                        "Are you sure you want to delete this badge?",
                                    buttons: "[No][Yes]"
                                },
                                (ButtonPressed) => {
                                    if (ButtonPressed === "Yes") {
                                        this.setState({
                                            selectedBadge: row,
                                            badgeIdx: rowIndex
                                        }, () => { this.updateBadge(row, 'delete'); });

                                    }
                                    if (ButtonPressed === "No") {
                                        return 0;
                                    }
                                }
                            );
                        } else {
                            this.triggerError('Badges have already been ordered for this attendee for an upcoming show.<br /><br />If you still want to delete this badge for this show AND all future shows, please contact Karen C. at 800-225-4535, ext. 138 or <a href=\"mailto:karenc@d2p.com\">karenc@d2p.com</a>')
                        }

                    }).catch(error => {
                        console.log(error);
                    });


                    
                } else if (e.target.tagName === 'I' && e.target.id === 'editIcon') {
                    this.setState({
                        selectedBadge: row,
                        showBadgePane: true,
                        badgeIdx: rowIndex
                    });
                }
                
                
            }
        };

        return (
            <Card className="showCard">
                <Card.Header><span>Supplied Services</span></Card.Header>
                {this.state.showInfo.myJobShopShowManualFlag ?
                    <Fragment>
                        {this.state.checkedExistingOrder &&
                            <Card.Body>

                                {this.state.orderAlreadySubmitted ?
                                    <div className="text-center">
                                        <div><h4>We've received and processed your order for {this.state.showInfo.name}</h4></div>
                                        <p className="a" onClick={() => { this.checkExistingOrder(); this.setState({ showPane: true }) }}>Click Here to View Details</p>
                                    </div>
                                    :
                                    <div className="col col-12 pb-5">
                                        <div className="text-center"><h3>Tables, Exhibitor Badges & Signs</h3></div><br />
                                        <div className="text-center"><button className="btn btn-warning btn-lg" onClick={() => this.setState({ showPane: true })}>Click Here To Order</button></div><br />
                                        <div className="text-center"><b>DEADLINE TO ORDER: <span className="text-danger">{Moment(this.state.showInfo.bfDeadline).format('LL')}</span></b></div><br />
                                        <div className="text-left">
                                            <p><b>PLEASE NOTE:</b> If you do not place your order by the deadline date, we will place a table/badge order using your last order.</p>
                                            <p>Please review your order carefully, once submitted you will not be able to make changes yourself. To make changes, please contact Karen C. at 800-225-4535, ext. 138 or <a href="mailto:karenc@d2p.com">karenc@d2p.com</a></p>
                                        </div>

                                        {/*<div className="text-left">*/}
                                        {/*    <p><b>PLEASE NOTE:</b> If you have previously exhibited at one of our shows, the booth information listed on the next page is what was used. If you wish to duplicate your order, please scroll to the bottom of the page, and click the Submit button.</p>*/}
                                        {/*    <p>Please review your order carefully, once submitted you will not be able to make changes yourself. To make changes, please contact Karen C. at 800-225-4535, ext. 138 or <a href="mailto:karenc@d2p.com">karenc@d2p.com</a></p>*/}
                                        {/*</div>*/}
                                    </div>
                                }
                            </Card.Body>
                        }
                <SlidingPane
                            className='some-custom-class'
                            overlayClassName='showCard'
                            isOpen={this.state.showPane}
                            title={this.state.orderAlreadySubmitted ? 'Your Submitted Order' : this.state.showInfo.name + ' Order Form'}
                    onRequestClose={this.hidePane}
                >
                    {this.state.orderAlreadySubmitted ? 
                        <Fragment>
                        <div className="slide-pane-body scrollableDiv">
                            <div className="row pb-5">
                                
                                <div className="col col-12 pb-5">
                                                <b>Note: Your order has been received for this show. Your order details are provided below for you to review. If you need to make any changes to your order,
                                                    please contact Karen C. at 1-800-225-4535 extension 138 or <a href="mailto:karenc@d2p.com">karenc@d2p.com</a>.</b>
                                </div>

                                <div className="col col-3">
                                    Tables:
                                </div>
                                <div className="col col-9">
                                    {tableString}
                                </div>

                                <div className="col col-3">
                                    Skirts:
                                </div>
                                <div className="col col-9">
                                    {skirtsString}
                                </div>

                                <div className="col col-3">
                                    Table Tops:
                                </div>
                                <div className="col col-9">
                                    {topsString}
                                </div>

                                <div className="col col-3 pb-5">
                                    Easels:
                                </div>
                                <div className="col col-9 pb-5">
                                    {this.state.formFields.chkEasel ?
                                        <Fragment>
                                            <div>{this.state.formFields.easel1 && this.state.formFields.easel1}</div>
                                            <div>{this.state.formFields.easel2 && this.state.formFields.easel2}</div>
                                            <div>{this.state.formFields.easel3 && this.state.formFields.easel3}</div>
                                            <div>{this.state.formFields.easel4 && this.state.formFields.easel4}</div>
                                            <div>{this.state.formFields.easel5 && this.state.formFields.easel5}</div>
                                            <div>{this.state.formFields.easel6 && this.state.formFields.easel6}</div>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            None
                                        </Fragment>
                                    }
                                        </div>

                                        <div className="col col-3 pb-5">
                                            Show Badges Ordered:
                                </div>
                                        <div className="col col-9 pb-5">
                                            {this.state.selectedBadges.length > 0 ?

                                                <Fragment>
                                                    {this.state.selectedBadges.map((item, idx) => (
                                                                <div><span>{item.fName} {item.lName}{item.title && (", " + item.title)}</span></div>
                                                        
                                                    ))}
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    None
                                        </Fragment>
                                            }
                                        </div>

                                        <h3>If you have any questions, call Karen C. at 800-225-4535 ext. 138 or <a href="mailto:karenc@d2p.com">karenc@d2p.com</a></h3>
                                    </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="form-group col-12 padding-25-10">
                                            <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                                        </div>
                                    </div>
                        </Fragment>
                        :
                        <div className="slide-pane-body scrollableDiv">
                            <div className="row">
                                <div className="col col-12 pb-5">
                                    <div className="text-center">
                                        <b>DEADLINE TO ORDER: <span className="text-danger">{Moment(this.state.showInfo.bfDeadline).format('LL')}</span></b><br />
                                        <div className="text-center"><h4>If you have any questions, call Karen C. at 800-225-4535 ext. 138 or <a href="mailto:karenc@d2p.com">karenc@d2p.com</a></h4></div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row pb-5">
                                <div className="col col-12">
                                            <h3><b className="sub-header-title">Booth Furnishings ({ this.state.boothType })</b></h3>
                                    <div><strong>What are Booth Furnishings?</strong> <span className="a" onClick={() => this.setState({ showBFSlider: true })}>Click here for more info</span></div>

                                    <div>
                                        <fieldset className="mb-3">
                                            <legend>Included with your booth price</legend>
                                            <div className="row">
                                                <div className="col col-4">
                                                    Carpet:
                                            </div>
                                                <div className="col col-8">
                                                    Tuxedo
                                            </div>

                                                <div className="col col-4">
                                                    Chairs:
                                            </div>
                                                <div className="col col-8">
                                                    2
                                            </div>

                                                <div className="col col-4">
                                                    Wastepaper basket:
                                            </div>
                                                <div className="col col-8">
                                                    1
                                            </div>

                                                <div className="col col-4">
                                                    Back Drapes:
                                            </div>
                                                <div className="col col-8">
                                                    8' H - Royal Blue w/gray center panel
                                            </div>

                                                <div className="col col-4">
                                                    Side Drapes:
                                            </div>
                                                <div className="col col-8">
                                                    3' H - Royal Blue
                                            </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <b className="text-danger mt-3">
                                        {/*Tables: Please order below. All tables are 24" wide and 30" high.*/}
                                                Please Note:  Quantities listed below reflect your most recent D2P show order.<br />
                                                Tables are 24" wide and 30" high. Please review/edit and Click Submit Order.<br />
                                                <u>Maximum of {this.state.maxTables} tables allowed for {this.state.boothType}</u>
                                    </b>

                                    {/*<div className="mt-3">*/}
                                    {/*            <b className="text-danger">Quantities listed below reflect your most recent D2P show order. Please review/edit and Click Submit Order.</b>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="form-group col-lg-12">
                                    <label className="form-label">Do you require tables?</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="needTables" value='yes' className="custom-control-input" checked={this.state.formFields.needTables === 'yes'} onChange={this.handleInputChange} />
                                            <span className="custom-control-label">Yes</span>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="needTables" value='no' className="custom-control-input" checked={this.state.formFields.needTables === 'no'} onChange={this.handleInputChange} />
                                            <span className="custom-control-label">No</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.formFields.needTables === 'yes' &&
                                    <div className="row ml-5">
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">4' Tables (Qty):</label>
                                            <input type='number' min="0" name="table4" value={this.state.formFields.table4 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-2">
                                            <label className="form-label">6' Tables (Qty):</label>
                                            <input type="number" min="0" name="table6" value={this.state.formFields.table6 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-2">
                                            <label className="form-label">8' Tables (Qty):</label>
                                            <input type="number" min="0" name="table8" value={this.state.formFields.table8 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="col-lg-6" />

                                        {/*<div className="form-group col-lg-12">*/}
                                        {/*    <label className="form-label">Vinyl Table Tops (Please Select One)</label>*/}
                                        {/*    <div className="form-control-custom no-border">*/}
                                        {/*        <div className="custom-control custom-radio custom-control-inline">*/}
                                        {/*            <input type="radio" name="needTTCovers" value='yes' className="custom-control-input" checked={this.state.formFields.needTTCovers === 'yes'} onChange={this.handleInputChange} />*/}
                                        {/*            <span className="custom-control-label">Yes</span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="custom-control custom-radio custom-control-inline">*/}
                                        {/*            <input type="radio" name="needTTCovers" value='no' className="custom-control-input" checked={this.state.formFields.needTTCovers === 'no'} onChange={this.handleInputChange} />*/}
                                        {/*            <span className="custom-control-label">No</span>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Vinyl Table Tops (Please Select One)</label><br />
                                            * (Decorator requires all tables to be covered)
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="ttops" value='white' className="custom-control-input" checked={this.state.formFields.ttops === 'white'} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">White</span>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="ttops" value='black' className="custom-control-input" checked={this.state.formFields.ttops === 'black'} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">Black</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Table Skirts ( Please Select One )</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="tskirts" value='none' className="custom-control-input" checked={this.state.formFields.tskirts === 'none'} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">None</span>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="tskirts" value='black' className="custom-control-input" checked={this.state.formFields.tskirts === 'black'} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">Black</span>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="tskirts" value='gray' className="custom-control-input" checked={this.state.formFields.tskirts === 'gray'} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">Gray</span>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="tskirts" value='royalblue' className="custom-control-input" checked={this.state.formFields.tskirts === 'royalblue'} onChange={this.handleInputChange} />
                                                    <span className="custom-control-label">Royal Blue</span>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<div className="row ml-5">*/}
                                        {/*    <div className="form-group col-lg-6">*/}
                                        {/*        <label className="form-label">Black Table Skirts (Qty):</label>*/}
                                        {/*        <input type="number" min="0" name="blackSkirt" value={this.state.formFields.blackSkirt || ''} onChange={this.handleInputChange} className="form-control-custom" />*/}
                                        {/*    </div>*/}
                                        {/*    <div className="form-group col-lg-6" />*/}

                                        {/*    <div className="form-group col-lg-6">*/}
                                        {/*        <label className="form-label">Grey Table Skirts (Qty):</label>*/}
                                        {/*        <input type="number" min="0" name="graySkirt" value={this.state.formFields.graySkirt || ''} onChange={this.handleInputChange} className="form-control-custom" />*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-lg-6" />*/}

                                        {/*    <div className="form-group col-lg-6">*/}
                                        {/*        <label className="form-label">Royal Blue Table Skirts (Qty):</label>*/}
                                        {/*        <input type="number" min="0" name="burgundySkirt" value={this.state.formFields.burgundySkirt || ''} onChange={this.handleInputChange} className="form-control-custom" />*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-lg-6" />*/}

                                        {/*</div>*/}
                                    </div>
                                }

                                
                                {/*{this.state.formFields.needTTCovers === 'yes' &&*/}
                                {/*    <div className="row ml-5">*/}
                                {/*        <div className="form-group col-lg-6">*/}
                                {/*            <label className="form-label">White Table Tops (Qty):</label>*/}
                                {/*                <input type="number" min="0" name="whiteTop" value={this.state.formFields.whiteTop || ''} onChange={this.handleInputChange} className="form-control-custom" />*/}
                                {/*        </div>*/}
                                {/*        <div className="form-group col-lg-6" />*/}

                                {/*        <div className="form-group col-lg-6">*/}
                                {/*            <label className="form-label">Black Table Tops (Qty):</label>*/}
                                {/*                <input type="number" min="0" name="blackTop" value={this.state.formFields.blackTop || ''} onChange={this.handleInputChange} className="form-control-custom" />*/}
                                {/*        </div>*/}
                                {/*        <div className="col-lg-6" />*/}

                                {/*    </div>*/}
                                {/*}*/}

                                {/*<div className="form-group col-lg-12">*/}
                                {/*    <label className="form-label">Do you require table skirts?</label>*/}
                                {/*    <div className="form-control-custom no-border">*/}
                                {/*        <div className="custom-control custom-radio custom-control-inline">*/}
                                {/*            <input type="radio" name="needTSkirts" value='yes' className="custom-control-input" checked={this.state.formFields.needTSkirts === 'yes'} onChange={this.handleInputChange} />*/}
                                {/*            <span className="custom-control-label">Yes</span>*/}
                                {/*        </div>*/}
                                {/*        <div className="custom-control custom-radio custom-control-inline">*/}
                                {/*            <input type="radio" name="needTSkirts" value='no' className="custom-control-input" checked={this.state.formFields.needTSkirts === 'no'} onChange={this.handleInputChange} />*/}
                                {/*            <span className="custom-control-label">No</span>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*{this.state.formFields.needTSkirts === 'yes' &&*/}
                                    
                                {/*}*/}
                            </div>
                            <hr />
                            <div className="row pb-5">
                                <div className="col col-12">
                                    <h3><b className="sub-header-title">Signs</b></h3>
                                    <div>All Exhibitors will automatically have a Company ID sign in their booth.
                                    If you would like to order Easel Back Signs (7"H x 11"W), please click Yes.
                                    </div>

                                </div>

                                <div className="form-group col-lg-8">
                                    <label className="form-label">Do you require signage?</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="chkNoSigns" value='yes' className="custom-control-input" checked={this.state.formFields.chkNoSigns === 'yes'} onChange={this.handleInputChange} />
                                            <span className="custom-control-label">Yes</span>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="chkNoSigns" value='no' className="custom-control-input" checked={this.state.formFields.chkNoSigns === 'no'} onChange={this.handleInputChange} />
                                            <span className="custom-control-label">No</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.formFields.chkNoSigns === 'yes' &&
                                    <div className="row ml-5">
                                        <div>
                                            Please indicate the content below. Type is Bold black only and four (4) words are allocated per sign. Customers use the signs to label parts, processes, and materials. <br />
                                            <span className="a" onClick={() => this.setState({ showSignsSlider: true })}>Click Here to See Examples</span>
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Easel #1:</label>
                                            <input type="text" name="easel1" value={this.state.formFields.easel1 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Easel #2:</label>
                                            <input type="text" name="easel2" value={this.state.formFields.easel2 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Easel #3:</label>
                                            <input type="text" name="easel3" value={this.state.formFields.easel3 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Easel #4:</label>
                                            <input type="text" name="easel4" value={this.state.formFields.easel4 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Easel #5:</label>
                                            <input type="text" name="easel5" value={this.state.formFields.easel5 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Easel #6:</label>
                                            <input type="text" name="easel6" value={this.state.formFields.easel6 || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                    </div>
                                }


                            </div>

                            <hr />

                            <div className="row pb-5">
                                <div className="col col-12">
                                    <h3><b className="sub-header-title">Badges</b></h3>
                                    <div className="pb-3">
                                        Listed below is a Master Badge List of employees from your company who attended D2P Shows. Select the individuals who will staff your booth at the upcoming show by checking the box next to their name. Badges that need to be edited can be updated under this section too.
                                    </div>
                                    <div className="pb-3">
                                        To add a NEW individual, enter the name and title under New Badges and click Add Badge. It will automatically check the box to be printed for the show. If it is not needed for the current show, uncheck the box.
                                    </div>
                                    <div className="pb-3">
                                        Badges can be picked up at the show office
                                    </div>
                                </div>

                                <div className="col col-lg-7 col-12">
                                    <div className="float-right pb-3">
                                        <button className="btn btn-submit" onClick={() => this.setState({ showBadgePane: true })} > Add New Badge</button>
                                    </div>
                                    {this.state.badgeList.length > 0 &&
                                        <BootstrapTable keyField='rowID'
                                            data={this.state.badgeList}
                                            columns={columns}
                                        selectRow={selectRow}
                                        rowEvents={rowEvents}
                                            hover condensed striped />
                                    }
                                </div>

                                <ShowSliders.IndividualBadgeSlider showPane={this.state.showBadgePane} selectedBadge={this.state.selectedBadge} updateBadge={(badge, type) => this.updateBadge(badge, type)} hidePane={() => this.setState({ showBadgePane: false, selectedBadge: {}, badgeIdx: null })} {...this.props} />
                            </div>

                            <hr />

                            <div className="row pb-5">
                                <div className="col col-12">
                                    <h3><b className="sub-header-title">Rep Board Posting</b></h3>
                                    <div>
                                        Exhibitors looking for independent sales representation in the region of the show can post a listing on the Rep Wanted Board at the show. This board will be located at the show entrance to be viewed by all attending <b>LOCAL</b> rep agencies.
                                </div>
                                </div>

                                <div className="form-group col-lg-8">
                                    <label className="form-label text-danger">Would you like a Free Rep Board Posting?</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="needFreeRepBoard" value='yes' className="custom-control-input" checked={this.state.formFields.needFreeRepBoard === 'yes'} onChange={this.handleInputChange} />
                                            <span className="custom-control-label">Yes</span>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" name="needFreeRepBoard" value='no' className="custom-control-input" checked={this.state.formFields.needFreeRepBoard === 'no'} onChange={this.handleInputChange} />
                                            <span className="custom-control-label">No</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.formFields.needFreeRepBoard === 'yes' &&
                                    <div className="row ml-5">
                                        <div>
                                            Please complete the information to receive a listing at the show. <strong>All fields are required.</strong>
                                    </div>
                                        <div className="form-group col-lg-12">
                                                <label className="form-label">Your Company</label>
                                                <input type="text" value={Globals.userInfo.companyName || ''} onChange={this.handleInputChange} className="form-control-custom" readOnly />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">City</label>
                                            <input type="text" name="rCity" value={this.state.formFields.rCity || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <label className="form-label">State</label>
                                            <select className="form-control-custom" value={this.state.formFields.rState} name="rState" onChange={this.handleInputChange}>
                                                <option></option>
                                                {this.state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Contact</label>
                                            <input type="text" name="rContact" value={this.state.formFields.rContact || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Services</label>
                                            <input type="text" name="rServices" value={this.state.formFields.rServices || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Territory(s)</label>
                                            <input type="text" name="rTerritory" value={this.state.formFields.rTerritory || ''} onChange={this.handleInputChange} className="form-control-custom" />
                                        </div>
                                    </div>
                                }
                            </div>

                            <hr />
                            <div className="row pb-5">
                                <div className="col col-12">
                                    <h3><b className="sub-header-title">Electronic Ticket</b></h3>
                                    <div>
                                        Each exhibitor will receive an E-Ticket 30 days prior to the show that can be sent via email to invite prospects and customers to visit your booth.
                                        The E-Ticket is customized with your company logo and web address. You will also receive Helpful Hints and Guidelines on how to create an Email
                                        Campaign. <a href={process.env.PUBLIC_URL + '/downloads/ETicket.pdf'} target="new"><u>Click Here to view a sample of the E-Ticket</u></a>
                                    </div>
                                </div>
                            </div>

                            <ShowSliders.BoothFurnishingsInfoSlider showPane={this.state.showBFSlider} hidePane={() => this.setState({ showBFSlider: false })} {...this.props} />
                            <ShowSliders.SignInfoSlider showPane={this.state.showSignsSlider} hidePane={() => this.setState({ showSignsSlider: false })} {...this.props} />
                        </div>
                    }
                            <div className="modal-footer">
                                <div className="col-12 padding-25-10">
                                    <button className="btn btn-danger btn-lg" onClick={this.handleSubmit} disabled={this.state.processingSubmitOrder}>Submit Order</button>
                                    <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                                    {this.state.processingSubmitOrder &&
                                        <img src={spinnerLarge} alt="D2P Busy" className="margin-left-15" style={{ height: '30px', width: '30px' }} />
                                    }
                                </div>
                            </div>
                </SlidingPane>
                    </Fragment>
                    :
                    <div className="text-center p-5"><h4>You'll be notified by email when show order forms are available.</h4></div>
                }
            </Card>
        );
    }
}