import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import { message } from 'antd';

function ReportingServicesTab(props) {
    const [state, setState] = useState({
        selectedShow: props.selectedShow,
        selectedShowName: props.selectedShowName
    });

    useEffect(() => {

    }, []);

    function ActiveUsersReport() {
        let postdata = {
            showcode: parseInt(state.selectedShow),
            showName: state.selectedShowName
        }

        const key = "downloadactiveusersrpt";
        message.loading({
            content: 'Downloading Your Report...',
            key,
            duration: 0,
            //style: {
            //    position: "fixed",
            //    bottom: 0,
            //    left: 0
            //},
        });

        Axios.post(`/api/DownloadActiveUsersReport`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = "D2PLeads_ActiveUsers.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    function LeadRetrievalReport(mysortorder) {
        let postdata = {
            showcode: parseInt(state.selectedShow),
            showName: state.selectedShowName,
            sortorder: mysortorder
        }

        const key = "downloadleadretrievalrpt";
        message.loading({
            content: 'Downloading Your Report...',
            key,
            duration: 0,
            //style: {
            //    position: "fixed",
            //    bottom: 0,
            //    left: 0
            //},
        });

        Axios.post(`/api/DownloadLeadRetrievalReport`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = "D2PLeads_LeadRetrieval.xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className="code-box-demo">
            <div className="text-left a">
                <p onClick={() => ActiveUsersReport()}><u>Active Users Report</u></p>
            </div>
            <p>&nbsp;</p>
            <div className="text-left a">
                <p onClick={() => LeadRetrievalReport('Alpha')}><u>Lead Retrieval Report (Alpha Order)</u></p>
            </div>
            <p>&nbsp;</p>
            <div className="text-left a">
                <p onClick={() => LeadRetrievalReport('Booth')}><u>Lead Retrieval Report (Booth Order)</u></p>
            </div>
        </div>
    );
}

export default withRouter(ReportingServicesTab);