import React, { useEffect, useState, useRef } from "react";
import { withRouter } from 'react-router-dom';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../css/table.css'
import QRCode from 'qrcode.react';
import { useForm, Controller } from 'react-hook-form';
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Select, Empty, Spin, Alert, message, Tabs, Drawer } from 'antd';
import { Fragment } from 'react';
import EmailTemplatePane from './EmailTemplatePane';

export default function EmailManager(props) {
    const [state, setState] = useState({
        formFields: {
            emailServers_ID: null,
            smtpServer: '',
            smtpPort: '',
            smtpUser: '',
            smtpPassword: '',
            app: ''
        },
        searchRunning: true,
        templates: [],
        selectedTemplateId: null,
        showTemplateDetailsSlider: false,
        templateEditMode: 'Add',
        selectedIdx: null,
        selectedTemplateType: '',
        showEmailRecipientsPane: false,
        availableEmployees: [],
        selectedTemplateType: 'MSO'
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const [showEmailTemplatePane, setShowEmailTemplatePane] = useState(false);
    const [showEmailRecipientsPane, setShowEmailRecipientsPane] = useState(false);

    const { Option } = Select;

    useEffect(() => {
        Axios.get(`/api/GetEmailManagerInfo`, {
            params: {
                App: 'MSO'
            }
        }).then(response => {
            console.log(response.data);
            let templatesList = response.data.emailTemplates;

            let availableEmployees = response.data.availableEmployees;

            setValue('formFields', response.data.mailserverinfo);

            setValue('formFieldsLR', response.data.mailserverinfoLR);

            setState({
                ...state,
                templates: templatesList,
                availableEmployees: availableEmployees,
                searchRunning: false
            });

        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function handleSaveEmailServerSettings() {
        let postdata = getValues("formFields");
        let postdataLR = getValues("formFieldsLR");

        if (postdata.smtpServer === '' || postdata.smtpUser === '' || postdata.smtpPort === '' || postdataLR.smtpServer === '' || postdataLR.smtpUser === '' || postdataLR.smtpPort === ''
        ) {
            toast.error('SMTP Server, Port and User are required fields for both MSO and LR Mail Servers');
            return;
        } 

        Axios.post(`/api/UpdateMailServerInfo`, postdata
        ).then(response => {
            let postdata = getValues("formFieldsLR");
            Axios.post(`/api/UpdateMailServerInfo`, postdata
            ).then(responseLR => {

                toast.success('Email Server Info Successfully Updated');
            }).catch(error => {
                console.log(error);
            });

            //toast.success('Email Server Info Successfully Updated');
        }).catch(error => {
            console.log(error);
        });
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#C5DBEC' },
        hideSelectColumn: true,
        selected: [state.selectedTemplateId]
    };

    function handleTableChange(type, { page, sizePerPage }) {

    }

    //<i class="fa fa-download" aria-hidden="true"></i>

    function downloadFormatter(cell, row) {
        if (row.templateData === null) {
            return (
                <div>No Template</div>
            );
        }
        else {
            return (
                <i id="downloadTemplate" className="fa fa-download fa-2x ml-3 text-center hover"></i>
            );
        }
    }

    function editFormatter(cell, row) {
        return (
            <i id="editTemplate" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    function recipientsFormatter(cell, row) {
        return (
            <i id="manageRecipients" className="fas fa-users fa-1x ml-3 text-center hover"></i>
        );
    }

    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.note_date)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    function deleteFormatter(cell, row) {
        return (
            <i id="deleteTemplate" className="far fa-trash-alt fa-1x ml-3 hover text-danger"></i>
        );
    }

    function getRecipients(cell, row, rowIndex) {
        //console.log(cell);
        //console.log(row);
        //console.log(rowIndex);

        var separateRecipients;

        if (row.recipients === null) {
            return (
                <div style={{color: "red"}}>N/A</div>
            );
        }
        else if (row.recipients.includes(";")) {
            separateRecipients = row.recipients.split("; ");
        }
        else {
            separateRecipients = row.recipients;
        }

        return (
            <Fragment>
                <Select
                    mode="multiple"
                    placeholder="Please select"
                    onChange={(value) => handleRecipientsChange(row, rowIndex, value)}
                    className="form-control-custom w-100"
                    bordered={false}
                    maxTagCount={6}
                    value={separateRecipients}
                >
                    {state.availableEmployees.map((e, idx) => <Option index={idx} key={idx + "b" + idx} value={e.email}>{e.email}</Option>)}
                </Select>

                <label>
                    <button type="button"
                        id="updaterecipientsbutton"
                        onClick={() => {handleSaveEmailRecipients(row)}}
                        className="btn btn-link">
                        Update Recipients
                    </button>
                </label>
            </Fragment>
        );
    }

    const columns = [{
        dataField: 'emailTemplates_ID',
        hidden: true
    }, {
        dataField: 'templateType',
        text: 'Email Type',
        sort: true
    }, {
        dataField: 'templateName',
        text: 'Template File Name',
        sort: true
    }, {
        dataField: 'templateDate',
        text: 'Upload Date',
        formatter: dateFormatter,
        headerStyle: { width: '140px', textAlign: 'left' },
        sort: true
    }, {
        dataField: 'uploadedBy',
        text: 'Uploaded By',
        headerStyle: { width: '140px', textAlign: 'left' },
        sort: true
    }, {
        dataField: 'notes',
        text: 'Notes',
        sort: true
    }, {
        dataField: 'recipients',
        text: 'Recipients',
        formatter: getRecipients
    }, {
        dataField: 'dum1',
        isDummyField: true,
        text: 'Download Template',
        formatter: downloadFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '100px', textAlign: 'center' }
    }, {
        dataField: 'dum2',
        isDummyField: true,
        text: 'Update Template',
        formatter: editFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '100px', textAlign: 'center' }
    }, {
        dataField: 'dum3',
        isDummyField: true,
        text: 'Delete Template',
        formatter: deleteFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '100px', textAlign: 'center' }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'editTemplate') {
                LoadTemplateDetails(row, rowIndex)
            }
            else if (e.target.tagName === 'I' && e.target.id === 'downloadTemplate') {
                DownloadEmailTemplate(row);
            }
            else if (e.target.tagName === 'I' && e.target.id === 'deleteTemplate') {
                handleDelete(row);
            }
            else if (e.target.tagName === 'I' && e.target.id === 'manageRecipients') {
                handleManageRecipients(row);
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            /*LoadUserDetails(row, rowIndex);*/
        }
    };

    function handleDelete(template) {
        confirm({
            title: "You are about to permanently delete this Template",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = {
                    emailTemplates_ID: template.emailTemplates_ID,
                    app: 'MSO'
                }

                let formdata = new FormData();
                formdata.append('emailTemplates_ID', template.emailTemplates_ID);
                formdata.append('app', 'MSO');

                console.log(postdata);

                Axios.post(`/api/DeleteEmailTemplate`, formdata
                ).then(response => {
                    if (response.data) {
                        toast.success("Template has been deleted");

                        setState({
                            ...state,
                            templates: response.data
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function LoadTemplateDetails(row, rowIndex) {
        setState({
            ...state,
            emailTemplate: row,
            selectedTemplateType: row.templateType,
            selectedIdx: rowIndex,
            templateEditMode: 'Edit',
        });

        setShowEmailTemplatePane(true);
    }

    function setEmailTemplateInfo(emailTemplate) {
        setState({
            ...state,
            emailTemplate: emailTemplate
        });

        let templates = state.templates;
        let targetTemplate = templates[state.selectedIdx];

        targetTemplate.notes = emailTemplate.notes;
        targetTemplate.templateName = emailTemplate.templateName;
        targetTemplate.templateType = emailTemplate.templateType;
        targetTemplate.uploadedBy = emailTemplate.uploadedBy;

        templates[state.selectedIdx] = targetTemplate;

        //console.log(state.selectedIdx);
        //console.log(emailTemplate);

        setState({
            ...state,
            templates
        });
    }

    function DownloadEmailTemplate(template) {
        let postdata = {
            emailtemplate: template.templateType
        }

        const key = "downloademailtemplate";
        message.loading({
            content: 'Downloading Email Template...',
            key,
            duration: 0,
            //style: {
            //    position: "fixed",
            //    bottom: 0,
            //    left: 0
            //},
        });

        Axios.post(`/api/DownloadEmailTemplate`, postdata, {
            responseType: 'blob'
        }).then(response => {
            message.destroy(key);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = template.templateName;
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleAddNewTemplate() {
        setState({
            ...state,
            templateEditMode: 'Add',
        });

        setShowEmailTemplatePane(true);
    }

    function handleManageRecipients(template) {
        console.log(template);

        setShowEmailRecipientsPane(true);
    }

    function handleRecipientsChange(row, rowIndex, value) {
        //console.log(row);
        //console.log(rowIndex);
        //console.log(value);

        let myTemplates = state.templates;
        let item = myTemplates[rowIndex];

        //convert from array to single string
        item.recipients = value.toString().replaceAll(',', '; ');

        myTemplates[rowIndex] = item;

        setState({
            ...state,
            templates: myTemplates,
            isDirty: true
        });
    }

    function handleSaveEmailRecipients(row) {
        if (row.recipients === "") {
            toast.error('You must select at least one valid recipient');
            return;
        }

        let postdata = {
            emailTemplate: row
        }

        let formdata = new FormData();
        formdata.append('emailTemplates_ID', row.emailTemplates_ID);
        formdata.append('app', 'MSO');
        formdata.append('recipients', row.recipients);

        console.log(row);
        console.log(formdata);

        Axios.post(`/api/UpdateEmailRecipients`, formdata
        ).then(response => {

            toast.success('Email Recipients Successfully Updated');
        }).catch(error => {
            console.log(error);
        });
    }

    //function handleTemplateTypeChange(e) {
    //    let myTemplateType = e.target.value;

    //    Axios.get(`/api/GetAllEmailRecipients`, {
    //        params: {
    //            App: e.target.value
    //        }
    //    }).then(response => {
    //        console.log(response.data);

    //        let templatesList = response.data.emailTemplates;
    //        let availableEmployees = response.data.availableEmployees;

    //        setState({
    //            ...state,
    //            selectedTemplateType: myTemplateType,
    //            templates: templatesList,
    //            availableEmployees: availableEmployees,
    //            searchRunning: false
    //        });

    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    return (
        <div className="code-box-demo">
            <p></p>

            <h3 className="frame-heading">Mail Server Settings</h3>
            <div className="row">
                <div className="form-group col-lg-2">
                    <label className="form-label">MSO SMTP Server</label>
                    <input {...register("formFields.smtpServer")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2">
                    <label className="form-label">MSO SMTP Port</label>
                    <input {...register("formFields.smtpPort")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-3">
                    <label className="form-label">MSO SMTP User</label>
                    <input {...register("formFields.smtpUser")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2">
                    <label className="form-label">MSO SMTP Password</label>
                    <input {...register("formFields.smtpPassword")} type="text" className="form-control-custom" />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-lg-2">
                    <label className="form-label">Lead Retrieval SMTP Server</label>
                    <input {...register("formFieldsLR.smtpServer")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2">
                    <label className="form-label">Lead Retrieval SMTP Port</label>
                    <input {...register("formFieldsLR.smtpPort")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-3">
                    <label className="form-label">Lead Retrieval SMTP User</label>
                    <input {...register("formFieldsLR.smtpUser")} type="text" className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2">
                    <label className="form-label">Lead Retrieval SMTP Password</label>
                    <input {...register("formFieldsLR.smtpPassword")} type="text" className="form-control-custom" />
                </div>
            </div>
            <button className="btn btn-primary" onClick={handleSaveEmailServerSettings}>Save Email Server Settings</button>

            <p>&nbsp;</p>
            <hr />
            <h3 className="frame-heading">System Generated Emails/Templates</h3>

            {/*<div onChange={handleTemplateTypeChange}>*/}
            {/*    <label className="form-label">Select App:</label>&nbsp;&nbsp;*/}
            {/*    <input type="radio" name="templateType" value="WEBCM" className="mt-1" checked={state.selectedTemplateType === "WEBCM"} />&nbsp;<label className="form-label">WEBCM</label>&nbsp;&nbsp;&nbsp;*/}
            {/*    <input type="radio" name="templateType" value="MSO" className="mt-1" checked={state.selectedTemplateType === "MSO"} />&nbsp;<label className="form-label">MyShowOnline</label>&nbsp;&nbsp;&nbsp;*/}
            {/*</div>*/}

            <div className="row">
                <div className="col-lg-12">
                    <button className="btn btn-submit float-end" onClick={handleAddNewTemplate}>Add New Template</button>
                </div>
                <div className="col-lg-12">&nbsp;</div>
                <div className="col-lg-12">
                    <Spin spinning={state.searchRunning}>
                        <div style={state.searchRunning ? {} : { display: 'none' }}>
                            <Alert
                                message="Loading Email Templates..."
                                description="Please stand by while we load all email templates"
                                type="info"
                            />
                        </div>
                        {!state.searchRunning &&
                            <div className="row">
                                {state.templates.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No email templates found
                                        </span>
                                    } />
                                    :
                                    <div className="fullTable">
                                        <ToolkitProvider
                                            keyField='emailTemplates_ID'
                                            data={state.templates}
                                            columns={columns}
                                            columnToggle
                                            onTableChange={handleTableChange}
                                        >
                                            {
                                                props => (
                                                    <div>
                                                        <BootstrapTable
                                                            pagination={paginationFactory(options)}
                                                            onTableChange={handleTableChange}
                                                            {...props.baseProps}
                                                            rowEvents={rowEvents}
                                                            selectRow={selectRow}
                                                            hover condensed />
                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </div>
                                }
                            </div>
                        }
                    </Spin>
                </div>

                {showEmailTemplatePane && <EmailTemplatePane {...props} hidePane={() => setShowEmailTemplatePane(false)} emailTemplate={state.emailTemplate} updateEmailTemplateInfo={(template) => setEmailTemplateInfo(template)} templateEditMode={state.templateEditMode} paneTitle='Update Email Template' templateType={state.selectedTemplateType} />}
            </div>
        </div>
    );
}