import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Axios from '../../../config/axios';
import Globals from '../../../config/globals';

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import Moment from 'moment';
import { Fragment } from 'react';

export class InvoiceSlider extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.showPane !== prevState.showPane) {
            return nextProps.showPane === true ? { showPane: true, showInfo: nextProps.showInfo, showDetails: nextProps.showDetails } : { showPane: false };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showPane: false,
            showInfo: {},
            showDetails: {},
            invoices: [],
            payments: [],
            invoiceDueDate: '',
            outstandingBalance: 0,
            noInvoice: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showPane && this.props.showPane !== prevState.showPane) {
            Axios.get(`/api/GetInvoiceInformation`, {
                params: {
                    company_id: this.state.showDetails.id,
                    showcode: parseInt(this.props.location?.state?.showcode || Globals.defaultShowCode)
                }
            }).then(response => {
                if (response.data.invoiceNumber) {
                    var totalInvoices = 0;
                    var totalPayments = 0;
                    for (var i = 0; i < response.data.invoices.length; i++) {
                        totalInvoices += parseFloat(response.data.invoices[i].amount);
                    }

                    for (var j = 0; j < response.data.payments.length; j++) {
                        totalPayments += parseFloat(response.data.payments[j].amount.substring(1));
                    }

                    let outstandingBalance = totalInvoices - totalPayments;


                    this.setState({
                        invoices: response.data.invoices,
                        payments: response.data.payments,
                        invoiceDueDate: response.data.invoiceDueDate,
                        outstandingBalance
                    });
                } else {
                    this.setState({
                        noInvoice: true
                    });
                }

                

            }).catch(error => {
                console.log(error);
            });
        }
    }

    hidePane = () => {
        this.setState({
            showPane: false
        });

        this.props.hidePane();
    }

    render() {

        const money = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })

        function a1Format(cell, row) {
            return (
                <span>{money.format(parseFloat(cell))}</span>
            );
        }

        function a2Format(cell, row) {
            var s1 = cell.substring(1);
            return (
                <span>({money.format(parseFloat(s1))})</span>
            );
        }

        function refFormat(cell, row) {
            return (
                <span>Reference: {cell}</span>
            );
        }

        const columns = [{
            dataField: 'date',
            text: 'Date',
            formatter: cell => cell ? Moment(cell).utc().format('L') : null
        }, {
            dataField: 'invoiceNumber',
            text: 'Invoice'
        }, {
            dataField: 'miscText',
            text: 'Details'
        }, {
            dataField: 'amount',
                text: 'Amount',
                formatter: a1Format
            }];

        const columnsP = [{
            dataField: 'date',
            text: 'Date',
            formatter: cell => cell ? Moment(cell).utc().format('L') : null
        }, {
            dataField: 'invoiceNumber',
            text: 'Invoice'
        }, {
            dataField: 'reference',
                text: 'Details',
                formatter: refFormat
        }, {
            dataField: 'amount',
                text: 'Amount',
                formatter: a2Format
        }];

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='showCard'
                isOpen={this.state.showPane}
                title='View Invoice'
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body scrollableDiv">
                    {this.state.noInvoice ?
                        <p>Invoices have not been sent out for this show yet</p>
                        :
                        <Fragment>
                            <div className="row">
                                {this.state.invoices.length > 0 &&
                                    <div className="fullTable">
                                        <h2>Invoices</h2>
                                        <BootstrapTable keyField='miscText'
                                            data={this.state.invoices}
                                            columns={columns}
                                            hover condensed />
                                    </div>
                                }
                            </div>
                            <div className="row pt-5">
                                {this.state.payments.length > 0 &&
                                    <div className="fullTable">
                                        <h2>Payments</h2>
                                        <BootstrapTable keyField='miscText'
                                            data={this.state.payments}
                                            columns={columnsP}
                                            hover condensed />
                                    </div>
                                }
                            </div>

                            <div className="row pt-5">
                                {(this.state.invoiceDueDate && this.state.invoiceDueDate !== '') &&
                                    <Fragment>
                                        <p className="col col-6"> Balance must be paid in full by: {Moment(this.state.invoiceDueDate).utc().format('L')}</p><p className="col col-6"><b>Outstanding balance: {money.format(this.state.outstandingBalance)}</b></p>
                                    </Fragment>
                                }
                            </div>

                            <div className="row pt-5">
                                To request a copy of your invoice, please contact Veronica at 800-225-4535, ext. 137 or&nbsp;<a href="mailto:v@d2p.com">v@d2p.com</a>
                            </div>
                        </Fragment>
                        }
                    
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        <button className="btn btn-primary btn-lg margin-left-15" onClick={this.hidePane}>Close</button>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}